import React, { Fragment } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createGlobalStyle } from "styled-components";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import "@aws-amplify/ui/dist/style.css";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { useTranslation } from "react-i18next";

import RobotoLight from "../assets/fonts/Roboto/Roboto-Light.ttf";
import RobotoRegular from "../assets/fonts/Roboto/Roboto-Regular.ttf";
import RobotoMedium from "../assets/fonts/Roboto/Roboto-Medium.ttf";
import RobotoBold from "../assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoBlack from "../assets/fonts/Roboto/Roboto-Black.ttf";
import NeueMachinaLight from "../assets/fonts/NeueMachina/NeueMachina-Light.otf";
import NeueMachinaRegular from "../assets/fonts/NeueMachina/NeueMachina-Regular.otf";
import NeueMachinaUltrabold from "../assets/fonts/NeueMachina/NeueMachina-Ultrabold.otf";

import { view } from "@risingstack/react-easy-state";
import { auth, gdpr } from "../stores/BaseStore";

import Loader from "./shared/Loader";
import ProtectedRoute from "./protected/ProtectedRoute";
import Footer from "./shared/Footer";
import Login from "./shared/Login";
import AcceptTerms from "./gdpr/AcceptTerms";
import TermsAndConditions from "./gdpr/TermsAndConditions";
import Privacy from "./gdpr/Privacy";
import Impressum from "./gdpr/Impressum";
import AppbarSupplier from "./supplier/AppbarSupplier";
import AppbarBuyer from "./buyer/AppbarBuyer";
import Header from "./shared/Header";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'RobotoLight';
    src: local('RobotoLight'), local('RobotoLight'),
    url(${RobotoLight}) format('truetype')
  }
  @font-face {
    font-family: 'RobotoRegular';
    src: local('RobotoRegular'), local('RobotoRegular'),
    url(${RobotoRegular}) format('truetype')
  }
  @font-face {
    font-family: 'RobotoMedium';
    src: local('RobotoMedium'), local('RobotoMedium'),
    url(${RobotoMedium}) format('truetype')
  }
  @font-face {
    font-family: 'RobotoBold';
    src: local('RobotoBold'), local('RobotoBold'),
    url(${RobotoBold}) format('truetype')
  }
  @font-face {
    font-family: 'RobotoBlack';
    src: local('RobotoBlack'), local('RobotoBlack'),
    url(${RobotoBlack}) format('truetype')
  }
  @font-face {
    font-family: 'NeueMachinaLight';
    src: local('NeueMachinaLight'), local('NeueMachinaLight'),
    url(${NeueMachinaLight}) format('opentype')
  }
  @font-face {
    font-family: 'NeueMachinaRegular';
    src: local('NeueMachinaRegular'), local('NeueMachinaRegular'),
    url(${NeueMachinaRegular}) format('opentype')
  }
  @font-face {
    font-family: 'NeueMachinaUltrabold';
    src: local('NeueMachinaUltrabold'), local('NeueMachinaUltrabold'),
    url(${NeueMachinaUltrabold}) format('opentype')
  }

  body {
    color: #000000;
    background-color: #ffffff;
    font-family: 'RobotoRegular', 'Arial', sans-serif;
  }

  .MuiTypography-body1 {
    color: #000000;
    font-family: 'RobotoRegular', 'Arial', sans-serif;
  }

  h1,h2,h3,h4,h5,h6 {
    color: #000000;
    font-family: 'NeueMachinaRegular', 'Arial', sans-serif;
  }

  a {
    outline: 0;
  }

  amplify-authenticator {
    --box-shadow: none;
    --container-display: block;
    --container-height: 100%;
    text-align: center;
  }

  :root {
    --amplify-font-family: 'RobotoRegular', 'Arial', sans-serif;
    --amplify-primary-color: #00bfe9;
    --amplify-secondary-tint: #00bfe9;
    --amplify-background-color: transparent;
    --amplify-primary-tint: #00bfe9;
    --amplify-primary-shade: #00bfe9;
    --amplify-text-md-sub: 36px;
  }

  main {
    width: 100%;
  }
  
  #SRLLightbox {
    z-index: 99999;
  }
`;

const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#06d6f8",
    },
  },
  props: {
    MuiButtonBase: {
      // The properties to apply
      // disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  overrides: {
    // MuiIconButton: {
    //   root: {
    //     "&:hover": {
    //       backgroundColor: "transparent !important",
    //     },
    //   },
    // },
    MuiPickersDay: {
      day: {
        borderRadius: "0 !important",
      },
      daySelected: {
        backgroundColor: "#00C9E9",
        "&:hover": {
          backgroundColor: "#00C9E9",
        },
      },
    },
  },
});

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const useStyles = makeStyles({
  root: {
    zIndex: 9999,
  },
});

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const Wrapper = view(() => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <GlobalStyle />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          classes={{
            root: classes.root,
          }}
        >
          <Switch>
            <ProtectedRoute path="/accept-terms" exact auth={auth} gdpr={gdpr}>
              <AcceptTerms />
            </ProtectedRoute>
            <Route path="/terms-and-conditions" exact>
              <Header />
              <TermsAndConditions />
            </Route>
            <Route path="/privacy" exact>
              <Header />
              <Privacy />
            </Route>
            <Route path="/impressum" exact>
              <Header />
              <Impressum />
            </Route>
            <Route path="/login/:slot?/:type?" exact>
              <Header />
              <Login />
            </Route>
            <ProtectedRoute path="/" auth={auth} gdpr={gdpr}>
              {!auth.user ? (
                <Fragment>
                  <StyledSpacer />
                  <Loader />
                </Fragment>
              ) : auth.user.signInUserSession.idToken.payload[
                  "cognito:groups"
                ].find((group) => group === "yvoo-supplier") ? (
                <AppbarSupplier />
              ) : auth.user.signInUserSession.idToken.payload[
                  "cognito:groups"
                ].find((group) => group === "yvoo-buyer") ? (
                <AppbarBuyer />
              ) : (
                <StyledSpacer>{t("not available")}</StyledSpacer>
              )}

              <Footer />
            </ProtectedRoute>
          </Switch>
        </SnackbarProvider>
      </ThemeProvider>
    </Fragment>
  );
});

export default Wrapper;
