import formModel from "./formModel";
const {
  formField: {
    organisation,
    financial,
    system_audit,
    process,
    location,
    capacity_planning,
    product_competence,
    development_competence,
    productivity,
    control,
    s5,
    visual_management,
    standardized_work,
    inventory,
    tpm,
    schedule,
    flow,
    cycle,
    pull,
    deviations,
    line_stop_concept,
    problem_solving,
    machine_design,
    line_design,
    factory_design,
  },
} = formModel;

export default {
  [organisation.name]: "",
  [financial.name]: "",
  [system_audit.name]: "",
  [process.name]: "",
  [location.name]: "",
  [capacity_planning.name]: "",
  [product_competence.name]: "",
  [development_competence.name]: "",
  [productivity.name]: "",
  [control.name]: "",
  [s5.name]: "",
  [visual_management.name]: "",
  [standardized_work.name]: "",
  [inventory.name]: "",
  [tpm.name]: "",
  [schedule.name]: "",
  [flow.name]: "",
  [cycle.name]: "",
  [pull.name]: "",
  [deviations.name]: "",
  [line_stop_concept.name]: "",
  [problem_solving.name]: "",
  [machine_design.name]: "",
  [line_design.name]: "",
  [factory_design.name]: "",
};
