import * as Yup from 'yup';
import formModel from './formModel';
const {
  formField: {
    name,
    email,
    profile_photo,
    mobile_phone,
    country,
    zip,
    job_function,
    job_level,
    discipline,
    industry,
    company_name,
    address,
    city,
  },
} = formModel;

const phoneNumberRegEx =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
// const numberRegEx = /^\d+$/;
const alphanumericRegEx = /^[a-z0-9]+$/i;

export default [
  Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [email.name]: Yup.string().nullable(),
    [profile_photo.name]: Yup.string().nullable(),
    [mobile_phone.name]: Yup.string().matches(
      phoneNumberRegEx,
      mobile_phone.invalidErrorMsg
    ),
    [country.name]: Yup.string().nullable(),
    [zip.name]: Yup.string()
      .nullable()
      .matches(alphanumericRegEx, zip.invalidErrorMsg)
      .test('len', `${zip.invalidErrorMsg}`, (val) => !val || val.length <= 10),
    [job_function.name]: Yup.string().nullable(),
    [job_level.name]: Yup.string().nullable(),
    [discipline.name]: Yup.string().nullable(),
    [industry.name]: Yup.string().nullable(),
    [company_name.name]: Yup.string().nullable(),
    [address.name]: Yup.string().nullable(),
    [city.name]: Yup.string().nullable(),
  }),
];
