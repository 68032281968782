import * as Yup from "yup";
import formModel from "./formModel";
import i18next from "i18next";

const {
  passwordFormField: { current_password, new_password, repeat_password },
} = formModel;

const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

export default [
  Yup.object().shape({
    [current_password.name]: Yup.string().required(
      `${current_password.requiredErrorMsg}`
    ),
    [new_password.name]: Yup.string()
      .required(`${new_password.requiredErrorMsg}`)
      .matches(
        passwordRegEx,
        i18next.t("at least 8 characters, 1 uppercase letter and 1 number")
      ),
    [repeat_password.name]: Yup.string()
      .required(`${repeat_password.requiredErrorMsg}`)
      .oneOf(
        [Yup.ref("new_password"), null],
        i18next.t("passwords must match")
      ),
  }),
];
