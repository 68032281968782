import React, { Fragment, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import { Box, Container } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import AWS from "aws-sdk";

import { autoEffect, view } from "@risingstack/react-easy-state";
import { company, countries } from "stores/BaseStore";

import Loader from "components/shared/Loader";
import MainForm from "../companyDetails/FormPage/Forms/MainForm";

import validationSchema from "../companyDetails/FormPage/FormModel/validationSchema";
import formModel from "../companyDetails/FormPage/FormModel/formModel";
import formInitialValues from "../companyDetails/FormPage/FormModel/formInitialValues";

import useStyles from "../companyDetails/FormPage/styles";

const { formId, formField } = formModel;

const StyledContainer = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 21px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 20px;
  }
`;

const StyledFormWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

const StyledButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const CompanyDetails = view(() => {
  const classes = useStyles();
  const currentValidationSchema = validationSchema[0];
  const [companyId, setCompanyId] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const { t } = useTranslation();
  const [s3Urls, setS3Urls] = useState(null);
  const [s3Logo, setS3Logo] = useState(null);
  const [s3Presentation, setS3Presentation] = useState(null);
  const [s3Reference, setS3Reference] = useState(null);

  const getS3Data = async (type, hook) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    });

    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Prefix: `media/${companyId}/${type}/`,
    };

    s3.listObjects(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        if (data.Contents.length > 0) {
          const content = data.Contents.map((url) => {
            return `${process.env.REACT_APP_S3_ENDPOINT}/${url.Key}`;
          });

          hook(content);
        } else {
          hook([]);
        }
      }
    });
  };

  autoEffect(async () => {
    if (!company.company) {
      await company.getCompany();
    }

    if (company.company) {
      setCompanyId(company.company.id);
      setInitialValues(company.company);

      await getS3Data("image", setS3Urls);
      await getS3Data("logo", setS3Logo);
      await getS3Data("company-presentation", setS3Presentation);
      await getS3Data("reference-list", setS3Reference);
    } else {
      setCompanyId(null);
      setInitialValues(formInitialValues);
    }
  });

  autoEffect(async () => {
    if (!company.allCompanies) {
      await company.getCompanies();
    }
  });
  autoEffect(async () => {
    if (!countries.countries) {
      await countries.getCountries();
    }
  });

  async function _submitForm(values, actions) {
    if (companyId) {
      await company.editCompany(values, companyId);
    } else {
      await company.addCompany(values);
    }
    actions.setSubmitting(false);
    setInitialValues(null);
    company.company = null;
  }

  // const blobToData = (blob) => {
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(blob);
  //     reader.onloadend = () => resolve(reader.result);
  //   });
  // };

  async function _handleSubmit(values, actions) {
    values.photos = null;
    values.logo = null;
    values.company_presentation = null;
    values.reference_list = null;
    _submitForm(values, actions);
  }

  return !initialValues ||
    !countries.countries ||
    !company.allCompanies ||
    company.loading ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <Fragment>
      <StyledContainer>
        <StyledFormWrapper fixed>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form id={formId}>
                <MainForm
                  formField={formField}
                  values={values}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  countryList={countries.countries}
                  companies={company.allCompanies}
                  s3Urls={s3Urls}
                  s3Logo={s3Logo}
                  s3Presentation={s3Presentation}
                  s3Reference={s3Reference}
                />
                <div>
                  <StyledButton disabled={isSubmitting} type="submit">
                    {t("save changes")}
                  </StyledButton>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </StyledFormWrapper>
      </StyledContainer>
    </Fragment>
  );
});

export default CompanyDetails;
