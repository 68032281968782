import i18next from "i18next";

export default {
  formId: "mainForm",
  formField: {
    customer_rating: {
      name: "customer_rating",
      label: i18next.t("lean-question-1"),
    },
    facility_clean: {
      name: "facility_clean",
      label: i18next.t("lean-question-2"),
    },
    visual_labeling: {
      name: "visual_labeling",
      label: i18next.t("lean-question-3"),
    },
    everything_stored: {
      name: "everything_stored",
      label: i18next.t("lean-question-4"),
    },
    operational_goals: {
      name: "operational_goals",
      label: i18next.t("lean-question-5"),
    },
    materials_stored: {
      name: "materials_stored",
      label: i18next.t("lean-question-6"),
    },
    work_instructions: {
      name: "work_instructions",
      label: i18next.t("lean-question-7"),
    },
    updated_charts: {
      name: "updated_charts",
      label: i18next.t("lean-question-8"),
    },
    current_state: {
      name: "current_state",
      label: i18next.t("lean-question-9"),
    },
    production_lines: {
      name: "production_lines",
      label: i18next.t("lean-question-10"),
    },
    material_moved: {
      name: "material_moved",
      label: i18next.t("lean-question-11"),
    },
    continuous_flow: {
      name: "continuous_flow",
      label: i18next.t("lean-question-12"),
    },
    trained_teams: {
      name: "trained_teams",
      label: i18next.t("lean-question-13"),
    },
    commited_employes: {
      name: "commited_employes",
      label: i18next.t("lean-question-14"),
    },
    timetable: {
      name: "timetable",
      label: i18next.t("lean-question-15"),
    },
    project_managment: {
      name: "project_managment",
      label: i18next.t("lean-question-16"),
    },
    supplier_certification: {
      name: "supplier_certification",
      label: i18next.t("lean-question-17"),
    },
    product_characteristics: {
      name: "product_characteristics",
      label: i18next.t("lean-question-18"),
    },
  },
};
