import React, { Fragment, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { autoEffect, view } from "@risingstack/react-easy-state";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MainModal from "components/shared/MainModal";

import { auth, customSearch, dashboards } from "../../../stores/BaseStore";
import Loader from "../../shared/Loader";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;
  padding-left: 24px;
  padding-right: 24px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
  }
`;

const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  margin-bottom: 60px;
`;

const StyledMain = styled(Box)`
  width: 100%;
  position: relative;
  z-index: 2;
  padding-left: 24px;
  padding-right: 24px;
  border-top: 1px solid #dddddd;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
  }
`;

const StyledMainWrapper = styled(Container)`
  width: 100%;
  position: relative;
  z-index: 2;
  padding-bottom: 60px;
  padding-top: 60px;

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 24px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 36px;

    span {
      color: #808285;

      &.separator {
        color: #cfcfcf;
      }
    }
  }
`;

const StyledTableContainer = styled(TableContainer)`
  .MuiTable-root {
    border-collapse: separate;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTableHead-root {
    background-color: #000000;

    .MuiTableCell-head {
      font-family: "RobotoRegular", "Arial", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #ffffff;
    }
  }

  .MuiTableCell-body {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    a {
      text-decoration-line: underline;
      color: #00c9e9;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const StyledSpecialCell = styled.span`
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;
  color: #00bfe9;
`;

const StyledTableCell = styled(TableCell)`
  position: relative;
`;

const StyledTableCellBorder = styled(Box)`
  position: absolute;
  right: 0;
  bottom: 0;
  height: calc(100% - 2px);
  width: 10px;
  background-color: ${(props) =>
    props.status === "open"
      ? "#FFC527"
      : props.status === "complete"
      ? "#01E7AA"
      : "#FFFFFF"};
`;

const StyledSeeAllButton = styled.span`
  display: block;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #474747;
  margin-top: 20px;
  cursor: pointer;
`;

const Dashboard = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [rfiRows, setRfiRows] = useState([]);
  const [quickscanRows, setQuickscanRows] = useState([]);
  const [favoritesRows, setFavoritesRows] = useState([]);
  const [shortlistedRows, setShortlistedRows] = useState([]);
  const [customSearchesRows, setCustomSearchesRows] = useState([]);
  const [rfiModalOpen, setRfiModalOpen] = useState(false);
  const [customSearchesModalOpen, setCustomSearchesModalOpen] = useState(false);
  const [favoritesModalOpen, setFavoritesModalOpen] = useState(false);
  const [shortlistedModalOpen, setShortlistedModalOpen] = useState(false);
  const [quickscanModalOpen, setQuickscanModalOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dashboards.buyerDashboard = null;
    dashboards.getBuyerDashboard();
    customSearch.getCustomSearches();
  }, []);

  const createRFIData = (
    id,
    product_name,
    company_id,
    company_name,
    date,
    rfi_deadline
  ) => {
    return { id, product_name, company_id, company_name, date, rfi_deadline };
  };

  const createQuickscanData = (id, company_id, company_name, date, status) => {
    return { id, company_id, company_name, date, status };
  };

  const createFavoritesData = (id, company_id, company_name, date) => {
    return { id, company_id, company_name, date };
  };

  const createShortlistedData = (id, company_name, date, input) => {
    return { id, company_name, date, input };
  };

  const createCustomSearchesData = (id, product_name, date) => {
    return { id, product_name, date };
  };

  autoEffect(() => {
    if (dashboards.buyerDashboard) {
      setRfiRows(
        dashboards.buyerDashboard.Rfis.map((rfi) => {
          return createRFIData(
            rfi.id ? rfi.id : null,
            rfi.product_name ? rfi.product_name : t("no data"),
            rfi.supplier && rfi.supplier.id ? rfi.supplier.id : null,
            rfi.supplier && rfi.supplier.name
              ? rfi.supplier.name
              : t("no data"),
            rfi.created_at
              ? moment(rfi.created_at).format("DD.MM.YYYY.")
              : t("no data"),
            rfi.rfi_deadline
              ? moment(rfi.rfi_deadline).format("DD.MM.YYYY.")
              : t("no data")
          );
        })
      );

      setQuickscanRows(
        dashboards.buyerDashboard.OrderedQuickScans.map((quickscan) => {
          return createQuickscanData(
            quickscan.id ? quickscan.id : null,
            quickscan.company && quickscan.company.id
              ? quickscan.company_id
              : null,
            quickscan.company && quickscan.company.name
              ? quickscan.company.name
              : t("no data"),
            quickscan.created_at
              ? moment(quickscan.created_at).format("DD.MM.YYYY.")
              : t("no data"),
            quickscan.status ? quickscan.status : t("no data")
          );
        })
      );

      setFavoritesRows(
        dashboards.buyerDashboard.Favorites.map((favorite) => {
          return createFavoritesData(
            favorite.id ? favorite.id : null,
            favorite.company && favorite.company.id
              ? favorite.company.id
              : null,
            favorite.company && favorite.company.name
              ? favorite.company.name
              : t("no data"),
            favorite.created_at
              ? moment(favorite.created_at).format("DD.MM.YYYY.")
              : t("no data")
          );
        })
      );

      let sellersArray = [];

      dashboards.buyerDashboard.LongLists.map((list) => {
        list.sellers.map((seller) => {
          sellersArray.push({
            id: seller.id ? seller.id : null,
            name: seller.name ? seller.name : t("no data"),
            date: seller.created_at
              ? moment(seller.created_at).format("DD.MM.YYYY.")
              : t("no data"),
            input: list.name ? list.name : t("no data"),
          });

          return null;
        });

        return sellersArray;
      });

      setShortlistedRows(
        sellersArray.map((seller) => {
          return createShortlistedData(
            seller.id,
            seller.name,
            seller.date,
            seller.input
          );
        })
      );

      setCustomSearchesRows(
        dashboards.buyerDashboard.CustomSearches.map((search) => {
          return createCustomSearchesData(
            search.id ? search.id : null,
            search.product_name ? search.product_name : t("no data"),
            search.created_at
              ? moment(search.created_at).format("DD.MM.YYYY.")
              : t("no data")
          );
        })
      );
    }
  });

  const handleRfiModalOpen = () => {
    setRfiModalOpen(true);
  };

  const handleRFIModalClose = () => {
    setRfiModalOpen(false);
  };

  const handleCustomSearchesModalOpen = () => {
    setCustomSearchesModalOpen(true);
  };

  const handleCustomSearchesModalClose = () => {
    setCustomSearchesModalOpen(false);
  };

  const handleFavoritesModalOpen = () => {
    setFavoritesModalOpen(true);
  };

  const handleFavoritesModalClose = () => {
    setFavoritesModalOpen(false);
  };

  const handleShortlistedModalOpen = () => {
    setShortlistedModalOpen(true);
  };

  const handleShortlistedModalClose = () => {
    setShortlistedModalOpen(false);
  };

  const handleQuickscanModalOpen = () => {
    setQuickscanModalOpen(true);
  };

  const handleQuickscanModalClose = () => {
    setQuickscanModalOpen(false);
  };

  return (
    <Fragment>
      {!auth.user ||
      !dashboards.buyerDashboard ||
      dashboards.loading ||
      !rfiRows ||
      !quickscanRows ||
      !shortlistedRows ||
      !customSearchesRows ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={6}>
                  <h2>{auth.user.attributes.name}</h2>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={rfiModalOpen}
                    handleModalClose={handleRFIModalClose}
                  >
                    <StyledTableContainer>
                      <Table aria-label="rfi table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("product")}</TableCell>
                            <TableCell>{t("company")}</TableCell>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell align="right">
                              {t("rfq deadline")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rfiRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link to={`/rfq/${row.id}`}>
                                  {row.product_name}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={`/companies/${row.company_id}`}>
                                  <StyledSpecialCell>
                                    {row.company_name}
                                  </StyledSpecialCell>
                                </Link>
                              </TableCell>
                              <TableCell>{row.date}</TableCell>
                              <TableCell align="right">
                                {row.rfi_deadline}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </MainModal>
                  <h3>
                    {t("sent rfqs")} <span className={"separator"}>|</span>{" "}
                    <span>{rfiRows.length}</span>
                  </h3>
                  {rfiRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="rfi table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("product")}</TableCell>
                            <TableCell>{t("company")}</TableCell>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell align="right">
                              {t("rfq deadline")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rfiRows.map((row, index) => {
                            if (index <= 4) {
                              return (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Link to={`/rfq/${row.id}`}>
                                      {row.product_name}
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    <Link to={`/companies/${row.company_id}`}>
                                      <StyledSpecialCell>
                                        {row.company_name}
                                      </StyledSpecialCell>
                                    </Link>
                                  </TableCell>
                                  <TableCell>{row.date}</TableCell>
                                  <TableCell align="right">
                                    {row.rfi_deadline}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {rfiRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleRfiModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={customSearchesModalOpen}
                    handleModalClose={handleCustomSearchesModalClose}
                  >
                    <StyledTableContainer>
                      <Table aria-label="shortlisted table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("product")}</TableCell>
                            <TableCell align="right">{t("date")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {customSearchesRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link to={`/custom-search-results/${row.id}`}>
                                  {row.product_name}
                                </Link>
                              </TableCell>
                              <TableCell align="right">{row.date}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </MainModal>
                  <h3>
                    {t("custom searches")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{customSearchesRows.length}</span>
                  </h3>
                  {customSearchesRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="shortlisted table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("product")}</TableCell>
                            <TableCell align="right">{t("date")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {customSearchesRows.map((row, index) => {
                            if (index <= 4) {
                              return (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Link
                                      to={`/custom-search-results/${row.id}`}
                                    >
                                      {row.product_name}
                                    </Link>
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.date}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {customSearchesRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleCustomSearchesModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={favoritesModalOpen}
                    handleModalClose={handleFavoritesModalClose}
                  >
                    {favoritesRows.length > 0 ? (
                      <StyledTableContainer>
                        <Table aria-label="favorites table">
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("company name")}</TableCell>
                              <TableCell align="right">{t("date")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {favoritesRows.map((row, index) => (
                              <TableRow
                                key={row.id}
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Link to={`/companies/${row.id}`}>
                                    <StyledSpecialCell>
                                      {row.company_name}
                                    </StyledSpecialCell>
                                  </Link>
                                </TableCell>
                                <TableCell align="right">{row.date}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </StyledTableContainer>
                    ) : null}
                  </MainModal>
                  <h3>
                    {t("suppliers added to my suppliers")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{favoritesRows.length}</span>
                  </h3>
                  {favoritesRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="favorites table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("company name")}</TableCell>
                            <TableCell align="right">{t("date")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {favoritesRows.map((row, index) => {
                            if (index <= 4) {
                              return (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Link to={`/companies/${row.company_id}`}>
                                      <StyledSpecialCell>
                                        {row.company_name}
                                      </StyledSpecialCell>
                                    </Link>
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.date}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {favoritesRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleFavoritesModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={shortlistedModalOpen}
                    handleModalClose={handleShortlistedModalClose}
                  >
                    <StyledTableContainer>
                      <Table aria-label="shortlisted table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("company name")}</TableCell>
                            <TableCell>{t("search input")}</TableCell>
                            <TableCell align="right">{t("date")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {shortlistedRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link to={`/companies/${row.id}`}>
                                  <StyledSpecialCell>
                                    {row.company_name}
                                  </StyledSpecialCell>
                                </Link>
                              </TableCell>
                              <TableCell>{row.input}</TableCell>
                              <TableCell align="right">{row.date}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </MainModal>
                  <h3>
                    {t("shortlisted suppliers")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{shortlistedRows.length}</span>
                  </h3>
                  {shortlistedRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="shortlisted table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("company name")}</TableCell>
                            <TableCell>{t("search input")}</TableCell>
                            <TableCell align="right">{t("date")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {shortlistedRows.map((row, index) => {
                            if (index <= 4) {
                              return (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Link to={`/companies/${row.id}`}>
                                      <StyledSpecialCell>
                                        {row.company_name}
                                      </StyledSpecialCell>
                                    </Link>
                                  </TableCell>
                                  <TableCell>{row.input}</TableCell>
                                  <TableCell align="right">
                                    {row.date}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {shortlistedRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleShortlistedModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={quickscanModalOpen}
                    handleModalClose={handleQuickscanModalClose}
                  >
                    <StyledTableContainer>
                      <Table aria-label="quickscans table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("company name")}</TableCell>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell>{t("status")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quickscanRows.map((row, index) => (
                            <TableRow
                              key={row.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link to={`/companies/${row.company_id}`}>
                                  <StyledSpecialCell>
                                    {row.company_name}
                                  </StyledSpecialCell>
                                </Link>
                              </TableCell>
                              <TableCell>{row.date}</TableCell>
                              <StyledTableCell>
                                <Link to={`/admin-quickscan-report/${row.id}`}>
                                  {row.status}
                                </Link>
                                <StyledTableCellBorder status={row.status} />
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </MainModal>
                  <h3>
                    {t("quick scans ordered")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{quickscanRows.length}</span>
                  </h3>
                  {quickscanRows.length > 0 ? (
                    <StyledTableContainer>
                      <Table aria-label="quickscans table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("company name")}</TableCell>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell>{t("status")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quickscanRows.map((row, index) => {
                            if (index <= 4) {
                              return (
                                <TableRow
                                  key={row.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Link to={`/companies/${row.company_id}`}>
                                      <StyledSpecialCell>
                                        {row.company_name}
                                      </StyledSpecialCell>
                                    </Link>
                                  </TableCell>
                                  <TableCell>{row.date}</TableCell>
                                  <StyledTableCell>
                                    <Link
                                      to={`/admin-quickscan-report/${row.id}`}
                                    >
                                      {row.status}
                                    </Link>
                                    <StyledTableCellBorder
                                      status={row.status}
                                    />
                                  </StyledTableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  ) : null}
                  {quickscanRows.length > 5 ? (
                    <StyledSeeAllButton onClick={handleQuickscanModalOpen}>
                      {t("see all")}
                    </StyledSeeAllButton>
                  ) : null}
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
          {/* <div>
            <h2>Dashboard</h2>
            {JSON.stringify(customSearch.list)}
          </div> */}
        </Fragment>
      )}
    </Fragment>
  );
});

export default Dashboard;
