import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import styled from "styled-components";
import { view } from "@risingstack/react-easy-state";

import { auth } from "../../../stores/BaseStore";
import Loader from "../../shared/Loader";

import SearchInput from "../search/SearchInput";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  height: 100%;
`;

const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  height: 100%;
`;

const StyledGridFullHeight = styled(Grid)`
  height: 100%;
`;

const Search = view(() => {
  return (
    <Fragment>
      {!auth.user ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <StyledGridFullHeight container justify="center">
                <StyledGridFullHeight item xs={12} md={6}>
                  <SearchInput />
                </StyledGridFullHeight>
              </StyledGridFullHeight>
            </StyledIntroWrapper>
          </StyledIntro>
        </Fragment>
      )}
    </Fragment>
  );
});

export default Search;
