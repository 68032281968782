import React, { Fragment, useEffect, useState } from "react";
import { Grid, Box, InputLabel, Button } from "@material-ui/core";
import {
  InputField,
  CheckboxField,
  SelectField,
  DatePickerField,
} from "../../FormFields";
import { FieldArray } from "formik";
import styled from "styled-components";
import { DropzoneArea } from "material-ui-dropzone";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Formik, Form } from "formik";
import S3 from "aws-s3";
import AWS from "aws-sdk";

import fileUploadIcon from "assets/img/fileUpload.svg";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import Loader from "components/shared/Loader";
import { autoEffect, view } from "@risingstack/react-easy-state";
import LoaderAbsolute from "components/shared/LoaderAbsolute";
import MainModal from "components/shared/MainModal";
import { cities, company } from "stores/BaseStore";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledSectionOuter = styled(Box)`
  border-left: 2px solid #01e7aa;
  padding-left: 11px;
  margin-bottom: 60px;
`;

const StyledSectionInner = styled(Box)`
  background: #f3f9fa;
  padding: 40px;

  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    line-height: 93.1%;
    color: #000000;
    margin-bottom: 9px;
  }

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }

  .MuiDropzoneArea-root {
    height: 133px;
    border-radius: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .custom-dropzone {
    height: 200px;
    border-radius: 0;
    min-height: unset;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    display: block;
    margin-bottom: 29px;
    overflow-y: scroll;
    padding: 20px 40px;

    .MuiDropzoneArea-textContainer {
      padding: 20px;
    }

    .MuiDropzonePreviewList-imageContainer {
      text-align: left;
      border-top: 1px solid #dddddd;
      padding: 12px 0 6px 0;

      &:nth-last-child(1) {
        border-bottom: 1px solid #dddddd;
      }

      .MuiDropzonePreviewList-image {
        width: 50px;
        object-fit: cover;
      }

      .MuiDropzonePreviewList-removeButton {
        top: 16px;
        opacity: 1;
        background-color: transparent;
        box-shadow: none;
      }
    }

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: flex;
    justify-content: center;
  }

  .MuiSnackbar-root {
    right: 24px;
    left: auto;
  }

  .MuiDropzoneArea-text {
    display: none;
  }

  .MuiDropzonePreviewList-image {
    height: 50px;
  }
`;

// const StyledUploadButton = styled(Box)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: #ffffff;
//   border: 2px dashed #cecece;
//   height: 133px;
//   margin-bottom: 29px;
// `;

const UploadFakeLabel = styled.span`
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 9px;
`;

// const StyledParagraph = styled.p`
//   font-size: 14px;
//   line-height: 17px;
//   color: #000000;
//   margin: 0;
//   margin-bottom: 33px;
// `;

const StyledCheckboxWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButtonWithIcon = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledPlusSign = styled(Box)`
  height: 100%;
  width: 46px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #ffffff;
  }
`;

const StyledRemoveWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 22px;
`;

const StyledMinusSign = styled(Box)`
  height: 40px;
  width: 40px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: #ffffff;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledButtonText = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`;

const StyledInputLabel = styled.span`
  font-family: "RobotoRegular", "Arial", sans-serif;
  transform: none;
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 9px;
  color: #00c9e9;
  cursor: pointer;
  text-decoration: underline;
`;

const SpecialInputLabel = styled(InputLabel)`
  font-family: "RobotoRegular", "Arial", sans-serif;
  transform: none !important;
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 9px;

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }
`;

const StyledButtonPrimary = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const InputEmployees = (inputProps) => (
  <InputField
    type="number"
    fullWidth
    variant="outlined"
    size="small"
    {...inputProps}
  />
);

const InputTurnover = (inputProps) => (
  <InputField
    type="number"
    fullWidth
    variant="outlined"
    size="small"
    {...inputProps}
  />
);

const MainForm = view((props) => {
  const {
    formField: {
      name,
      industry,
      company_type,
      parent_company,
      number_of_employees,
      turnover,
      duns_number,
      tax_jurisdiction_code,
      supplier_type,
      communication_language,
      country_id,
      city_id,
      postcode,
      address,
      phone,
      email,
      website,
      short_description,
      iso9001_2015,
      iso14001,
      ohsas18001,
      iso9001_2015ExpiryDate,
      iso14001ExpiryDate,
      ohsas18001ExpiryDate,
      company_presentation,
      reference_list,
      photos,
      logo,
      vat_id,
    },
    initialValues,
    values,
    setFieldValue,
    companies,
    countryList,
    s3Urls,
    s3Logo,
    s3Presentation,
    s3Reference,
  } = props;
  const [preparedCities, setPreparedCities] = useState([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const [cityModalOpen, setCityModalOpen] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [photosObjects, setPhotosObjects] = useState([]);
  const [logoObjects, setLogoObjects] = useState([]);
  const [presentationObjects, setPresentationObjects] = useState([]);
  const [referenceObjects, setReferenceObjects] = useState([]);
  const [photoLoading, setPhotoLoading] = useState(false);

  autoEffect(async () => {
    if (!cities.filteredCities && initialValues.Country) {
      await cities.getCitiesByCountry(initialValues.Country.id);
    }

    if (
      cities.filteredCities &&
      cities.filteredCities.Cities &&
      cities.filteredCities.Cities.length > 0
    ) {
      setCityList(cities.filteredCities.Cities);
    }
  });

  useEffect(() => {
    if (cities.filteredCities) {
      prepareCities();
    }
    // eslint-disable-next-line
  }, [cityList]);

  useEffect(() => {
    setFieldValue(photos.name, photosObjects);
    // eslint-disable-next-line
  }, [photosObjects]);

  useEffect(() => {
    if (s3Urls) {
      setPhotosObjects(
        s3Urls.map((url) => {
          return {
            s3Url: url,
          };
        })
      );
    }

    if (s3Logo) {
      setLogoObjects(
        s3Logo.map((url) => {
          return {
            s3Url: url,
          };
        })
      );
    }

    if (s3Presentation) {
      setPresentationObjects(
        s3Presentation.map((url) => {
          return {
            s3Url: url,
          };
        })
      );
    }

    if (s3Reference) {
      setReferenceObjects(
        s3Reference.map((url) => {
          return {
            s3Url: url,
          };
        })
      );
    }

    // eslint-disable-next-line
  }, [s3Urls, s3Logo, s3Presentation, s3Reference]);

  let preparedCompanies = companies.map((company) => {
    return {
      value: company.id,
      label: company.name,
    };
  });

  preparedCompanies = [
    {
      value: null,
      label: t("none"),
    },
    ...preparedCompanies,
  ];

  const preparedCountries = Array.from(countryList)
    .sort((a, b) => {
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    })
    .map((country) => {
      return {
        value: country.id,
        label: country.name.charAt(0).toUpperCase() + country.name.slice(1),
        iso: country.iso,
      };
    });

  const prepareCities = () => {
    // const filteredCities = cityList.filter((city) => {
    //   return city.country_id === values.country_id;
    // });

    const preparedCities = Array.from(cityList)
      .sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      })
      .map((city) => {
        return {
          value: city.id,
          label: city.name.charAt(0).toUpperCase() + city.name.slice(1),
          postal_code: city.postal_code,
          country_id: city.country_id,
        };
      });

    setPreparedCities(preparedCities);
  };

  const updateCities = async () => {
    // const filteredCities = cities.cities.filter((city) => {
    //   return city.country_id === values.country_id;
    // });

    const preparedCities = cityList.map((city) => {
      return {
        value: city.id,
        label: city.name.charAt(0).toUpperCase() + city.name.slice(1),
        postal_code: city.postal_code,
        country_id: city.country_id,
      };
    });

    setPreparedCities(preparedCities);
  };

  useEffect(() => {
    if (values.country_id) {
      setCityList([]);
      cities.getCitiesByCountry(values.country_id);
    }
    // eslint-disable-next-line
  }, [values.country_id]);

  autoEffect(async () => {
    if (cities.sent) {
      await cities.getCities();
      await updateCities();
    }
  });

  // function NumberFormatCustomWithPrefix(props) {
  //   const { inputRef, onChange, ...other } = props;

  //   return (
  //     <NumberFormat
  //       {...other}
  //       getInputRef={inputRef}
  //       thousandSeparator={"."}
  //       decimalSeparator={","}
  //       isNumericString
  //       prefix="$"
  //     />
  //   );
  // }

  // function NumberFormatCustom(props) {
  //   const { inputRef, onChange, ...other } = props;

  //   console.log(props);

  //   return (
  //     <NumberFormat
  //       {...other}
  //       inputRef={inputRef}
  //       thousandSeparator={"."}
  //       decimalSeparator={","}
  //       isNumericString
  //       onChange={onChange}
  //     />
  //   );
  // }

  const validateCity = (values) => {
    const errors = {};

    if (!values.country_id) {
      errors.country_id = t("country required");
    }

    if (!values.city) {
      errors.city = t("city required");
    }

    if (
      values.postal_code &&
      (values.postal_code.length > 10 ||
        !values.postal_code.match(/^[a-z0-9]+$/i))
    ) {
      errors.postal_code = t("postcode is not valid");
    }

    return errors;
  };

  const handleCityModalOpen = () => {
    cities.sent = false;
    setCityModalOpen(true);
  };

  const handleCityModalClose = () => {
    setCityModalOpen(false);
    setTimeout(() => {
      cities.sent = false;
    }, 500);
  };

  const industries = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "Advertising",
      label: t("advertising"),
    },
    {
      value: "Agriculture",
      label: t("agriculture"),
    },
    {
      value: "Banking & Financial Services",
      label: t("banking & financial services"),
    },
    {
      value: "Civil Construction",
      label: t("civil construction"),
    },
    {
      value: "Consulting",
      label: t("consulting"),
    },
    {
      value: "Ecommerce",
      label: t("ecommerce"),
    },
    {
      value: "Education",
      label: t("education"),
    },
    {
      value: "Electronics / Hardware",
      label: t("electronics / hardware"),
    },
    {
      value: "Energy",
      label: t("energy"),
    },
    {
      value: "Fast Moving Consumer Goods",
      label: t("fast moving consumer goods"),
    },
    {
      value: "Healthcare",
      label: t("healthcare"),
    },
    {
      value: "Human Resources Service Provider",
      label: t("human resources service provider"),
    },
    {
      value: "Industrial Goods",
      label: t("industrial goods"),
    },
    {
      value: "IT Products",
      label: t("IT products"),
    },
    {
      value: "IT Services",
      label: t("IT services"),
    },
    {
      value: "Media",
      label: t("media"),
    },
    {
      value: "Real Estate",
      label: t("real estate"),
    },
    {
      value: "Retail",
      label: t("retail"),
    },
    {
      value: "Telecommunication",
      label: t("telecommunication"),
    },
    {
      value: "Tourism / Hotel / Restaurant",
      label: t("tourism / hotel / restaurant"),
    },
    {
      value: "Transport - Logistic",
      label: t("transport - logistic"),
    },
    {
      value: "Other",
      label: t("other"),
    },
  ];

  const company_types = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "headquarter",
      label: t("headquarter"),
    },
    {
      value: "subsidiary",
      label: t("subsidiary"),
    },
    {
      value: "manufacturing",
      label: t("manufacturing"),
    },
  ];

  const supplier_types = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "manufacturer-producer",
      label: t("manufacturer / producer"),
    },
    {
      value: "distributor",
      label: t("distributor"),
    },
    { value: "service-provider", label: t("service provider") },
    {
      value: "importer",
      label: t("importer"),
    },
  ];

  const languages = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "en",
      label: "English",
    },
    {
      value: "ru",
      label: "Russian",
    },
  ];

  return !initialValues ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <h2>{t("company details")}</h2>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <MainModal
            modalOpen={cityModalOpen}
            handleModalClose={handleCityModalClose}
          >
            {cities.loading ? <LoaderAbsolute /> : null}
            {!cities.loading && cities.sent ? (
              <p style={{ padding: "4rem 3rem" }}>
                {t("city created successfully.")}
              </p>
            ) : !cities.loading && cities.errors ? (
              <p style={{ padding: "4rem 3rem" }}>
                {t("there's been an error. please try again.")}
              </p>
            ) : (
              <Formik
                initialValues={{
                  country_id: "",
                  city: "",
                  postal_code: "",
                }}
                validate={validateCity}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  cities.createCity(values);
                }}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <SpecialInputLabel
                      htmlFor={country_id.name}
                      shrink={true}
                      variant="outlined"
                      required
                    >
                      {country_id.label}
                    </SpecialInputLabel>
                    <SelectField
                      id={country_id.name}
                      name={country_id.name}
                      data={preparedCountries}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                    <SpecialInputLabel
                      htmlFor="city"
                      shrink={true}
                      variant="outlined"
                      required
                    >
                      {t("city")}
                    </SpecialInputLabel>
                    <InputField
                      id="city"
                      name="city"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                    <SpecialInputLabel
                      htmlFor="postal_code"
                      shrink={true}
                      variant="outlined"
                    >
                      {t("postcode")}
                    </SpecialInputLabel>
                    <InputField
                      id="postal_code"
                      name="postal_code"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />

                    {isSubmitting}
                    <br />
                    <StyledButtonPrimary
                      type="button"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      {t("create city")}
                    </StyledButtonPrimary>
                  </Form>
                )}
              </Formik>
            )}
          </MainModal>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              {cities.loading || photoLoading ? <Loader /> : null}
              <InputLabel
                htmlFor={name.name}
                shrink={true}
                variant="outlined"
                required
              >
                {name.label}
              </InputLabel>
              <InputField
                id={name.name}
                name={name.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={industry.name}
                shrink={true}
                variant="outlined"
                required
              >
                {industry.label}
              </InputLabel>
              <SelectField
                id={industry.name}
                name={industry.name}
                data={industries}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={company_type.name}
                shrink={true}
                variant="outlined"
              >
                {company_type.label}
              </InputLabel>
              <SelectField
                id={company_type.name}
                name={company_type.name}
                data={company_types}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={parent_company.name}
                shrink={true}
                variant="outlined"
              >
                {parent_company.label}
              </InputLabel>
              <SelectField
                id={parent_company.name}
                name={parent_company.name}
                data={preparedCompanies}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={number_of_employees.name}
                shrink={true}
                variant="outlined"
                required
              >
                {number_of_employees.label}
              </InputLabel>
              <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                id={number_of_employees.name}
                name={number_of_employees.name}
                value={values.number_of_employees}
                onValueChange={(vals) =>
                  setFieldValue(number_of_employees.name, vals.value)
                }
                customInput={InputEmployees}
              />
              <InputLabel
                htmlFor={turnover.name}
                shrink={true}
                variant="outlined"
              >
                {turnover.label}
              </InputLabel>
              <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="$"
                isNumericString
                id={turnover.name}
                name={turnover.name}
                value={values.turnover}
                onValueChange={(vals) =>
                  setFieldValue(turnover.name, vals.value)
                }
                customInput={InputTurnover}
              />
              <InputLabel
                htmlFor={duns_number.name}
                shrink={true}
                variant="outlined"
              >
                {duns_number.label}
              </InputLabel>
              <InputField
                id={duns_number.name}
                name={duns_number.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={tax_jurisdiction_code.name}
                shrink={true}
                variant="outlined"
              >
                {tax_jurisdiction_code.label}
              </InputLabel>
              <InputField
                id={tax_jurisdiction_code.name}
                name={tax_jurisdiction_code.name}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel
                htmlFor={vat_id.name}
                shrink={true}
                variant="outlined"
                required
              >
                {vat_id.label}
              </InputLabel>
              <InputField
                id={vat_id.name}
                name={vat_id.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={country_id.name}
                shrink={true}
                variant="outlined"
                required
              >
                {country_id.label}
              </InputLabel>
              <SelectField
                id={country_id.name}
                name={country_id.name}
                data={preparedCountries}
                fullWidth
                variant="outlined"
                size="small"
              />
              <Box display="flex" justifyContent="space-between">
                <InputLabel
                  htmlFor={city_id.name}
                  shrink={true}
                  variant="outlined"
                  required
                >
                  {city_id.label}
                </InputLabel>
                <StyledInputLabel onClick={handleCityModalOpen}>
                  {t("add new")}
                </StyledInputLabel>
              </Box>
              <SelectField
                id={city_id.name}
                name={city_id.name}
                data={preparedCities}
                fullWidth
                variant="outlined"
                size="small"
                disabled={preparedCities.length === 0}
              />
              <InputLabel
                htmlFor={postcode.name}
                shrink={true}
                variant="outlined"
                required
              >
                {postcode.label}
              </InputLabel>
              <InputField
                id={postcode.name}
                name={postcode.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={address.name}
                shrink={true}
                variant="outlined"
                required
              >
                {address.label}
              </InputLabel>
              <InputField
                id={address.name}
                name={address.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={phone.name}
                shrink={true}
                variant="outlined"
                required
              >
                {phone.label}
              </InputLabel>
              <InputField
                id={phone.name}
                name={phone.name}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="+1 23 456 7890"
              />
              <InputLabel
                htmlFor={email.name}
                shrink={true}
                variant="outlined"
                required
              >
                {email.label}
              </InputLabel>
              <InputField
                id={email.name}
                name={email.name}
                fullWidth
                variant="outlined"
                size="small"
                type="email"
              />
              <InputLabel
                htmlFor={website.name}
                shrink={true}
                variant="outlined"
              >
                {website.label}
              </InputLabel>
              <InputField
                id={website.name}
                name={website.name}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="https://website.com"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel
                htmlFor={supplier_type.name}
                shrink={true}
                variant="outlined"
              >
                {supplier_type.label}
              </InputLabel>
              <SelectField
                id={supplier_type.name}
                name={supplier_type.name}
                data={supplier_types}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={communication_language.name}
                shrink={true}
                variant="outlined"
                required
              >
                {communication_language.label}
              </InputLabel>
              <SelectField
                id={communication_language.name}
                name={communication_language.name}
                data={languages}
                fullWidth
                variant="outlined"
                size="small"
              />
              <UploadFakeLabel>{logo.label}</UploadFakeLabel>
              <DropzoneArea
                id={logo.name}
                name={logo.name}
                filesLimit={1}
                dropzoneText=""
                showPreviewsInDropzone
                previewGridProps={{ container: { spacing: 0 } }}
                acceptedFiles={[".png", ".jpg", ".jpeg"]}
                initialFiles={s3Logo}
                Icon={() => {
                  return (
                    <Fragment>
                      <img
                        src={fileUploadIcon}
                        alt="file upload icon"
                        style={{ marginBottom: "15px" }}
                      />
                      {values.logo.length === 0 ? (
                        <p>{t("drag and drop your file here")}</p>
                      ) : null}
                    </Fragment>
                  );
                }}
                onChange={(e) => {
                  setFieldValue(logo.name, e);
                }}
                dropzoneProps={{
                  disabled: logoObjects.length > 0,
                }}
                onDrop={async (acceptedFiles) => {
                  // do nothing if no files
                  if (acceptedFiles.length === 0) {
                    return;
                  }

                  setPhotoLoading(true);

                  const config = {
                    bucketName: process.env.REACT_APP_S3_BUCKET,
                    dirName: `media/${company.company.id}/logo` /* optional */,
                    region: "eu-central-1",
                    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
                    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
                    s3Url: process.env.REACT_APP_S3_ENDPOINT,
                  };

                  const S3Client = new S3(config);

                  let tempPhotoArray = [];

                  const prom = (file) => {
                    return new Promise((resolve, reject) => {
                      S3Client.uploadFile(file)
                        .then((data) => {
                          tempPhotoArray.push({
                            localName: file.name,
                            s3Name: data.key.substring(
                              data.key.lastIndexOf("/") + 1
                            ),
                          });
                          setLogoObjects([...logoObjects, ...tempPhotoArray]);
                          resolve();
                        })
                        .catch((err) => {
                          console.error(err);
                          reject();
                        });
                    });
                  };

                  const promises = acceptedFiles.map((file) => {
                    return prom(file);
                  });

                  await Promise.all(promises)
                    .then(() => setPhotoLoading(false))
                    .catch((err) => console.log(err));
                }}
                onDelete={(file) => {
                  let s3Logo = logoObjects.find((logo) => {
                    return logo.localName === file.name;
                  });

                  const s3 = new AWS.S3({
                    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
                    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
                  });

                  const params = {
                    Bucket: process.env.REACT_APP_S3_BUCKET,
                    Key: `media/${company.company.id}/logo/${
                      s3Logo ? s3Logo.s3Name : file.name
                    }`,
                  };

                  s3.deleteObject(params, function (err, data) {
                    if (err) console.log(err, err.stack);
                    // else deleted
                  });

                  setLogoObjects(
                    logoObjects.filter((logo) => {
                      return (
                        logo.localName !== file.name &&
                        logo.s3Url !==
                          `${process.env.REACT_APP_S3_ENDPOINT}/media/${company.company.id}/logo/${file.name}`
                      );
                    })
                  );
                }}
              />
              <Grid container spacing={matches ? 6 : 0}>
                <Grid item xs={12} xl={6}>
                  <UploadFakeLabel>
                    {company_presentation.label}
                  </UploadFakeLabel>
                  <DropzoneArea
                    id={company_presentation.name}
                    name={company_presentation.name}
                    filesLimit={1}
                    dropzoneText=""
                    showPreviewsInDropzone
                    previewGridProps={{ container: { spacing: 0 } }}
                    acceptedFiles={[".pdf"]}
                    initialFiles={s3Presentation}
                    Icon={() => {
                      return (
                        <Fragment>
                          <img
                            src={fileUploadIcon}
                            alt="file upload icon"
                            style={{ marginBottom: "15px" }}
                          />
                          {values.company_presentation.length === 0 ? (
                            <p>{t("drag and drop your file here")}</p>
                          ) : null}
                        </Fragment>
                      );
                    }}
                    onChange={(e) => {
                      setFieldValue(company_presentation.name, e);
                    }}
                    dropzoneProps={{
                      disabled: presentationObjects.length > 0,
                    }}
                    onDrop={async (acceptedFiles) => {
                      // do nothing if no files
                      if (acceptedFiles.length === 0) {
                        return;
                      }

                      setPhotoLoading(true);

                      const config = {
                        bucketName: process.env.REACT_APP_S3_BUCKET,
                        dirName: `media/${company.company.id}/company-presentation` /* optional */,
                        region: "eu-central-1",
                        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
                        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
                        s3Url: process.env.REACT_APP_S3_ENDPOINT,
                      };

                      const S3Client = new S3(config);

                      let tempPhotoArray = [];

                      const prom = (file) => {
                        return new Promise((resolve, reject) => {
                          S3Client.uploadFile(file)
                            .then((data) => {
                              tempPhotoArray.push({
                                localName: file.name,
                                s3Name: data.key.substring(
                                  data.key.lastIndexOf("/") + 1
                                ),
                              });
                              setPresentationObjects([
                                ...presentationObjects,
                                ...tempPhotoArray,
                              ]);
                              resolve();
                            })
                            .catch((err) => {
                              console.error(err);
                              reject();
                            });
                        });
                      };

                      const promises = acceptedFiles.map((file) => {
                        return prom(file);
                      });

                      await Promise.all(promises)
                        .then(() => setPhotoLoading(false))
                        .catch((err) => console.log(err));
                    }}
                    onDelete={(file) => {
                      let s3Presentation = presentationObjects.find(
                        (presentation) => {
                          return presentation.localName === file.name;
                        }
                      );

                      const s3 = new AWS.S3({
                        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
                        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
                      });

                      const params = {
                        Bucket: process.env.REACT_APP_S3_BUCKET,
                        Key: `media/${
                          company.company.id
                        }/company-presentation/${
                          s3Presentation ? s3Presentation.s3Name : file.name
                        }`,
                      };

                      s3.deleteObject(params, function (err, data) {
                        if (err) console.log(err, err.stack);
                        // else deleted
                      });

                      setPresentationObjects(
                        presentationObjects.filter((presentation) => {
                          return (
                            presentation.localName !== file.name &&
                            presentation.s3Url !==
                              `${process.env.REACT_APP_S3_ENDPOINT}/media/${company.company.id}/company-presentation/${file.name}`
                          );
                        })
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <UploadFakeLabel>{reference_list.label}</UploadFakeLabel>
                  <DropzoneArea
                    id={reference_list.name}
                    name={reference_list.name}
                    filesLimit={1}
                    dropzoneText=""
                    showPreviewsInDropzone
                    previewGridProps={{ container: { spacing: 0 } }}
                    acceptedFiles={[".pdf"]}
                    initialFiles={s3Reference}
                    Icon={() => {
                      return (
                        <Fragment>
                          <img
                            src={fileUploadIcon}
                            alt="file upload icon"
                            style={{ marginBottom: "15px" }}
                          />
                          {values.reference_list.length === 0 ? (
                            <p>{t("drag and drop your file here")}</p>
                          ) : null}
                        </Fragment>
                      );
                    }}
                    onChange={(e) => {
                      setFieldValue(reference_list.name, e);
                    }}
                    dropzoneProps={{
                      disabled: referenceObjects.length > 0,
                    }}
                    onDrop={async (acceptedFiles) => {
                      // do nothing if no files
                      if (acceptedFiles.length === 0) {
                        return;
                      }

                      setPhotoLoading(true);

                      const config = {
                        bucketName: process.env.REACT_APP_S3_BUCKET,
                        dirName: `media/${company.company.id}/reference-list` /* optional */,
                        region: "eu-central-1",
                        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
                        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
                        s3Url: process.env.REACT_APP_S3_ENDPOINT,
                      };

                      const S3Client = new S3(config);

                      let tempPhotoArray = [];

                      const prom = (file) => {
                        return new Promise((resolve, reject) => {
                          S3Client.uploadFile(file)
                            .then((data) => {
                              tempPhotoArray.push({
                                localName: file.name,
                                s3Name: data.key.substring(
                                  data.key.lastIndexOf("/") + 1
                                ),
                              });
                              setReferenceObjects([
                                ...referenceObjects,
                                ...tempPhotoArray,
                              ]);
                              resolve();
                            })
                            .catch((err) => {
                              console.error(err);
                              reject();
                            });
                        });
                      };

                      const promises = acceptedFiles.map((file) => {
                        return prom(file);
                      });

                      await Promise.all(promises)
                        .then(() => setPhotoLoading(false))
                        .catch((err) => console.log(err));
                    }}
                    onDelete={(file) => {
                      let s3Reference = referenceObjects.find((reference) => {
                        return reference.localName === file.name;
                      });

                      const s3 = new AWS.S3({
                        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
                        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
                      });

                      const params = {
                        Bucket: process.env.REACT_APP_S3_BUCKET,
                        Key: `media/${company.company.id}/reference-list/${
                          s3Reference ? s3Reference.s3Name : file.name
                        }`,
                      };

                      s3.deleteObject(params, function (err, data) {
                        if (err) console.log(err, err.stack);
                        // else deleted
                      });

                      setReferenceObjects(
                        referenceObjects.filter((reference) => {
                          return (
                            reference.localName !== file.name &&
                            reference.s3Url !==
                              `${process.env.REACT_APP_S3_ENDPOINT}/media/${company.company.id}/reference-list/${file.name}`
                          );
                        })
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputLabel
                htmlFor={short_description.name}
                shrink={true}
                variant="outlined"
                required
              >
                {short_description.label}
              </InputLabel>
              <InputField
                id={short_description.name}
                name={short_description.name}
                fullWidth
                variant="outlined"
                size="small"
                multiline
                rows={4}
                placeholder={t("company description placeholder")}
              />
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6}>
              <UploadFakeLabel>{photos.label}</UploadFakeLabel>
              <DropzoneArea
                id={photos.name}
                name={photos.name}
                dropzoneText=""
                filesLimit={100}
                showPreviewsInDropzone
                dropzoneClass="custom-dropzone"
                previewGridProps={{
                  container: { spacing: 0 },
                  item: { xs: 12 },
                }}
                acceptedFiles={[".png", ".jpg", ".jpeg"]}
                initialFiles={s3Urls}
                dropzoneProps={{
                  disabled: photosObjects.length >= 100,
                }}
                Icon={() => {
                  return (
                    <Fragment>
                      <img
                        src={fileUploadIcon}
                        alt="file upload icon"
                        style={{ marginBottom: "15px" }}
                      />
                      {values.photos.length === 0 ? (
                        <p>{t("company photos placeholder")}</p>
                      ) : null}
                    </Fragment>
                  );
                }}
                onChange={(e) => {
                  setFieldValue(photos.name, e);
                }}
                onDrop={async (acceptedFiles) => {
                  // do nothing if no files
                  if (acceptedFiles.length === 0) {
                    return;
                  }

                  setPhotoLoading(true);

                  const config = {
                    bucketName: process.env.REACT_APP_S3_BUCKET,
                    dirName: `media/${company.company.id}/image` /* optional */,
                    region: "eu-central-1",
                    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
                    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
                    s3Url: process.env.REACT_APP_S3_ENDPOINT,
                  };

                  const S3Client = new S3(config);

                  let tempPhotoArray = [];

                  const prom = (file) => {
                    return new Promise((resolve, reject) => {
                      S3Client.uploadFile(file)
                        .then((data) => {
                          tempPhotoArray.push({
                            localName: file.name,
                            s3Name: data.key.substring(
                              data.key.lastIndexOf("/") + 1
                            ),
                          });
                          setPhotosObjects([
                            ...photosObjects,
                            ...tempPhotoArray,
                          ]);
                          resolve();
                        })
                        .catch((err) => {
                          console.error(err);
                          reject();
                        });
                    });
                  };

                  const promises = acceptedFiles.map((file) => {
                    return prom(file);
                  });

                  await Promise.all(promises)
                    .then(() => setPhotoLoading(false))
                    .catch((err) => console.log(err));
                }}
                onDelete={(file) => {
                  let s3Photo = photosObjects.find((photo) => {
                    return photo.localName === file.name;
                  });

                  const s3 = new AWS.S3({
                    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
                    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
                  });

                  const params = {
                    Bucket: process.env.REACT_APP_S3_BUCKET,
                    Key: `media/${company.company.id}/image/${
                      s3Photo ? s3Photo.s3Name : file.name
                    }`,
                  };

                  s3.deleteObject(params, function (err, data) {
                    if (err) console.log(err, err.stack);
                    // else deleted
                  });

                  setPhotosObjects(
                    photosObjects.filter((photo) => {
                      return (
                        photo.localName !== file.name &&
                        photo.s3Url !==
                          `${process.env.REACT_APP_S3_ENDPOINT}/media/${company.company.id}/image/${file.name}`
                      );
                    })
                  );
                }}
              />
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      <Grid container>
        <Grid item xs={12}>
          <h3>{t("certificates")}</h3>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <StyledCheckboxWrapper>
                <Grid container>
                  <Grid item xs={12} lg={2}>
                    <CheckboxField
                      name={iso9001_2015.name}
                      label={iso9001_2015.label}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <DatePickerField
                      name={iso9001_2015ExpiryDate.name}
                      format="dd/MM/yyyy"
                      minDate={new Date()}
                      maxDate={new Date("2050/12/31")}
                      fullWidth
                      placeholder={t("expiry date")}
                      disabled={!values.iso9001_2015}
                    />
                  </Grid>
                </Grid>
              </StyledCheckboxWrapper>
            </Grid>
            <Grid item xs={12}>
              <StyledCheckboxWrapper>
                <Grid container>
                  <Grid item xs={12} lg={2}>
                    <CheckboxField
                      name={iso14001.name}
                      label={iso14001.label}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <DatePickerField
                      name={iso14001ExpiryDate.name}
                      format="dd/MM/yyyy"
                      minDate={new Date()}
                      maxDate={new Date("2050/12/31")}
                      fullWidth
                      placeholder={t("expiry date")}
                      disabled={!values.iso14001}
                    />
                  </Grid>
                </Grid>
              </StyledCheckboxWrapper>
            </Grid>
            <Grid item xs={12}>
              <StyledCheckboxWrapper>
                <Grid container>
                  <Grid item xs={12} lg={2}>
                    <CheckboxField
                      name={ohsas18001.name}
                      label={ohsas18001.label}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <DatePickerField
                      name={ohsas18001ExpiryDate.name}
                      format="dd/MM/yyyy"
                      minDate={new Date()}
                      maxDate={new Date("2050/12/31")}
                      fullWidth
                      placeholder={t("expiry date")}
                      disabled={!values.ohsas18001}
                    />
                  </Grid>
                </Grid>
              </StyledCheckboxWrapper>
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      <Grid container>
        <Grid item xs={12}>
          <h3>{t("other certificates")}</h3>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <FieldArray
              name="certificates"
              render={(arrayHelpers) => (
                <Fragment>
                  {values.certificates.map((certificate, index) => (
                    <Fragment key={index}>
                      <Grid item xs={12} lg={3}>
                        {/** both these conventions do the same */}
                        <InputLabel
                          htmlFor={`certificates[${index}].name`}
                          shrink={true}
                          variant="outlined"
                        >
                          {t("name")}
                        </InputLabel>
                        <InputField
                          id={`certificates[${index}].name`}
                          name={`certificates[${index}].name`}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <InputLabel
                          htmlFor={`certificates.${index}.number`}
                          shrink={true}
                          variant="outlined"
                        >
                          {t("number")}
                        </InputLabel>
                        <InputField
                          id={`certificates.${index}.number`}
                          name={`certificates.${index}.number`}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <InputLabel
                          htmlFor={`certificates.${index}.expiry_date`}
                          shrink={true}
                          variant="outlined"
                        >
                          {t("expiry date")}
                        </InputLabel>
                        <DatePickerField
                          id={`certificates.${index}.expiry_date`}
                          name={`certificates.${index}.expiry_date`}
                          format="dd/MM/yyyy"
                          minDate={new Date()}
                          maxDate={new Date("2050/12/31")}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <StyledRemoveWrapper>
                          <StyledMinusSign
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <RemoveIcon />
                          </StyledMinusSign>
                        </StyledRemoveWrapper>
                      </Grid>
                    </Fragment>
                  ))}

                  <Grid item xs={12}>
                    <StyledButtonWithIcon
                      onClick={() =>
                        arrayHelpers.push({
                          name: "",
                          number: "",
                          expiry_date: "",
                        })
                      }
                    >
                      <StyledPlusSign>
                        <AddIcon />
                      </StyledPlusSign>
                      <StyledButtonText>
                        {t("add certificate")}
                      </StyledButtonText>
                    </StyledButtonWithIcon>
                  </Grid>
                </Fragment>
              )}
            />
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      <Grid container>
        <Grid item xs={12}>
          <h3>{t("production location")}</h3>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <FieldArray
              name="production_locations"
              render={(arrayHelpers) => (
                <Fragment>
                  {values.production_locations.map((location, index) => (
                    <Fragment key={index}>
                      <Grid item xs={12} lg={8}>
                        {/** both these conventions do the same */}
                        <InputLabel
                          htmlFor={`production_locations[${index}].address`}
                          shrink={true}
                          variant="outlined"
                        >
                          {t("address")}
                        </InputLabel>
                        <InputField
                          id={`production_locations[${index}].address`}
                          name={`production_locations[${index}].address`}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <InputLabel
                          htmlFor={`production_locations.${index}.short_description`}
                          shrink={true}
                          variant="outlined"
                        >
                          {t("description")}
                        </InputLabel>
                        <InputField
                          id={`production_locations.${index}.short_description`}
                          name={`production_locations.${index}.short_description`}
                          fullWidth
                          variant="outlined"
                          size="small"
                          multiline
                          rows={4}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <StyledRemoveWrapper>
                          <StyledMinusSign
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <RemoveIcon />
                          </StyledMinusSign>
                        </StyledRemoveWrapper>
                      </Grid>
                    </Fragment>
                  ))}

                  <Grid item xs={12}>
                    <StyledButtonWithIcon
                      onClick={() =>
                        arrayHelpers.push({
                          address: "",
                          short_description: "",
                        })
                      }
                    >
                      <StyledPlusSign>
                        <AddIcon />
                      </StyledPlusSign>
                      <StyledButtonText>{t("add location")}</StyledButtonText>
                    </StyledButtonWithIcon>
                  </Grid>
                </Fragment>
              )}
            />
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
      {/* <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            {productionLocation > 0
              ? Array.from(Array(productionLocation), (e, i) => {
                  return (
                    <Fragment>
                      <Grid item xs={12} lg={4}>
                        <InputLabel
                          htmlFor={`productionLocationCertificateName${i}`}
                          shrink={true}
                          variant="outlined"
                        >
                          Certificate name
                        </InputLabel>
                        <InputField
                          id={`productionLocationCertificateName${i}`}
                          name={`productionLocationCertificateName${i}`}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <InputLabel
                          htmlFor={`productionLocationDescription${i}`}
                          shrink={true}
                          variant="outlined"
                        >
                          Description
                        </InputLabel>
                        <InputField
                          id={`productionLocationDescription${i}`}
                          name={`productionLocationDescription${i}`}
                          fullWidth
                          variant="outlined"
                          size="small"
                          multiline
                          rows={4}
                        />
                      </Grid>
                    </Fragment>
                  );
                })
              : null}
            <Grid item xs={12}>
              <StyledButtonWithIcon
                onClick={() => setProductionLocation(productionLocation + 1)}
              >
                <StyledPlusSign>
                  <AddIcon />
                </StyledPlusSign>
                <StyledButtonText>Add location</StyledButtonText>
              </StyledButtonWithIcon>
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter> */}
    </Fragment>
  );
});

export default MainForm;
