import React, { useState, useEffect } from "react";
import { useField } from "formik";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import calendarIcon from "assets/img/calendarIcon.svg";

const StyledDatePicker = styled(KeyboardDatePicker)`
  // margin-bottom: 30px;
  background: #ffffff;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      // borderColor: "green",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      // borderColor: "red",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#06D7F9",
    },
    "& .MuiOutlinedInput-input": {
      // color: "green",
    },
    "&:hover .MuiOutlinedInput-input": {
      // color: "red",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#06D7F9",
    },
    "& .MuiInputLabel-outlined": {
      // color: "green",
    },
    "&:hover .MuiInputLabel-outlined": {
      // color: "red",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#06D7F9",
    },
  },
});

export default function DatePickerField(props) {
  const classes = useStyles();
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(null);
  const { initialvalue } = props;

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    }
  }, [value]);

  function _onChange(date) {
    if (date) {
      setSelectedDate(date);
      try {
        const ISODateString = date.toISOString();
        const ISOWithoutTime = ISODateString.split("T");
        setValue(ISOWithoutTime[0]);
      } catch (error) {
        setValue(date);
      }
    } else {
      setValue(date);
    }
  }

  return (
    <Grid container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StyledDatePicker
          {...field}
          {...props}
          value={selectedDate || initialvalue || null}
          onChange={_onChange}
          error={isError}
          invalidDateMessage={isError && error}
          helperText={isError && error}
          variant="inline"
          inputVariant="outlined"
          size="small"
          disableToolbar
          keyboardIcon={<img src={calendarIcon} alt="calendar icon" />}
          className={classes.root}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
}
