import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { Switch, Route, withRouter } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import logo from "../../assets/img/logo.svg";
import logoBlack from "../../assets/img/logoBlack.svg";
import drawerButtonLeft from "../../assets/img/drawerButtonLeft.svg";
import drawerButtonRight from "../../assets/img/drawerButtonRight.svg";
import profileIcon from "../../assets/img/profileIcon.svg";
import logoutIcon from "../../assets/img/logoutIcon.svg";
import infoIcon from "../../assets/img/infoIcon.svg";
import infoIconWhite from "../../assets/img/infoIconWhite.svg";
import chatIcon from "../../assets/img/chatIcon.svg";
import chatIconWhite from "../../assets/img/chatIconWhite.svg";
import closeIcon from "../../assets/img/closeIcon.svg";
import openDrawerIconWhite from "../../assets/img/openDrawerIconWhite.svg";

import { autoEffect, view } from "@risingstack/react-easy-state";
import {
  auth,
  gdpr,
  selfAssessment,
  company,
  quickscan,
  language,
} from "../../stores/BaseStore";

import ProtectedRoute from "../protected/ProtectedRoute";

import Loader from "../shared/Loader";
import Login from "../shared/Login";
import AcceptTerms from "../gdpr/AcceptTerms";
import TermsAndConditions from "../gdpr/TermsAndConditions";
import Privacy from "../gdpr/Privacy";
import Impressum from "../gdpr/Impressum";
import SelfAssessment from "./routes/SelfAssessment";
import CompanyDetails from "./routes/CompanyDetails";
import Lean from "./routes/Lean";
import Quickscan from "./routes/Quickscan";
import SupplierProducts from "./routes/SupplierProducts";
import Report from "./routes/Report";
import SpecificReport from "components/shared/SpecificReport";
import RFI from "./routes/RFI";
import Dashboard from "./routes/Dashboard";
import UserProfile from "components/shared/UserProfile";
import Help from "components/shared/Help";
import FAQ from "components/shared/FAQ";
import CompanyProfile from "./routes/CompanyProfile";
import AdminQuickscanReport from "components/supplier/routes/AdminQuickscanReport";

const drawerWidth = 260;

const StyledSpacer = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const StyledAppbar = styled(AppBar)`
  background-color: #000000;
  min-height: 75px;
  box-shadow: none;
  border-bottom: 1px solid #383838;
`;

const StyledToolbar = styled(Toolbar)`
  height: 100%;
  min-height: 75px;

  .MuiIconButton-root {
    background: url(${drawerButtonRight});
    width: 78px;
    height: 75px;
    border-radius: 0;
  }

  .MuiIconButton-edgeStart {
    margin-left: 0;
  }
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    color: #000000;
    background-color: #ffffff;
    text-transform: uppercase;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    // border-right: 1px solid #dddddd;
    border: none;
  }

  .MuiIconButton-root {
    background: url(${drawerButtonLeft});
    width: 78px;
    height: 75px;
    border-radius: 0;
    color: #ffffff;
  }
`;

const StyledList = styled(List)`
  padding-bottom: 43px;
  padding-top: 43px;
  border-right: 1px solid #dddddd;
  height: 100%;

  a,
  .MuiButtonBase-root {
    padding: 0 56px;

    .MuiTypography-root {
      padding: 16px 0;
      font-size: 14px;
      line-height: 17px;
      font-family: "RobotoMedium", "Arial", sans-serif;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      border-bottom: 1px solid #dddddd;
    }

    #borderless {
      .MuiTypography-root {
        border-bottom: none;
      }
    }
  }

  .MuiListItem-button {
    &:hover {
      text-decoration: none;
      background-color: unset;
      color: #06d7f9;
    }
  }

  [type="active"] {
    span {
      color: #06d7f9;
    }
  }

  svg {
    position: absolute;
    right: 35px;
  }
`;

const StyledNestedList = styled(List)`
  border-right: 1px solid #dddddd;
  height: 100%;

  a,
  .MuiButtonBase-root {
    padding: 0 56px;
    margin-left: 30px;

    .MuiTypography-root {
      padding: 16px 0;
      font-size: 14px;
      line-height: 17px;
      font-family: "RobotoMedium", "Arial", sans-serif;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      border-bottom: 1px solid #dddddd;
    }

    #borderless {
      .MuiTypography-root {
        border-bottom: none;
      }
    }
  }

  .MuiListItem-button {
    &:hover {
      text-decoration: none;
      background-color: unset;
      color: #06d7f9;
    }
  }

  [type="active"] {
    span {
      color: #06d7f9;
    }
  }
`;

const StyledToolbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledToolbarButton = styled.div`
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 12px;
  }

  span {
    color: #42eae5;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledHelpDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    color: #000000;
    background-color: #ffffff;
    text-transform: uppercase;
    // height: 1032px;
    z-index: 99999;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    // border-right: 1px solid #dddddd;
    border: none;
  }

  .MuiIconButton-root {
    background: url(${drawerButtonLeft});
    width: 78px;
    height: 75px;
    border-radius: 0;
    color: #ffffff;
  }
`;

const StyledHelpBorder = styled(Box)`
  position: fixed;
  top: 0;
  right: ${(props) => (props.open ? `${drawerWidth}px` : 0)};
  height: 100%;
  width: 18px;
  background: linear-gradient(13.55deg, #00c9e9 0.09%, #00e8a6 80.26%);
  z-index: 9999;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  display: flex;
  align-items: center;
  z-index: 99999;
`;

const StyledHelpButtons = styled(Box)`
  width: 52px;
  border: 3px solid #01dcc1;
  border-radius: 13px;
  background-color: #ffffff;
  position: absolute;
  right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTopButton = styled(IconButton)`
  width: 100%;
  height: 100%;
  border-radius: 13px 13px 0 0;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #ffffff;
    opacity: 0.8;
  }

  &.active {
    border-radius: 6px 6px 0 0;
    background-color: #01dcc1;

    &:hover {
      background-color: #01dcc1;
    }
  }
`;
const StyledBottomButton = styled(IconButton)`
  width: 100%;
  height: 100%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }

  &.active {
    border-radius: 0 0 6px 6px;
    background-color: #01dcc1;

    &:hover {
      background: #01dcc1;
    }
  }
`;

const StyledCloseButton = styled(Box)`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledSidebarWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  h3 {
    font-family: "NeueMachinaRegular", "Arial", sans-serif;
    font-size: 21px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 30px;
  }

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
    margin-bottom: 20px;
    text-transform: none;
  }
`;

const StyledButtonPrimary = styled.button`
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #06d7f9;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #06d7f9;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 8px;
  }
`;

const StyledMainWrapper = styled(Box)`
  padding: ${(props) => (props.pathname === "/" ? "24px 0" : "24px")};
`;

const StyledTopBar = styled(Box)`
  width: 100%;
  background: black;
  // height: ${(props) => (props.openTopBar ? "250px" : 0)};
  min-height: ${(props) =>
    props.openTopBar && props.matches
      ? "200px"
      : props.openTopBar && !props.matches
      ? "340px"
      : 0};
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding-top: ${(props) => (props.openTopBar ? "35px" : 0)};
`;

const StyledTopBarFooter = styled(Box)`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid #474747;
  cursor: pointer;
  background-color: #474747;

  img {
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: ${(props) =>
      props.openTopBar ? "rotate(0deg)" : "rotate(180deg)"};
  }
`;

const StyledRatingWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 35px;

  h3 {
    font-family: "NeueMachinaUltraBold";
    font-size: 28px;
    line-height: 17px;
    color: #ffffff;

    span {
      font-size: 14px;
      vertical-align: middle;
    }
  }
`;

const StyledRatingInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h4 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    color: #ffffff;
    margin: 0;
    margin-bottom: 24px;
  }

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: #c7c7c7;
    margin: 0;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(0, 1),
    padding: 0,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    minHeight: "75px !important",
  },
  toolbarClosed: {
    paddingLeft: 0,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    padding: 0,
    marginTop: "75px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  dropdownMenu: {
    display: "flex",
    alignItems: "center",
  },
  dropdownIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const useStylesRating = makeStyles((props) => ({
  root: {
    position: "relative",
    marginRight: "26px",
  },
  bottom: {
    color: "#444444",
  },
  top: {
    animationDuration: "550ms",
    position: "absolute",
    left: -4,
    top: -4,
  },
  red: { color: "#FF490F" },
  orange: { color: "#FF7B51" },
  yellow: { color: "#FFC527" },
  blue: { color: "#06D7F9" },
  green: { color: "#01E7AA" },
  circle: {
    // strokeLinecap: "round",
  },
}));

function ListItemLink(props) {
  const { primary, to, id, pathname } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link
          to={to}
          ref={ref}
          {...itemProps}
          type={to === pathname ? "active" : null}
        />
      )),
    [to, pathname]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        <ListItemText primary={primary} id={id} />
      </ListItem>
    </li>
  );
}

const logout = async () => {
  await auth.logout();
  company.company = null;
  // history.push("/login");
};

const AppbarSupplier = view((props) => {
  const classes = useStyles();
  const classesRating = useStylesRating();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(true);
  const [openSideBar, setOpenSidebar] = useState(false);
  const [openTopBar, setOpenTopBar] = useState(false);
  const { pathname } = props.location;
  const [sidebarContent, setSidebarContent] = useState(null);
  const [topBarContent, setTopBarContent] = useState(false);
  const [openMultilevel, setOpenMultilevel] = useState(false);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickLanguage = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorEl(null);
  };

  const handlePickLanguage = (lang) => {
    language.setLanguage(lang);
    setAnchorEl(null);
  };

  autoEffect(() => {
    if (!selfAssessment.selfAssessment) {
      selfAssessment.getSelfAssessment();
    }
  }, [selfAssessment.selfAssessment]);

  useEffect(() => {
    if (pathname === "/self-assessment" || pathname === "/") {
      setOpenTopBar(true);
    } else {
      setOpenTopBar(false);
    }

    if (pathname === "/quickscan" || pathname === "/lean") {
      setSidebarContent("info");
      setOpenSidebar(true);
    } else {
      setOpenSidebar(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (matches) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
  }, [matches]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleTopBar = () => {
    setOpenTopBar(!openTopBar);
  };

  // const handleDrawerOpenHelp = () => {
  //   setOpenSidebar(true);
  // };

  const handleDrawerCloseHelp = () => {
    setOpenSidebar(false);
    setSidebarContent(null);
  };

  const handleMultilevel = () => {
    setOpenMultilevel(!openMultilevel);
  };

  const handleSidebarContent = (content) => {
    setSidebarContent(content);
    setOpenSidebar(true);
  };

  const renderSidebarContent = (path) => {
    switch (path) {
      case "/self-assessment":
        return (
          <StyledSidebarWrapper>
            <div>
              <h3>{t("tutorial")}</h3>
              <p>{t("tutorial-info")}</p>
              <StyledButtonPrimary>
                <PlayCircleFilledIcon />
                {t("video")}
              </StyledButtonPrimary>
            </div>
          </StyledSidebarWrapper>
        );
      case "/lean":
        return (
          <StyledSidebarWrapper>
            <div>
              <Trans i18nKey="lean-guidance">
                <p></p>
                <p></p>
              </Trans>
            </div>
          </StyledSidebarWrapper>
        );
      case "/quickscan":
        switch (quickscan.step) {
          case 1:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-1">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 2:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-2">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 3:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-3">
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 4:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-4">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 5:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-5">
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 6:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-6">
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 7:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-7">
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 8:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-8">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 9:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-9">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 10:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-10">
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 11:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-11">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 12:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-12">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 13:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-13">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );

          case 14:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-14">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 15:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-15">
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 16:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-16">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 17:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-17">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 18:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-18">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 19:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-19">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 20:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-20">
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 21:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-21">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 22:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-22">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 23:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-23">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 24:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-24">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          case 25:
            return (
              <StyledSidebarWrapper>
                <div>
                  <Trans i18nKey="quickscan-help-25">
                    <p></p>
                  </Trans>
                </div>
              </StyledSidebarWrapper>
            );
          default:
            return (
              <StyledSidebarWrapper>
                <div>
                  {/* <p>{t("no data")}</p> */}
                  <img src={logoBlack} alt="yvoo" />
                </div>
              </StyledSidebarWrapper>
            );
        }
      default:
        return (
          <StyledSidebarWrapper>
            {/* {t("no data")} */}
            <img src={logoBlack} alt="yvoo" />
          </StyledSidebarWrapper>
        );
    }
  };

  const renderTopBarContent = () => {
    // const result = await timer();

    return topBarContent ? (
      <Container style={{ height: "100%" }}>
        <Grid container style={{ height: "100%" }} alignItems="center">
          <Grid item xs={12} lg={5}>
            <StyledRatingWrapper>
              <div className={classesRating.root}>
                <CircularProgress
                  variant="determinate"
                  className={classesRating.bottom}
                  size={109}
                  thickness={1}
                  {...props}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  className={clsx(classesRating.top, {
                    [classesRating.red]:
                      selfAssessment.selfAssessment.overall_completenes < 20,
                    [classesRating.orange]:
                      selfAssessment.selfAssessment.overall_completenes >= 20 &&
                      selfAssessment.selfAssessment.overall_completenes < 40,
                    [classesRating.yellow]:
                      selfAssessment.selfAssessment.overall_completenes >= 40 &&
                      selfAssessment.selfAssessment.overall_completenes < 60,
                    [classesRating.blue]:
                      selfAssessment.selfAssessment.overall_completenes >= 60 &&
                      selfAssessment.selfAssessment.overall_completenes < 80,
                    [classesRating.green]:
                      selfAssessment.selfAssessment.overall_completenes >= 80,
                  })}
                  classes={{
                    circle: classesRating.circle,
                  }}
                  size={117}
                  thickness={4}
                  value={
                    selfAssessment.selfAssessment &&
                    selfAssessment.selfAssessment.overall_completenes
                      ? selfAssessment.selfAssessment.overall_completenes
                      : 0
                  }
                  {...props}
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <h3>
                    {selfAssessment.selfAssessment &&
                    selfAssessment.selfAssessment.overall_completenes
                      ? selfAssessment.selfAssessment.overall_completenes
                      : 0}
                    <span> %</span>
                  </h3>
                </Box>
              </div>
              <StyledRatingInfoWrapper>
                <h4>
                  {t("your chance to be found by leads is currently")}{" "}
                  {selfAssessment.selfAssessment &&
                  selfAssessment.selfAssessment.overall_completenes
                    ? selfAssessment.selfAssessment.overall_completenes
                    : 0}
                  %
                </h4>
                {/* <p>{t("try to reach the green level")}</p> */}
              </StyledRatingInfoWrapper>
            </StyledRatingWrapper>
          </Grid>
          <Grid item xs={12} lg={5}>
            <StyledRatingWrapper>
              <div className={classesRating.root}>
                <CircularProgress
                  variant="determinate"
                  className={classesRating.bottom}
                  size={109}
                  thickness={1}
                  {...props}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  className={clsx(classesRating.top, {
                    [classesRating.red]:
                      selfAssessment.selfAssessment.overall_company_level < 20,
                    [classesRating.orange]:
                      selfAssessment.selfAssessment.overall_company_level >=
                        20 &&
                      selfAssessment.selfAssessment.overall_company_level < 40,
                    [classesRating.yellow]:
                      selfAssessment.selfAssessment.overall_company_level >=
                        40 &&
                      selfAssessment.selfAssessment.overall_company_level < 60,
                    [classesRating.blue]:
                      selfAssessment.selfAssessment.overall_company_level >=
                        60 &&
                      selfAssessment.selfAssessment.overall_company_level < 80,
                    [classesRating.green]:
                      selfAssessment.selfAssessment.overall_company_level >= 80,
                  })}
                  classes={{
                    circle: classesRating.circle,
                  }}
                  size={117}
                  thickness={4}
                  value={
                    selfAssessment.selfAssessment &&
                    selfAssessment.selfAssessment.overall_company_level
                      ? selfAssessment.selfAssessment.overall_company_level
                      : 0
                  }
                  {...props}
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <h3>
                    {selfAssessment.selfAssessment &&
                    selfAssessment.selfAssessment.overall_company_level
                      ? selfAssessment.selfAssessment.overall_company_level
                      : 0}
                  </h3>
                </Box>
              </div>
              <StyledRatingInfoWrapper>
                <h4>
                  {t(
                    "your chances to be qualified by international industrial leads"
                  )}
                </h4>
                {/* <p>{t("what would be the chances to be qualified")}</p> */}
              </StyledRatingInfoWrapper>
            </StyledRatingWrapper>
          </Grid>
        </Grid>
      </Container>
    ) : null;
  };

  useEffect(() => {
    if (openTopBar) {
      const timer = setTimeout(() => {
        setTopBarContent(true);
      }, 225);

      return () => clearTimeout(timer);
    } else {
      setTopBarContent(false);
    }
  }, [openTopBar]);

  return (
    <Fragment>
      {!auth.user ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <div style={{ display: "flex", minHeight: "100vh" }}>
            <StyledAppbar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <StyledToolbar
                className={clsx({ [classes.toolbarClosed]: !open })}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <img src={logo} alt="yvoo logo" />
                <div className={classes.grow} />
                <StyledToolbarWrapper>
                  <StyledLink to="/profile">
                    <StyledToolbarButton>
                      <img src={profileIcon} alt="profile icon" />
                      <span>{auth.user.attributes.name}</span>
                    </StyledToolbarButton>
                  </StyledLink>
                  <StyledToolbarButton
                    aria-controls="language-menu"
                    aria-haspopup="true"
                    onClick={handleClickLanguage}
                  >
                    <span>{language.language.toUpperCase()}</span>
                  </StyledToolbarButton>
                  <Menu
                    id="language-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseLanguage}
                  >
                    {language.languages.map((lang) => {
                      if (lang !== language.language) {
                        return (
                          <MenuItem onClick={() => handlePickLanguage(lang)}>
                            {lang.toUpperCase()}
                          </MenuItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Menu>
                  <StyledToolbarButton onClick={logout}>
                    <img src={logoutIcon} alt="logout icon" />
                    <span>{t("logout")}</span>
                  </StyledToolbarButton>
                </StyledToolbarWrapper>
              </StyledToolbar>
            </StyledAppbar>

            <StyledDrawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  <MenuIcon />
                </IconButton>
              </div>
              <StyledList>
                <ListItemLink
                  to="/"
                  primary={t("dashboard")}
                  pathname={pathname}
                />
                <span className={classes.dropdownMenu}>
                  <ListItemLink
                    to="/self-assessment"
                    primary={t("yvoo 4 steps method your forecast of success")}
                    pathname={pathname}
                  />
                  {openMultilevel ? (
                    <IconExpandLess
                      onClick={handleMultilevel}
                      className={classes.dropdownIcon}
                    />
                  ) : (
                    <IconExpandMore
                      onClick={handleMultilevel}
                      className={classes.dropdownIcon}
                    />
                  )}
                </span>
                <Collapse in={openMultilevel}>
                  <StyledNestedList>
                    <ListItemLink
                      to="/company-details"
                      primary={t("company information")}
                      pathname={pathname}
                    />
                    <ListItemLink
                      to="/lean"
                      primary={t("lean manufacturing score")}
                      pathname={pathname}
                    />
                    <ListItemLink
                      to="/quickscan"
                      primary={t("self-assessment")}
                      pathname={pathname}
                    />
                    <ListItemLink
                      to="/supplier-products"
                      primary={t("products")}
                      pathname={pathname}
                    />
                  </StyledNestedList>
                </Collapse>
                {/* <ListItemLink
                  to="/self-assessment"
                  primary="Self assessment"
                  pathname={pathname}
                />
                <ListItemLink
                  to="/company-details"
                  primary="Company details"
                  pathname={pathname}
                />
                <ListItemLink to="/lean" primary="Lean" pathname={pathname} />

                <ListItemLink
                  to="/quickscan"
                  primary="Self quickscan"
                  pathname={pathname}
                />
                <ListItemLink
                  to="/supplier-products"
                  primary="Supplier products"
                  pathname={pathname}
                /> */}
                <ListItemLink
                  to="/report"
                  primary={t("yvoo 4 steps method report")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/faq"
                  primary={t("faq")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/help"
                  primary={t("help")}
                  pathname={pathname}
                  id="borderless"
                />
              </StyledList>
            </StyledDrawer>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open,
              })}
            >
              {selfAssessment.selfAssessment || !selfAssessment.loading ? (
                <Fragment>
                  <StyledTopBar openTopBar={openTopBar} matches={matches}>
                    {renderTopBarContent()}
                  </StyledTopBar>
                  <StyledTopBarFooter
                    openTopBar={openTopBar}
                    onClick={toggleTopBar}
                  >
                    <img src={openDrawerIconWhite} alt="open drawer icon" />
                  </StyledTopBarFooter>
                </Fragment>
              ) : null}
              <StyledMainWrapper pathname={pathname}>
                <Switch>
                  {/* <Route path="/" exact component={Home} props={auth} /> */}
                  <ProtectedRoute path="/" exact auth={auth} gdpr={gdpr}>
                    <Dashboard />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/accept-terms"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <AcceptTerms />
                  </ProtectedRoute>
                  <ProtectedRoute path="/profile" exact auth={auth} gdpr={gdpr}>
                    <UserProfile />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/self-assessment"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <SelfAssessment />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/company-details"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <CompanyDetails />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/company-profile"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <CompanyProfile />
                  </ProtectedRoute>
                  <ProtectedRoute path="/lean" exact auth={auth} gdpr={gdpr}>
                    <Lean />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/quickscan"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <Quickscan />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/supplier-products"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <SupplierProducts />
                  </ProtectedRoute>
                  <ProtectedRoute path="/report" exact auth={auth} gdpr={gdpr}>
                    <Report />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/reports/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <SpecificReport />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/admin-quickscan-report/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <AdminQuickscanReport />
                  </ProtectedRoute>
                  <ProtectedRoute path="/rfq/:id" exact auth={auth} gdpr={gdpr}>
                    <RFI />
                  </ProtectedRoute>
                  <ProtectedRoute path="/faq" exact auth={auth} gdpr={gdpr}>
                    <FAQ />
                  </ProtectedRoute>
                  <ProtectedRoute path="/help" exact auth={auth} gdpr={gdpr}>
                    <Help />
                  </ProtectedRoute>
                  <Route path="/terms-and-conditions" exact>
                    <TermsAndConditions />
                  </Route>
                  <Route path="/privacy" exact>
                    <Privacy />
                  </Route>
                  <Route path="/impressum" exact>
                    <Impressum />
                  </Route>
                  <Route path="/login" exact>
                    <Login />
                  </Route>
                </Switch>
              </StyledMainWrapper>
              <StyledHelpBorder open={openSideBar} id="helpBorder">
                <StyledHelpButtons>
                  <StyledTopButton
                    onClick={() => handleSidebarContent("info")}
                    className={sidebarContent === "info" ? "active" : null}
                    disableRipple={true}
                    disableFocusRipple={true}
                  >
                    {sidebarContent === "info" ? (
                      <img src={infoIconWhite} alt="info icon white" />
                    ) : (
                      <img src={infoIcon} alt="info icon" />
                    )}
                  </StyledTopButton>
                  <StyledBottomButton
                    onClick={() => handleSidebarContent("chat")}
                    className={sidebarContent === "chat" ? "active" : null}
                    disableRipple={true}
                    disableFocusRipple={true}
                  >
                    {sidebarContent === "chat" ? (
                      <img src={chatIconWhite} alt="chat icon white" />
                    ) : (
                      <img src={chatIcon} alt="chat icon" />
                    )}
                  </StyledBottomButton>
                </StyledHelpButtons>
              </StyledHelpBorder>
            </main>
          </div>
          <StyledHelpDrawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={openSideBar}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {sidebarContent === "info" ? (
              renderSidebarContent(pathname)
            ) : (
              <StyledSidebarWrapper>
                {/* {t("chat placeholder")} */}
                <img src={logoBlack} alt="yvoo" />
              </StyledSidebarWrapper>
            )}
            <StyledCloseButton onClick={handleDrawerCloseHelp}>
              <img src={closeIcon} alt="close icon" />
            </StyledCloseButton>
          </StyledHelpDrawer>
        </Fragment>
      )}
    </Fragment>
  );
});

export default withRouter(AppbarSupplier);
