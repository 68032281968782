import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { Switch, Route, withRouter } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import plusSign from "assets/img/plusIcon.svg";
import minusSign from "assets/img/minusIcon.svg";
import { useTranslation } from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import logo from "assets/img/logo.svg";
import logoBlack from "assets/img/logoBlack.svg";
import drawerButtonLeft from "assets/img/drawerButtonLeft.svg";
import drawerButtonRight from "assets/img/drawerButtonRight.svg";
import profileIcon from "assets/img/profileIcon.svg";
import logoutIcon from "assets/img/logoutIcon.svg";
import infoIcon from "assets/img/infoIcon.svg";
import infoIconWhite from "assets/img/infoIconWhite.svg";
import filterIcon from "assets/img/filterIcon.svg";
import filterIconWhite from "assets/img/filterIconWhite.svg";
import chatIcon from "assets/img/chatIcon.svg";
import chatIconWhite from "assets/img/chatIconWhite.svg";
import closeIcon from "assets/img/closeIcon.svg";
import openDrawerIconWhite from "assets/img/openDrawerIconWhite.svg";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { view } from "@risingstack/react-easy-state";
import { auth, gdpr, company, searchFilters, language } from "stores/BaseStore";

import ProtectedRoute from "../protected/ProtectedRoute";

import Loader from "../shared/Loader";
import Login from "../shared/Login";
import AcceptTerms from "../gdpr/AcceptTerms";
import Dashboard from "./routes/Dashboard";
import TermsAndConditions from "../gdpr/TermsAndConditions";
import Privacy from "../gdpr/Privacy";
import Impressum from "../gdpr/Impressum";
import Search from "./routes/Search";
import SearchResults from "./routes/SearchResults";
import CustomSearch from "./routes/CustomSearch";
import Company from "components/shared/Company";
import SpecificReport from "components/shared/SpecificReport";
import RFI from "./routes/RFI";
import CustomSearchResults from "./routes/CustomSearchResults";
import UserProfile from "components/shared/UserProfile";
import Help from "components/shared/Help";
import FAQ from "components/shared/FAQ";
import SearchInputWithFilter from "./search/SearchInputWithFilter";
import MyQuickscans from "./routes/MyQuickscans";
import MySuppliers from "./routes/MySuppliers";
import AdminQuickscanReport from "components/buyer/routes/AdminQuickscanReport";

const drawerWidth = 260;

const StyledSpacer = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const StyledAppbar = styled(AppBar)`
  background-color: #000000;
  min-height: 75px;
  box-shadow: none;
  border-bottom: 1px solid #383838;
`;

const StyledToolbar = styled(Toolbar)`
  height: 100%;
  min-height: 75px;

  .MuiIconButton-root {
    background: url(${drawerButtonRight});
    width: 78px;
    height: 75px;
    border-radius: 0;
  }

  .MuiIconButton-edgeStart {
    margin-left: 0;
  }
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    color: #000000;
    background-color: #ffffff;
    text-transform: uppercase;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    // border-right: 1px solid #dddddd;
    border: none;
  }

  .MuiIconButton-root {
    background: url(${drawerButtonLeft});
    width: 78px;
    height: 75px;
    border-radius: 0;
    color: #ffffff;
  }
`;

const StyledList = styled(List)`
  padding-bottom: 43px;
  padding-top: 43px;
  border-right: 1px solid #dddddd;
  height: 100%;

  a,
  .MuiButtonBase-root {
    padding: 0 56px;

    .MuiTypography-root {
      padding: 16px 0;
      font-size: 14px;
      line-height: 17px;
      font-family: "RobotoMedium", "Arial", sans-serif;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      border-bottom: 1px solid #dddddd;
    }

    #borderless {
      .MuiTypography-root {
        border-bottom: none;
      }
    }
  }

  .MuiListItem-button {
    &:hover {
      text-decoration: none;
      background-color: unset;
      color: #06d7f9;
    }
  }

  [type="active"] {
    span {
      color: #06d7f9;
    }
  }

  svg {
    position: absolute;
    right: 35px;
  }
`;

// const StyledNestedList = styled(List)`
//   border-right: 1px solid #dddddd;
//   height: 100%;

//   a,
//   .MuiButtonBase-root {
//     padding: 0 56px;

//     .MuiTypography-root {
//       padding: 16px 0;
//       font-size: 14px;
//       line-height: 17px;
//       font-family: "RobotoMedium", "Arial", sans-serif;
//       display: flex;
//       align-items: center;
//       text-transform: uppercase;
//       border-bottom: 1px solid #dddddd;
//     }

//     #borderless {
//       .MuiTypography-root {
//         border-bottom: none;
//       }
//     }
//   }

//   .MuiListItem-button {
//     &:hover {
//       text-decoration: none;
//       background-color: unset;
//       color: #06d7f9;
//     }
//   }

//   [type="active"] {
//     span {
//       color: #06d7f9;
//     }
//   }
// `;

const StyledToolbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledToolbarButton = styled.div`
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 12px;
  }

  span {
    color: #42eae5;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledHelpDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    color: #000000;
    background-color: #ffffff;
    text-transform: uppercase;
    // height: 1032px;
    z-index: 99999;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    // border-right: 1px solid #dddddd;
    border: none;
  }

  .MuiIconButton-root {
    background: url(${drawerButtonLeft});
    width: 78px;
    height: 75px;
    border-radius: 0;
    color: #ffffff;
  }
`;

const StyledHelpBorder = styled(Box)`
  position: fixed;
  top: 0;
  right: ${(props) => (props.open ? `${drawerWidth}px` : 0)};
  height: 100%;
  width: 18px;
  background: linear-gradient(13.55deg, #00c9e9 0.09%, #00e8a6 80.26%);
  z-index: 9999;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  display: flex;
  align-items: center;
  z-index: 99999;
`;

const StyledHelpButtons = styled(Box)`
  width: 52px;
  border: 3px solid #01dcc1;
  border-radius: 13px;
  background-color: #ffffff;
  position: absolute;
  right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 18px;
    width: 26px;
  }
`;

const StyledTopButton = styled(IconButton)`
  width: 100%;
  height: 100%;
  border-radius: 13px 13px 0 0;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #ffffff;
    opacity: 0.8;
  }

  &.active {
    border-radius: 6px 6px 0 0;
    background-color: #01dcc1;

    &:hover {
      background-color: #01dcc1;
    }
  }
`;

const StyledMiddleButton = styled(IconButton)`
  width: 100%;
  height: 100%;
  border-radius: 0 0 0 0;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #ffffff;
    opacity: 0.8;
  }

  &.active {
    border-radius: 0 0 0 0;
    background-color: #01dcc1;

    &:hover {
      background-color: #01dcc1;
    }
  }
`;

const StyledBottomButton = styled(IconButton)`
  width: 100%;
  height: 100%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }

  &.active {
    border-radius: 0 0 6px 6px;
    background-color: #01dcc1;

    &:hover {
      background: #01dcc1;
    }
  }
`;

const StyledCloseButton = styled(Box)`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledSidebarWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  h3 {
    font-family: "NeueMachinaRegular", "Arial", sans-serif;
    font-size: 21px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 30px;
  }

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
    margin-bottom: 40px;
    text-transform: none;
  }
`;

const StyledFilterWrapper = styled(Box)`
  padding: 100px 30px;

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordion-root::before {
    display: none;
  }

  .MuiIconButton-root {
    background: unset;
    height: unset;
    width: unset;
    color: unset;
  }

  .MuiCheckbox-root {
    color: #c4c4c4;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #06d7f9;
  }

  .MuiFormControlLabel-label {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    color: #000000;
  }

  h3 {
    font-family: "NeueMachinaRegular", "Arial", sans-serif;
    font-size: 21px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 30px;
  }
`;

const StyledButtonPrimary = styled.button`
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #06d7f9;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #06d7f9;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 8px;
  }
`;

const StyledMainWrapper = styled(Box)`
  padding: ${(props) => (props.pathname === "/" ? "24px 0" : "24px")};
  height: 100%;
  background-color: ${(props) =>
    props.pathname === "/search" ? "#000000" : "transparent"};
`;

const StyledTopBar = styled(Box)`
  width: 100%;
  background: black;
  // height: ${(props) => (props.openTopBar ? "250px" : 0)};
  min-height: ${(props) =>
    props.openTopBar && props.matches
      ? "200px"
      : props.openTopBar && !props.matches
      ? "340px"
      : 0};
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding-top: ${(props) => (props.openTopBar ? "35px" : 0)};
`;

const StyledTopBarFooter = styled(Box)`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid #474747;
  cursor: pointer;
  background-color: #474747;

  img {
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: ${(props) =>
      props.openTopBar ? "rotate(0deg)" : "rotate(180deg)"};
  }
`;

const StyledRatingWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 35px;

  h3 {
    font-family: "NeueMachinaUltraBold";
    font-size: 28px;
    line-height: 17px;
    color: #ffffff;

    span {
      font-size: 14px;
    }
  }
`;

const StyledRatingInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h4 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    color: #ffffff;
    margin: 0;
    // margin-bottom: 24px;
  }

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: #c7c7c7;
    margin: 0;
  }
`;

const StyledAccordion = styled(Accordion)`
  border-bottom: 1px solid #c4c4c4;
  border-radius: 0 !important;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;

  h2 {
    font-family: "NeueMachinaRegular";
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
  }
`;
const StyledAccrdionDetails = styled(AccordionDetails)`
  padding: 0;
  margin: 0;
  margin-bottom: 35px;

  p {
    margin: 0;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(0, 1),
    padding: 0,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    minHeight: "75px !important",
  },
  toolbarClosed: {
    paddingLeft: 0,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    padding: 0,
    marginTop: "75px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const useStylesRating = makeStyles((props) => ({
  root: {
    position: "relative",
    marginRight: "26px",
  },
  bottom: {
    color: "#444444",
  },
  top: {
    animationDuration: "550ms",
    position: "absolute",
    left: -4,
    top: -4,
  },
  red: { color: "#FF490F" },
  orange: { color: "#FF7B51" },
  yellow: { color: "#FFC527" },
  blue: { color: "#06D7F9" },
  green: { color: "#01E7AA" },
  circle: {
    // strokeLinecap: "round",
  },
}));

function ListItemLink(props) {
  const { primary, to, id, pathname } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link
          to={to}
          ref={ref}
          {...itemProps}
          type={to === pathname ? "active" : null}
        />
      )),
    [to, pathname]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        <ListItemText primary={primary} id={id} />
      </ListItem>
    </li>
  );
}

const logout = async () => {
  await auth.logout();
  company.company = null;
  // history.push("/login");
};

const AppbarBuyer = view((props) => {
  const classes = useStyles();
  const classesRating = useStylesRating();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(true);
  const [openSideBar, setOpenSidebar] = useState(false);
  const [openTopBar, setOpenTopBar] = useState(false);
  const { pathname } = props.location;
  const [sidebarContent, setSidebarContent] = useState(null);
  const [topBarContent, setTopBarContent] = useState(false);
  const [filterTurnoverExpanded, setFilterTurnoverExpanded] = useState(false);
  const [filterISOExpanded, setFilterISOExpanded] = useState(false);
  const [filterQuickscanExpanded, setFilterQuickscanExpanded] = useState(false);
  const [filterQuickscanRatingExpanded, setFilterQuickscanRatingExpanded] =
    useState(false);
  const [filters, setFilters] = useState({
    turnover5: false,
    turnover20: false,
    turnover100: false,
    turnover500: false,
    turnover1B: false,
    turnover10B: false,
    iso9001_2015: false,
    iso14001_2015: false,
    ohsas18001: false,
    quickscan: false,
    quickscan_rating: false,
  });
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickLanguage = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorEl(null);
  };

  const handlePickLanguage = (lang) => {
    language.setLanguage(lang);
    setAnchorEl(null);
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
    searchFilters.filters = {
      ...filters,
      [event.target.name]: event.target.checked,
    };
  };

  const {
    turnover5,
    turnover20,
    turnover100,
    turnover500,
    turnover1B,
    turnover10B,
    iso9001_2015,
    iso14001_2015,
    ohsas18001,
    quickscan,
    quickscan_rating,
  } = filters;
  // const [openMultilevel, setOpenMultilevel] = useState(false);

  // autoEffect(() => {
  //   if (!selfAssessment.selfAssessment) {
  //     selfAssessment.getSelfAssessment();
  //   }
  // }, [selfAssessment.selfAssessment]);

  useEffect(() => {
    if (pathname === "/") {
      setOpenTopBar(true);
    } else {
      setOpenTopBar(false);
    }

    // setFilters({
    //   turnover5: false,
    //   turnover20: false,
    //   turnover100: false,
    //   turnover500: false,
    //   turnover1B: false,
    //   turnover10B: false,
    //   iso9001_2015: false,
    //   iso14001_2015: false,
    //   ohsas18001: false,
    //   quickscan: false,
    //   quickscan_rating: false,
    // });
    setOpenSidebar(false);
    setFilters({
      turnover5: false,
      turnover20: false,
      turnover100: false,
      turnover500: false,
      turnover1B: false,
      turnover10B: false,
      iso9001_2015: false,
      iso14001_2015: false,
      ohsas18001: false,
      quickscan: false,
      quickscan_rating: false,
    });
    searchFilters.filters = null;
  }, [pathname]);

  useEffect(() => {
    if (matches) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }

    if (pathname === "/search") {
      handleDrawerClose();
    }

    if (
      pathname.includes("/search-results") ||
      pathname.includes("/custom-search-results")
    ) {
      handleDrawerClose();
      setOpenTopBar(true);
      setSidebarContent("filter");
      setOpenSidebar(true);
    }

    if (pathname.includes("/custom-search")) {
      handleDrawerClose();
      setOpenTopBar(true);
      // setSidebarContent("info");
      // setOpenSidebar(true);
    }
  }, [matches, pathname]);

  const handleTurnoverAccordionChange = (panel) => (event, isExpanded) => {
    setFilterTurnoverExpanded(isExpanded ? panel : false);
  };

  const handleISOAccordionChange = (panel) => (event, isExpanded) => {
    setFilterISOExpanded(isExpanded ? panel : false);
  };

  const handleQuickscanAccordionChange = (panel) => (event, isExpanded) => {
    setFilterQuickscanExpanded(isExpanded ? panel : false);
  };

  const handleQuickscanRatingAccordionChange =
    (panel) => (event, isExpanded) => {
      setFilterQuickscanRatingExpanded(isExpanded ? panel : false);
    };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleTopBar = () => {
    setOpenTopBar(!openTopBar);
  };

  // const handleDrawerOpenHelp = () => {
  //   setOpenSidebar(true);
  // };

  const handleDrawerCloseHelp = () => {
    setOpenSidebar(false);
    setSidebarContent(null);
  };

  // const handleMultilevel = () => {
  //   setOpenMultilevel(!openMultilevel);
  // };

  const handleSidebarContent = (content) => {
    setSidebarContent(content);
    setOpenSidebar(true);
  };

  const renderSidebarContent = (path) => {
    switch (path) {
      case "/self-assessment":
        return (
          <StyledSidebarWrapper>
            <div>
              <h3>tutorial</h3>
              <p>
                If you want to get information how to use YVOO please have a
                look at our Tutorial. The tutorial explains the benefits of the
                platform and gives you an introduction how to run the self
                assessment.
              </p>
              <StyledButtonPrimary>
                <PlayCircleFilledIcon />
                video
              </StyledButtonPrimary>
            </div>
          </StyledSidebarWrapper>
        );
      default:
        return (
          <StyledSidebarWrapper>
            {/* {t("no data")} */}
            <img src={logoBlack} alt="yvoo" />
          </StyledSidebarWrapper>
        );
    }
  };

  const renderFilterContent = () => {
    return (
      <StyledFilterWrapper>
        <h3>{t("filter")}</h3>
        <StyledAccordion
          expanded={filterTurnoverExpanded}
          onChange={handleTurnoverAccordionChange("turnover")}
        >
          <StyledAccordionSummary
            expandIcon={
              filterTurnoverExpanded ? (
                <img src={minusSign} alt="expand" />
              ) : (
                <img src={plusSign} alt="expand" />
              )
            }
            aria-controls="turnover-content"
            id="turnover-header"
          >
            <h2>{t("turnover")}</h2>
          </StyledAccordionSummary>
          <StyledAccrdionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover5}
                      onChange={handleFilterChange}
                      name="turnover5"
                    />
                  }
                  label={t("<5M")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover20}
                      onChange={handleFilterChange}
                      name="turnover20"
                    />
                  }
                  label={t("<20M")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover100}
                      onChange={handleFilterChange}
                      name="turnover100"
                    />
                  }
                  label={t("<100M")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover500}
                      onChange={handleFilterChange}
                      name="turnover500"
                    />
                  }
                  label={t("<500M")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover1B}
                      onChange={handleFilterChange}
                      name="turnover1B"
                    />
                  }
                  label={t("<1B")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnover10B}
                      onChange={handleFilterChange}
                      name="turnover10B"
                    />
                  }
                  label={t("<10B")}
                />
              </FormGroup>
            </FormControl>
          </StyledAccrdionDetails>
        </StyledAccordion>
        <StyledAccordion
          expanded={filterISOExpanded}
          onChange={handleISOAccordionChange("iso")}
        >
          <StyledAccordionSummary
            expandIcon={
              filterISOExpanded ? (
                <img src={minusSign} alt="expand" disableRipple />
              ) : (
                <img src={plusSign} alt="expand" />
              )
            }
            aria-controls="iso-content"
            id="iso-header"
          >
            <h2>{t("certificate")}</h2>
          </StyledAccordionSummary>
          <StyledAccrdionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={iso9001_2015}
                      onChange={handleFilterChange}
                      name="iso9001_2015"
                    />
                  }
                  label={t("iso 9001-2015")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={iso14001_2015}
                      onChange={handleFilterChange}
                      name="iso14001_2015"
                    />
                  }
                  label={t("iso 14001-2015")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ohsas18001}
                      onChange={handleFilterChange}
                      name="ohsas18001"
                    />
                  }
                  label={t("ohsas 18001")}
                />
              </FormGroup>
            </FormControl>
          </StyledAccrdionDetails>
        </StyledAccordion>
        <StyledAccordion
          expanded={filterQuickscanExpanded}
          onChange={handleQuickscanAccordionChange("quickscan")}
        >
          <StyledAccordionSummary
            expandIcon={
              filterQuickscanExpanded ? (
                <img src={minusSign} alt="expand" />
              ) : (
                <img src={plusSign} alt="expand" />
              )
            }
            aria-controls="quickscan-content"
            id="quickscan-header"
          >
            <h2>{t("quickscan")}</h2>
          </StyledAccordionSummary>
          <StyledAccrdionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={quickscan}
                      onChange={handleFilterChange}
                      name="quickscan"
                    />
                  }
                  label={t("quickscan available")}
                />
              </FormGroup>
            </FormControl>
          </StyledAccrdionDetails>
        </StyledAccordion>
        <StyledAccordion
          expanded={filterQuickscanRatingExpanded}
          onChange={handleQuickscanRatingAccordionChange("quickscan-rating")}
        >
          <StyledAccordionSummary
            expandIcon={
              filterQuickscanRatingExpanded ? (
                <img src={minusSign} alt="expand" />
              ) : (
                <img src={plusSign} alt="expand" />
              )
            }
            aria-controls="quickscan-rating-content"
            id="quickscan-rating-header"
          >
            <h2>{t("quickscan rating")}</h2>
          </StyledAccordionSummary>
          <StyledAccrdionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={quickscan_rating}
                      onChange={handleFilterChange}
                      name="quickscan_rating"
                    />
                  }
                  label={t("quickscan score > 3")}
                />
              </FormGroup>
            </FormControl>
          </StyledAccrdionDetails>
        </StyledAccordion>
      </StyledFilterWrapper>
    );
  };

  const renderTopBarContent = () => {
    // const result = await timer();

    const userAttributes = {
      name: auth.user.attributes.name,
      profile_photo: auth.user.attributes["custom:profile_photo"],
      mobile_phone: auth.user.attributes["custom:mobile_phone"],
      country: auth.user.attributes["custom:country"],
      address: auth.user.attributes.address,
      city: auth.user.attributes["custom:city"],
      zip_code: auth.user.attributes["custom:zip"],
      job_function: auth.user.attributes["custom:job_function"],
      job_level: auth.user.attributes["custom:job_level"],
      discipline: auth.user.attributes["custom:discipline"],
      industry: auth.user.attributes["custom:industry"],
      company_name: auth.user.attributes["custom:company_name"],
    };

    const attributeCount = Object.values(userAttributes).filter((value) => {
      return value;
    }).length;

    const user = {
      overall_completenes: Math.round((attributeCount / 12) * 100),
    };

    const isSearch =
      pathname.includes("/search-results") ||
      pathname.includes("/custom-search-results") ||
      pathname.includes("/custom-search");

    if (isSearch) {
      return topBarContent ? (
        <Container style={{ height: "100%" }}>
          <Grid container style={{ height: "100%" }} alignItems="center">
            <Grid item xs={12}>
              <SearchInputWithFilter />
            </Grid>
          </Grid>
        </Container>
      ) : null;
    } else {
      return topBarContent ? (
        <Container style={{ height: "100%" }}>
          <Grid container style={{ height: "100%" }} alignItems="center">
            <Grid item xs={12} lg={5}>
              <StyledRatingWrapper>
                <div className={classesRating.root}>
                  <CircularProgress
                    variant="determinate"
                    className={classesRating.bottom}
                    size={109}
                    thickness={1}
                    {...props}
                    value={100}
                  />
                  <CircularProgress
                    variant="static"
                    className={clsx(classesRating.top, {
                      [classesRating.red]: user.overall_completenes < 20,
                      [classesRating.orange]:
                        user.overall_completenes >= 20 &&
                        user.overall_completenes < 40,
                      [classesRating.yellow]:
                        user.overall_completenes >= 40 &&
                        user.overall_completenes < 60,
                      [classesRating.blue]:
                        user.overall_completenes >= 60 &&
                        user.overall_completenes < 80,
                      [classesRating.green]: user.overall_completenes >= 80,
                    })}
                    classes={{
                      circle: classesRating.circle,
                    }}
                    size={117}
                    thickness={4}
                    value={user ? user.overall_completenes : 0}
                    {...props}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <h3>
                      {user ? user.overall_completenes : 0}
                      <span> %</span>
                    </h3>
                  </Box>
                </div>
                <StyledRatingInfoWrapper>
                  <h4>{t("completed user profile")}</h4>
                  {/* <p>
                    {t(
                      "what would be the chances to be qualified by the leading customers in the industry? The rating depends on completeness and quality of information provided by you during quick-scan"
                    )}
                    .
                  </p> */}
                </StyledRatingInfoWrapper>
              </StyledRatingWrapper>
            </Grid>
          </Grid>
        </Container>
      ) : null;
    }
  };

  useEffect(() => {
    if (openTopBar) {
      const timer = setTimeout(() => {
        setTopBarContent(true);
      }, 225);

      return () => clearTimeout(timer);
    } else {
      setTopBarContent(false);
    }
  }, [openTopBar]);

  // autoEffect(() => {
  //   if (!countries.countries) {
  //     countries.getCountries();
  //   }
  // });

  return (
    <Fragment>
      {!auth.user ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <div style={{ display: "flex", minHeight: "100vh" }}>
            <StyledAppbar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <StyledToolbar
                className={clsx({ [classes.toolbarClosed]: !open })}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <img src={logo} alt="yvoo logo" />
                <div className={classes.grow} />
                <StyledToolbarWrapper>
                  <StyledLink to="/profile">
                    <StyledToolbarButton>
                      <img src={profileIcon} alt="profile icon" />
                      <span>{auth.user.attributes.name}</span>
                    </StyledToolbarButton>
                  </StyledLink>
                  <StyledToolbarButton
                    aria-controls="language-menu"
                    aria-haspopup="true"
                    onClick={handleClickLanguage}
                  >
                    <span>{language.language.toUpperCase()}</span>
                  </StyledToolbarButton>
                  <Menu
                    id="language-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseLanguage}
                  >
                    {language.languages.map((lang) => {
                      if (lang !== language.language) {
                        return (
                          <MenuItem onClick={() => handlePickLanguage(lang)}>
                            {lang.toUpperCase()}
                          </MenuItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Menu>
                  <StyledToolbarButton onClick={logout}>
                    <img src={logoutIcon} alt="logout icon" />
                    <span>{t("logout")}</span>
                  </StyledToolbarButton>
                </StyledToolbarWrapper>
              </StyledToolbar>
            </StyledAppbar>

            <StyledDrawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  <MenuIcon />
                </IconButton>
              </div>
              <StyledList>
                <ListItemLink
                  to="/"
                  primary={t("dashboard")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/search"
                  primary={t("search")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/my-suppliers"
                  primary={t("my suppliers")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/my-quickscans"
                  primary={t("my quick-scans")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/faq"
                  primary={t("faq")}
                  pathname={pathname}
                />
                <ListItemLink
                  to="/help"
                  primary={t("help")}
                  pathname={pathname}
                />
              </StyledList>
            </StyledDrawer>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open,
              })}
            >
              {auth.user ? (
                <Fragment>
                  <StyledTopBar openTopBar={openTopBar} matches={matches}>
                    {renderTopBarContent()}
                  </StyledTopBar>
                  <StyledTopBarFooter
                    openTopBar={openTopBar}
                    onClick={toggleTopBar}
                  >
                    <img src={openDrawerIconWhite} alt="open drawer icon" />
                  </StyledTopBarFooter>
                </Fragment>
              ) : null}
              <StyledMainWrapper pathname={pathname}>
                <Switch>
                  {/* <Route path="/" exact component={Home} props={auth} /> */}
                  <ProtectedRoute path="/" exact auth={auth} gdpr={gdpr}>
                    <Dashboard />
                  </ProtectedRoute>
                  <ProtectedRoute path="/profile" exact auth={auth} gdpr={gdpr}>
                    <UserProfile />
                  </ProtectedRoute>
                  <ProtectedRoute path="/search" exact auth={auth} gdpr={gdpr}>
                    <Search />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/search-results/:input/region/:region/page/:page"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <SearchResults />
                  </ProtectedRoute>
                  <Route path="/custom-search/:input" exact>
                    <CustomSearch />
                  </Route>
                  <ProtectedRoute
                    path="/my-suppliers"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <MySuppliers />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/my-quickscans"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <MyQuickscans />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/companies/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <Company />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/reports/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <SpecificReport />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/admin-quickscan-report/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <AdminQuickscanReport />
                  </ProtectedRoute>
                  <ProtectedRoute path="/rfq/:id" exact auth={auth} gdpr={gdpr}>
                    <RFI />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/custom-search-results/:id"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <CustomSearchResults />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="/accept-terms"
                    exact
                    auth={auth}
                    gdpr={gdpr}
                  >
                    <AcceptTerms />
                  </ProtectedRoute>
                  <ProtectedRoute path="/help" exact auth={auth} gdpr={gdpr}>
                    <Help />
                  </ProtectedRoute>
                  <ProtectedRoute path="/faq" exact auth={auth} gdpr={gdpr}>
                    <FAQ />
                  </ProtectedRoute>

                  <Route path="/terms-and-conditions" exact>
                    <TermsAndConditions />
                  </Route>
                  <Route path="/privacy" exact>
                    <Privacy />
                  </Route>
                  <Route path="/impressum" exact>
                    <Impressum />
                  </Route>
                  <Route path="/login" exact>
                    <Login />
                  </Route>
                </Switch>
              </StyledMainWrapper>
              <StyledHelpBorder open={openSideBar} id="helpBorder">
                {pathname.includes("/search-results") ||
                pathname.includes("/custom-search-results") ? (
                  <StyledHelpButtons>
                    <StyledTopButton
                      onClick={() => handleSidebarContent("filter")}
                      className={sidebarContent === "filter" ? "active" : null}
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      {sidebarContent === "filter" ? (
                        <img src={filterIconWhite} alt="info icon white" />
                      ) : (
                        <img src={filterIcon} alt="info icon" />
                      )}
                    </StyledTopButton>
                    <StyledMiddleButton
                      onClick={() => handleSidebarContent("info")}
                      className={sidebarContent === "info" ? "active" : null}
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      {sidebarContent === "info" ? (
                        <img src={infoIconWhite} alt="info icon white" />
                      ) : (
                        <img src={infoIcon} alt="info icon" />
                      )}
                    </StyledMiddleButton>
                    <StyledBottomButton
                      onClick={() => handleSidebarContent("chat")}
                      className={sidebarContent === "chat" ? "active" : null}
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      {sidebarContent === "chat" ? (
                        <img src={chatIconWhite} alt="chat icon white" />
                      ) : (
                        <img src={chatIcon} alt="chat icon" />
                      )}
                    </StyledBottomButton>
                  </StyledHelpButtons>
                ) : (
                  <StyledHelpButtons>
                    <StyledTopButton
                      onClick={() => handleSidebarContent("info")}
                      className={sidebarContent === "info" ? "active" : null}
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      {sidebarContent === "info" ? (
                        <img src={infoIconWhite} alt="info icon white" />
                      ) : (
                        <img src={infoIcon} alt="info icon" />
                      )}
                    </StyledTopButton>
                    <StyledBottomButton
                      onClick={() => handleSidebarContent("chat")}
                      className={sidebarContent === "chat" ? "active" : null}
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      {sidebarContent === "chat" ? (
                        <img src={chatIconWhite} alt="chat icon white" />
                      ) : (
                        <img src={chatIcon} alt="chat icon" />
                      )}
                    </StyledBottomButton>
                  </StyledHelpButtons>
                )}
              </StyledHelpBorder>
            </main>
          </div>
          <StyledHelpDrawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={openSideBar}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {sidebarContent === "info" ? (
              renderSidebarContent(pathname)
            ) : sidebarContent === "chat" ? (
              <StyledSidebarWrapper>
                {/* {t("chat placeholder")} */}
                <img src={logoBlack} alt="yvoo" />
              </StyledSidebarWrapper>
            ) : sidebarContent === "filter" ? (
              renderFilterContent()
            ) : null}

            <StyledCloseButton onClick={handleDrawerCloseHelp}>
              <img src={closeIcon} alt="close icon" />
            </StyledCloseButton>
          </StyledHelpDrawer>
        </Fragment>
      )}
    </Fragment>
  );
});

export default withRouter(AppbarBuyer);
