import React, { Fragment, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import styled from "styled-components";
import { autoEffect, view } from "@risingstack/react-easy-state";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { auth, quickscan } from "../../../stores/BaseStore";
import Loader from "../../shared/Loader";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledMain = styled(Box)`
  width: 100%;
  z-index: 2;
  position: relative;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
  }
`;

const StyledMainWrapper = styled(Container)`
  width: 100%;
  position: relative;

  position: relative;
  z-index: 2;
  margin-bottom: 60px;

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 24px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 36px;

    span {
      color: #808285;

      &.separator {
        color: #cfcfcf;
      }
    }
  }
`;

const StyledTableContainer = styled(TableContainer)`
  .MuiTable-root {
    border-collapse: separate;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTableHead-root {
    background-color: #000000;

    .MuiTableCell-head {
      font-family: "RobotoRegular", "Arial", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #ffffff;
    }
  }

  .MuiTableCell-body {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    a {
      text-decoration-line: underline;
      color: #00c9e9;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const StyledSpecialCell = styled.span`
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;
  color: #00bfe9;
`;

const StyledTableCell = styled(TableCell)`
  position: relative;
`;

const StyledTableCellBorder = styled(Box)`
  position: absolute;
  right: 0;
  bottom: 0;
  height: calc(100% - 2px);
  width: 10px;
  background-color: ${(props) =>
    props.status === "open"
      ? "#FFC527"
      : props.status === "complete"
      ? "#01E7AA"
      : "#FFFFFF"};
`;

const MyQuickscans = view(() => {
  const [quickscanRows, setQuickscanRows] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    quickscan.userQuickscans = null;
    quickscan.getUserQuickscans();
  }, []);

  const createQuickscanData = (id, company_id, company_name, date, status) => {
    return { id, company_id, company_name, date, status };
  };

  autoEffect(() => {
    if (quickscan.userQuickscans) {
      setQuickscanRows(
        quickscan.userQuickscans.map((quickscan) => {
          return createQuickscanData(
            quickscan.id ? quickscan.id : null,
            quickscan.Company && quickscan.Company.id
              ? quickscan.Company.id
              : null,
            quickscan.Company && quickscan.Company.name
              ? quickscan.Company.name
              : t("no data"),
            quickscan.created_at
              ? moment(quickscan.created_at).format("DD.MM.YYYY.")
              : t("no data"),
            quickscan.status ? quickscan.status : t("no data")
          );
        })
      );
    }
  });

  return (
    <Fragment>
      {!auth.user ||
      !quickscan.userQuickscans ||
      quickscan.loading ||
      !quickscanRows ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          {/* <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={6}>
                  <h2>{auth.user.attributes.name}</h2>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro> */}
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <h3>
                    {t("quick scans ordered")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{quickscanRows.length}</span>
                  </h3>
                  <StyledTableContainer>
                    <Table aria-label="quickscans table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("company name")}</TableCell>
                          <TableCell>{t("date")}</TableCell>
                          <TableCell>{t("status")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {quickscanRows.map((row, index) => (
                          <TableRow
                            key={row.id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Link to={`/companies/${row.company_id}`}>
                                <StyledSpecialCell>
                                  {row.company_name}
                                </StyledSpecialCell>
                              </Link>
                            </TableCell>
                            <TableCell>{row.date}</TableCell>
                            <StyledTableCell>
                              {row.status}
                              <StyledTableCellBorder status={row.status} />
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
        </Fragment>
      )}
    </Fragment>
  );
});

export default MyQuickscans;
