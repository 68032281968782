import i18next from "i18next";

export default {
  passwordFormId: "mainForm",
  passwordFormField: {
    current_password: {
      name: "current_password",
      label: i18next.t("current password"),
      requiredErrorMsg: i18next.t("current password is required"),
      invalidErrorMsg: i18next.t("current password is not valid"),
    },
    new_password: {
      name: "new_password",
      label: i18next.t("new password (at least 8 characters, 1 uppercase letter and 1 number"),
      requiredErrorMsg: i18next.t("new password is required"),
      invalidErrorMsg: i18next.t("new password is not valid"),
    },
    repeat_password: {
      name: "repeat_password",
      label: i18next.t("retype new password"),
      requiredErrorMsg: i18next.t("retype new password is required"),
      invalidErrorMsg: i18next.t("retype new password is required"),
    },
  },
};
