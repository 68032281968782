import i18next from "i18next";

export default {
  formId: "mainForm",
  formField: {
    name: {
      name: "name",
      label: i18next.t("company name"),
      requiredErrorMsg: i18next.t("company name is required"),
    },
    industry: {
      name: "industry",
      label: i18next.t("industry"),
      requiredErrorMsg: i18next.t("industry is required"),
    },
    company_type: {
      name: "company_type",
      label: i18next.t("type of company"),
    },
    parent_company: {
      name: "parent_company",
      label: i18next.t("parent company"),
    },
    number_of_employees: {
      name: "number_of_employees",
      label: i18next.t("number of employees"),
      requiredErrorMsg: i18next.t("number of employees is required"),
      invalidErrorMsg: i18next.t("number of employees is not valid"),
    },
    turnover: {
      name: "turnover",
      label: i18next.t("turnover in usd"),
      requiredErrorMsg: i18next.t("turnover is required"),
      invalidErrorMsg: i18next.t("turnover is not valid"),
    },
    duns_number: {
      name: "duns_number",
      label: i18next.t("duns number"),
      requiredErrorMsg: i18next.t("duns number is required"),
      invalidErrorMsg: i18next.t("duns number is not valid"),
    },
    tax_jurisdiction_code: {
      name: "tax_jurisdiction_code",
      label: i18next.t("tax jurisdiction code"),
      requiredErrorMsg: i18next.t("tax jurisdiction code is required"),
      invalidErrorMsg: i18next.t("tax jurisdiction code is not valid"),
    },
    supplier_type: {
      name: "supplier_type",
      label: i18next.t("supplier type"),
    },
    communication_language: {
      name: "communication_language",
      label: i18next.t("communication language"),
      requiredErrorMsg: i18next.t("communication language is required"),
    },
    country_id: {
      name: "country_id",
      label: i18next.t("country"),
      requiredErrorMsg: i18next.t("country is required"),
    },
    city_id: {
      name: "city_id",
      label: i18next.t("city"),
      requiredErrorMsg: i18next.t("city is required"),
    },
    postcode: {
      name: "postcode",
      label: i18next.t("postcode"),
      requiredErrorMsg: i18next.t("postcode is required"),
      invalidErrorMsg: i18next.t("postcode is not valid"),
    },
    address: {
      name: "address",
      label: i18next.t("address"),
      requiredErrorMsg: i18next.t("address is required"),
    },
    phone: {
      name: "phone",
      label: i18next.t("phone"),
      requiredErrorMsg: i18next.t("phone is required"),
      invalidErrorMsg: i18next.t("phone is not valid"),
    },
    email: {
      name: "email",
      label: i18next.t("email"),
      requiredErrorMsg: i18next.t("email is required"),
      invalidErrorMsg: i18next.t("email is not valid"),
    },
    website: {
      name: "website",
      label: i18next.t("website"),
      requiredErrorMsg: i18next.t("website is required"),
      invalidErrorMsg: i18next.t("webiste is not valid"),
    },
    short_description: {
      name: "short_description",
      label: i18next.t("company description"),
      requiredErrorMsg: i18next.t("company description is required"),
    },
    iso9001_2015: {
      name: "iso9001_2015",
      label: i18next.t("iso 9001-2015"),
    },
    iso14001: {
      name: "iso14001",
      label: i18next.t("iso 14001"),
    },
    ohsas18001: {
      name: "ohsas18001",
      label: i18next.t("ohsas 18001"),
    },
    iso9001_2015ExpiryDate: {
      name: "iso9001_2015ExpiryDate",
      label: i18next.t("iso 9001:2015 expiry date"),
    },
    iso14001ExpiryDate: {
      name: "iso14001ExpiryDate",
      label: i18next.t("iso 14001 expiry date"),
    },
    ohsas18001ExpiryDate: {
      name: "ohsas18001ExpiryDate",
      label: i18next.t("ohsas 18001 expiry date"),
    },
    company_presentation: {
      name: "company_presentation",
      label: i18next.t("company presentation"),
    },
    reference_list: {
      name: "reference_list",
      label: i18next.t("reference list"),
    },
    logo: {
      name: "logo",
      label: i18next.t("company logo"),
    },
    vat_id: {
      name: "vat_id",
      label: i18next.t("vat-id"),
      requiredErrorMsg: i18next.t("vat-id is required"),
      invalidErrorMsg: i18next.t("vat-id is not valid"),
    },
    certificates: {
      name: "certificates",
      label: i18next.t("certificates"),
    },
    production_locations: {
      name: "production_locations",
      label: i18next.t("production locations"),
    },
    photos: {
      name: "photos",
      label: i18next.t("company photos"),
    },
  },
};
