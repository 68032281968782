import React, { Fragment } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import styled from "styled-components";
import { autoEffect, view } from "@risingstack/react-easy-state";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { company, selfAssessment } from "stores/BaseStore";
import Loader from "components/shared/Loader";

import step1 from "assets/img/step1.svg";
import step2 from "assets/img/step2.svg";
import step3 from "assets/img/step3.svg";
import step4 from "assets/img/step4.svg";
import arrowRight from "assets/img/arrowRight.svg";

const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 40px;
  }
`;

// const StyledIntroBackground = styled(Box)`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background: url(${"test"}) no-repeat center center;
//   background-size: cover;
//   z-index: 0;
// `;

// const StyledIntroGradient = styled(Box)`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   background: rgba(15, 15, 15, 0.7);
//   z-index: 1;
// `;

const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

const StyledStepsWrapper = styled(Container)`
  position: relative;
  z-index: 2;
`;

const StyledStepWrapper = styled(Box)`
  border-right: 1px solid #dddddd;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  img {
    margin-bottom: 50px;
  }

  h1 {
    font-family: "NeueMachinaRegular", "Arial", sans-serif;
    font-size: 54px;
    line-height: 52px;
    color: #06d7f9;
    margin: 0;
  }

  h3 {
    font-size: 21px;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    color: #7b7b7b;
  }

  @media (max-width: 1279px) {
    border-right: none;
  }

  @media (max-width: 959px) {
    h1 {
      margin-bottom: 24px;
    }

    h3 {
      margin-bottom: 24px;
    }
  }
`;

const StyledStepWrapperBorderless = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  img {
    margin-bottom: 50px;
  }

  h1 {
    font-family: "NeueMachinaRegular", "Arial", sans-serif;
    font-size: 54px;
    line-height: 52px;
    color: #06d7f9;
    margin: 0;
  }

  h3 {
    font-size: 21px;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    color: #7b7b7b;
  }

  @media (max-width: 959px) {
    h1 {
      margin-bottom: 24px;
    }

    h3 {
      margin-bottom: 24px;
    }
  }
`;

const StyledImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledStepInner = styled(Box)`
  width: 100%;
  padding: 0 4rem;
`;

const StyledStepFirstRow = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 959px) {
    text-align: center;
  }
`;

const StyledImage = styled.img`
  margin-bottom: 0 !important;
`;

const StyledStepSecondRow = styled(Grid)`
  margin-bottom: 40px;

  @media (max-width: 959px) {
    text-align: center;
  }
`;

const StyledStepThirdRow = styled(Grid)`
  margin-bottom: 40px;

  @media (max-width: 959px) {
    text-align: center;
  }
`;

const StyledButtonPrimary = styled.button`
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #06d7f9;
  height: 45px;
  cursor: pointer;

  color: #06d7f9;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledStepFourthRow = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 959px) {
    text-align: center;
  }

  .MuiLinearProgress-root {
    height: 5px;
  }

  .MuiLinearProgress-colorPrimary {
    background-color: #ededed;
  }

  .MuiLinearProgress-barColorPrimary {
    background: linear-gradient(90deg, #06d7f9 3.37%, #52ff00 100%);
  }
`;

const StyledEndWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;

  p {
    font-size: 12px;
    line-height: 17px;
    color: #2ecc71;
    margin: 0;
  }

  @media (max-width: 959px) {
    justify-content: center;
  }
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const SelfAssessment = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  let history = useHistory();
  const { t } = useTranslation();

  autoEffect(async () => {
    if (!company.company) {
      await company.getCompany();
    }
    
    if (!selfAssessment.selfAssessment) {
      await selfAssessment.getSelfAssessment();
    }

    if (!company.company) {
      history.push("/company-details");
    }
  });

  return (
    <Fragment>
      {!selfAssessment.selfAssessment || selfAssessment.loading ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={4}>
                  <h2>{t("4 steps for a successful rating")}</h2>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <p>
                    {t(
                      "the accuracy of the results is depending on the amount of information you provide"
                    )}
                  </p>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
            <StyledStepsWrapper fixed>
              <Grid container justify="space-between" alignItems="flex-start">
                <Grid item xs={12} lg={6}>
                  <StyledStepWrapper>
                    <StyledStepInner>
                      <StyledImageWrapper>
                        <img src={step1} alt="step1" />
                      </StyledImageWrapper>
                      <StyledStepFirstRow container>
                        <Grid item xs={12} md={2}>
                          <h1>1</h1>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <h3>{t("rfi supplier self disclosure")}</h3>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <StyledImage src={arrowRight} alt="arrow right" />
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFirstRow>
                      <StyledStepSecondRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <p>{t("SA info 1")}</p>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepSecondRow>
                      <StyledStepThirdRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <Link to="/company-details">
                            <StyledButtonPrimary>
                              {t("start")}
                            </StyledButtonPrimary>
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepThirdRow>
                      <StyledStepFourthRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <LinearProgress
                            variant="determinate"
                            value={selfAssessment.selfAssessment.score_step_one}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <p>
                              {selfAssessment.selfAssessment.score_step_one}
                            </p>
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFourthRow>
                    </StyledStepInner>
                  </StyledStepWrapper>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledStepWrapperBorderless>
                    <StyledStepInner>
                      <StyledImageWrapper>
                        <img src={step2} alt="step2" />
                      </StyledImageWrapper>
                      <StyledStepFirstRow container>
                        <Grid item xs={12} md={2}>
                          <h1>2</h1>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <h3>{t("how lean is your company?")}</h3>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <StyledImage src={arrowRight} alt="arrow right" />
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFirstRow>
                      <StyledStepSecondRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <p>{t("SA info 2")}</p>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepSecondRow>
                      <StyledStepThirdRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <Link to="/lean">
                            <StyledButtonPrimary>
                              {t("start")}
                            </StyledButtonPrimary>
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepThirdRow>
                      <StyledStepFourthRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <LinearProgress
                            variant="determinate"
                            value={selfAssessment.selfAssessment.score_step_two}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <p>
                              {selfAssessment.selfAssessment.score_step_two}
                            </p>
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFourthRow>
                    </StyledStepInner>
                  </StyledStepWrapperBorderless>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledStepWrapper>
                    <StyledStepInner>
                      <StyledImageWrapper>
                        <img src={step3} alt="step3" />
                      </StyledImageWrapper>
                      <StyledStepFirstRow container>
                        <Grid item xs={12} md={2}>
                          <h1>3</h1>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <h3>{t("the quick scan")}</h3>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <StyledImage src={arrowRight} alt="arrow right" />
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFirstRow>
                      <StyledStepSecondRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <p>{t("SA info 3")}</p>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepSecondRow>
                      <StyledStepThirdRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <Link to="/quickscan">
                            <StyledButtonPrimary>
                              {t("start")}
                            </StyledButtonPrimary>
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepThirdRow>
                      <StyledStepFourthRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <LinearProgress
                            variant="determinate"
                            value={
                              selfAssessment.selfAssessment.score_step_three
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <p>
                              {selfAssessment.selfAssessment.score_step_three}
                            </p>
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFourthRow>
                    </StyledStepInner>
                  </StyledStepWrapper>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledStepWrapperBorderless>
                    <StyledStepInner>
                      <StyledImageWrapper>
                        <img src={step4} alt="step4" />
                      </StyledImageWrapper>
                      <StyledStepFirstRow container>
                        <Grid item xs={12} md={2}>
                          <h1>4</h1>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <h3>{t("step supplier products")}</h3>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <StyledImage src={arrowRight} alt="arrow right" />
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFirstRow>
                      <StyledStepSecondRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <p>{t("SA info 4")}</p>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepSecondRow>
                      <StyledStepThirdRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <Link to="/supplier-products">
                            <StyledButtonPrimary>
                              {t("start")}
                            </StyledButtonPrimary>
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={2}></Grid>
                      </StyledStepThirdRow>
                      <StyledStepFourthRow container>
                        <Grid item xs={12} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                          <LinearProgress
                            variant="determinate"
                            value={
                              selfAssessment.selfAssessment.score_step_four
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <StyledEndWrapper>
                            <p>
                              {selfAssessment.selfAssessment.score_step_four}
                            </p>
                          </StyledEndWrapper>
                        </Grid>
                      </StyledStepFourthRow>
                    </StyledStepInner>
                  </StyledStepWrapperBorderless>
                </Grid>
              </Grid>
            </StyledStepsWrapper>
          </StyledIntro>
        </Fragment>
      )}
    </Fragment>
  );
});

export default SelfAssessment;
