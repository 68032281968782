import React, { Fragment } from "react";
import { Container, Box, Grid, Button } from "@material-ui/core";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { InputLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { withSnackbar } from "notistack";

import { auth, support } from "stores/BaseStore";

import Loader from "components/shared/Loader";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;

    span {
      font-weight: 700;
    }
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 40px;
  }
`;

const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

const StyledMain = styled(Box)`
  position: relative;
  z-index: 2;
`;

const StyledMainWrapper = styled(Container)`
  position: relative;
  z-index: 2;
`;

const StyledSectionOuter = styled(Box)`
  border-left: 2px solid #01e7aa;
  padding-left: 11px;
  margin-bottom: 60px;
`;

const StyledSectionInner = styled(Box)`
  background: #f3f9fa;
  padding: 40px;

  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    line-height: 93.1%;
    color: #000000;
    margin-bottom: 9px;
    position: relative;
  }

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }

  .MuiDropzoneArea-root {
    height: 133px;
    border-radius: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: flex;
    justify-content: center;
  }

  .MuiSnackbar-root {
    right: 24px;
    left: auto;
  }

  .MuiDropzoneArea-text {
    display: none;
  }

  .MuiDropzonePreviewList-image {
    height: 50px;
  }
`;

const StyledField = styled(Field)`
  background: #ffffff;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  font-family: "RobotoRegular", "Arial", sans-serif;
  transform: none !important;
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 20px;
`;

const StyledSelect = styled(FormControl)`
  margin-bottom: 30px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

const StyledButtonPrimary = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const menuItemStyle = {
  paddingLeft: "25px",
};

const Help = view((props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    if (!values.topic) {
      errors.topic = t("topic required");
    }
    if (!values.message) {
      errors.message = t("message required");
    }

    return errors;
  };

  autoEffect(() => {
    if (support.errors) {
      props.enqueueSnackbar(t("something went wrong"), {
        variant: "error",
      });
    }

    if (support.sent) {
      props.enqueueSnackbar(t("sent successfully"), {
        variant: "success",
      });
    }
  });

  return (
    <Fragment>
      {!auth.user ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <h2>{t("contact our support")}</h2>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
          <StyledMain>
            <StyledMainWrapper>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <StyledSectionOuter>
                    <StyledSectionInner>
                      <Formik
                        initialValues={{
                          message: "",
                          topic: "",
                        }}
                        validate={validate}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                          setSubmitting(false);
                          await support.sendSupport(values);
                          if (support.sent) {
                            resetForm();
                          }
                        }}
                      >
                        {({
                          submitForm,
                          isSubmitting,
                          setFieldValue,
                          errors,
                        }) => (
                          <Form>
                            <StyledSelect
                              fullWidth
                              variant="outlined"
                              size="small"
                              error={errors.topic}
                            >
                              <StyledInputLabel
                                htmlFor="topic"
                                shrink={true}
                                variant="outlined"
                                required
                              >
                                {t("topic")}
                              </StyledInputLabel>
                              <Select
                                onChange={(event) =>
                                  setFieldValue("topic", event.target.value)
                                }
                                id="topic"
                                name="topic"
                              >
                                <MenuItem value=""></MenuItem>
                                <ListSubheader disableSticky>
                                  My Account
                                </ListSubheader>
                                <MenuItem
                                  value={"Creating a New Account"}
                                  style={menuItemStyle}
                                >
                                  Creating a New Account
                                </MenuItem>
                                <MenuItem
                                  value={"Account Termination / Delete"}
                                  style={menuItemStyle}
                                >
                                  Account Termination / Delete
                                </MenuItem>
                                <MenuItem
                                  value={"I Want to Change Account Type"}
                                  style={menuItemStyle}
                                >
                                  I Want to Change Account Type
                                </MenuItem>
                                <ListSubheader disableSticky>
                                  Security
                                </ListSubheader>
                                <MenuItem
                                  value={"Forgot Password"}
                                  style={menuItemStyle}
                                >
                                  Forgot Password
                                </MenuItem>
                                <MenuItem
                                  value={"Account abuse"}
                                  style={menuItemStyle}
                                >
                                  Account abuse
                                </MenuItem>
                                <ListSubheader disableSticky>
                                  Troubleshooting
                                </ListSubheader>
                                <MenuItem
                                  value={
                                    "I Changed my Company, How Can I De-Assign Myself From Current One"
                                  }
                                  style={menuItemStyle}
                                >
                                  I Changed my Company, How Can I De-Assign
                                  Myself From Current One
                                </MenuItem>
                                <MenuItem
                                  value={
                                    "Someone Else has Rights to Edit my Company"
                                  }
                                  style={menuItemStyle}
                                >
                                  Someone Else has Rights to Edit my Company
                                </MenuItem>
                                <MenuItem
                                  value={
                                    "I Want to Transfer Company Rights to Another User"
                                  }
                                  style={menuItemStyle}
                                >
                                  I Want to Transfer Company Rights to Another
                                  User
                                </MenuItem>
                                <MenuItem
                                  value={
                                    "I Have Problems with Receiving Emails and Notifications"
                                  }
                                  style={menuItemStyle}
                                >
                                  I Have Problems with Receiving Emails and
                                  Notifications
                                </MenuItem>
                                <ListSubheader disableSticky>
                                  General
                                </ListSubheader>
                                <MenuItem
                                  value={"About Yvoo"}
                                  style={menuItemStyle}
                                >
                                  About Yvoo
                                </MenuItem>
                                <MenuItem value={"Other"} style={menuItemStyle}>
                                  Other
                                </MenuItem>
                              </Select>
                              {errors.topic ? (
                                <FormHelperText>{errors.topic}</FormHelperText>
                              ) : null}
                              {/* <FormHelperText>{error}</FormHelperText>; */}
                            </StyledSelect>
                            <StyledInputLabel
                              htmlFor="message"
                              shrink={true}
                              variant="outlined"
                              required
                            >
                              {t("message")}
                            </StyledInputLabel>
                            <StyledField
                              component={TextField}
                              name="message"
                              autoComplete="off"
                              fullWidth
                              variant="outlined"
                              size="small"
                              multiline
                              rows={4}
                            />
                            {isSubmitting}
                            <br />
                            <StyledButtonPrimary
                              type="button"
                              disabled={isSubmitting}
                              onClick={submitForm}
                            >
                              {t("send message")}
                            </StyledButtonPrimary>
                          </Form>
                        )}
                      </Formik>
                    </StyledSectionInner>
                  </StyledSectionOuter>
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
        </Fragment>
      )}
    </Fragment>
  );
});

export default withSnackbar(Help);
