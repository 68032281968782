import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import AsyncSelect from "react-select/async";
import { view } from "@risingstack/react-easy-state";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { search } from "../../../stores/BaseStore";

import searchGraphic from "assets/img/searchGraphic.svg";
import searchIcon from "assets/img/searchIcon.svg";

const StyledWrapper = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSearchWrapper = styled(Box)`
  position: absolute;
  z-index: 3;
  width: 100%;
  bottom: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  position: relative;
  max-width: 100%;
  width: 100%;
  z-index: 2;
`;

const StyledSearchButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    marginRight: "20px",
    "@media (max-width: 959px)": {
      width: "100%",
    },
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "none",
    borderWidth: "0 !important",
    boxShadow: "none",
    borderBottom: "1px solid #ffffff !important",
    outline: "none",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // placeholder: (provided) => ({
  //   ...provided,
  //   color: "#0091d4",
  //   fontSize: "16px",
  // }),
  // input: (provided) => ({
  //   ...provided,
  //   color: "#0091d4",
  //   fontSize: "16px",
  // }),
  // valueContainer: (provided) => ({
  //   ...provided,
  //   padding: "17px",
  // }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
  }),
  // option: (provided) => ({
  //   ...provided,
  //   backgroundColor: "transparent",
  //   padding: "17px",
  //   "&:hover": {
  //     color: "#0091d4",
  //     cursor: "pointer",
  //   },
  // }),
};

const SearchInput = view(() => {
  const [value, setValue] = useState("");
  const [selectedResult, setSelectedResult] = useState(null);
  const [tempValues, setTempValues] = useState(null);
  const history = useHistory();
  let allowCall = true;
  let selectRef = useRef(null);
  const { t } = useTranslation();

  let results = (inputValue, data) => {
    // data.filter((key) =>
    //   key.name.toLowerCase().includes(inputValue.toLowerCase())
    return data;
  };

  const handleInputChange = (input, params) => {
    selectRef.current.select.select.getNextFocusedOption = () => false;

    if (params.action === "input-change") {
      setValue({
        inputValue: input,
      });
    }
    // setValue({
    //   inputValue: input,
    // });
    setTempValues(input);
    return { value };
  };

  const handleChange = (selectedOption) => {
    setSelectedResult(selectedOption);
  };

  const handleGetResults = (inputValue) =>
    inputValue.length > 2 &&
    allowCall &&
    search
      .getSuggestions(inputValue)
      .then((response) => {
        return results(inputValue, response);
      })
      .catch((data) => {
        console.log(data);
      });

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleGetResults(inputValue));
      }, 1000);
    });

  let counter = setTimeout(() => {
    allowCall = true;
  }, 1000);

  const handleKeyDown = () => {
    allowCall = false;
    clearTimeout(counter);
    return counter;
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (value.inputValue) {
      history.push(
        `/search-results/${value.inputValue.toLowerCase()}/region/all/page/1`
      );
    }
  };

  useEffect(() => {
    if (selectedResult) {
      history.push(
        `/search-results/${selectedResult.commodity_name.toLowerCase()}/region/all/page/1`
      );
    }
    // eslint-disable-next-line
  }, [selectedResult]);

  return (
    <StyledWrapper>
      <form onSubmit={submitForm}>
        <StyledSearchWrapper>
          <AsyncSelect
            ref={selectRef}
            styles={customStyles}
            value={selectedResult}
            loadOptions={promiseOptions}
            defaultOptions
            onInputChange={handleInputChange}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={t("search")}
            loadingMessage={() => `${t("searching")}...`}
            noOptionsMessage={() => t("no suggested products")}
            getOptionValue={(option) => option.commodity_name}
            getOptionLabel={(option) => {
              return option.commodity_name;
            }}
            menuIsOpen={tempValues ? true : false}
          />
          <StyledSearchButton onClick={submitForm}>
            <img src={searchIcon} alt="search icon" />
          </StyledSearchButton>
        </StyledSearchWrapper>
        <StyledImage src={searchGraphic} alt="search graphic" />
      </form>
    </StyledWrapper>
  );
});

export default SearchInput;
