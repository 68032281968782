import React from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { autoEffect, view } from "@risingstack/react-easy-state";
import { Hub } from "aws-amplify";
import "@aws-amplify/ui/dist/style.css";
import { useTranslation } from "react-i18next";

import { auth, language } from "./stores/BaseStore";
import i18next from "i18next";

import ScrollToTop from "./components/shared/ScrollToTop";
import Wrapper from "./components/Wrapper";
import { useEffect } from "react";

const App = view(() => {
  const { i18n } = useTranslation();

  autoEffect(() => {
    Hub.listen("auth", () => {
      if (!auth.user) {
        auth.getUser();
      }
    }); // listen for login/signup events
    auth.getUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", auth.getUser); // cleanup
  }, []);

  autoEffect(() => {
    language.getLanguage();

    if (language.language) {
      i18n.changeLanguage(language.language);
      i18next.on("languageChanged", () => {
        auth.user = null;
        window.location.reload();
      });
    }
  });

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      auth.refreshToken();
    }, 3000000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Wrapper />
    </Router>
  );
});

export default App;
