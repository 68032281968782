import i18next from "i18next";

export default {
  formId: "mainForm",
  formField: {
    name: {
      name: "name",
      label: i18next.t("full name"),
      requiredErrorMsg: i18next.t("full name is required"),
    },
    email: {
      name: "email",
      label: i18next.t("email"),
      requiredErrorMsg: i18next.t("email is required"),
    },
    profile_photo: {
      name: "custom:profile_photo",
      label: i18next.t("profile photo"),
    },
    mobile_phone: {
      name: "custom:mobile_phone",
      label: i18next.t("phone number"),
      invalidErrorMsg: i18next.t("phone number is not valid"),
    },
    country: {
      name: "custom:country",
      label: i18next.t("country"),
    },
    zip: {
      name: "custom:zip",
      label: i18next.t("zip code"),
      invalidErrorMsg: i18next.t("zip code is not valid"),
    },
    job_function: {
      name: "custom:job_function",
      label: i18next.t("job function"),
    },
    job_level: {
      name: "custom:job_level",
      label: i18next.t("job level"),
    },
    discipline: {
      name: "custom:discipline",
      label: i18next.t("discipline"),
    },
    industry: {
      name: "custom:industry",
      label: i18next.t("industry"),
    },
    company_name: {
      name: "custom:company_name",
      label: i18next.t("company name"),
    },
    address: {
      name: "address",
      label: i18next.t("address"),
    },
    city: {
      name: "custom:city",
      label: i18next.t("city"),
    },
  },
};
