import formModel from "./formModel";
const {
  formField: {
    name,
    industry,
    company_type,
    parent_company,
    number_of_employees,
    turnover,
    duns_number,
    tax_jurisdiction_code,
    supplier_type,
    communication_language,
    country_id,
    city_id,
    postcode,
    address,
    phone,
    email,
    website,
    short_description,
    iso9001_2015,
    iso14001,
    ohsas18001,
    iso9001_2015ExpiryDate,
    iso14001ExpiryDate,
    ohsas18001ExpiryDate,
    logo,
    company_presentation,
    reference_list,
    vat_id,
    certificates,
    production_locations,
    photos,
  },
} = formModel;

export default {
  [name.name]: "",
  [industry.name]: "",
  [company_type.name]: "",
  [parent_company.name]: "",
  [number_of_employees.name]: "",
  [turnover.name]: "",
  [duns_number.name]: "",
  [tax_jurisdiction_code.name]: "",
  [supplier_type.name]: "",
  [communication_language.name]: "",
  [country_id.name]: "",
  [city_id.name]: "",
  [postcode.name]: "",
  [address.name]: "",
  [phone.name]: "",
  [email.name]: "",
  [website.name]: "",
  [short_description.name]: "",
  [iso9001_2015.name]: false,
  [iso14001.name]: false,
  [ohsas18001.name]: false,
  [iso9001_2015ExpiryDate.name]: "",
  [iso14001ExpiryDate.name]: "",
  [ohsas18001ExpiryDate.name]: "",
  [logo.name]: "",
  [company_presentation.name]: "",
  [reference_list.name]: "",
  [vat_id.name]: "",
  [certificates.name]: [],
  [production_locations.name]: [],
  [photos.name]: [],
};
