import i18next from "i18next";

export default {
  formId: "mainForm",
  formField: {
    segment: {
      name: "segment",
      label: i18next.t("segment"),
      requiredErrorMsg: i18next.t("segment is required"),
    },
    family: {
      name: "family",
      label: i18next.t("family"),
      requiredErrorMsg: i18next.t("family is required"),
    },
    productClass: {
      name: "class",
      label: i18next.t("class"),
      requiredErrorMsg: i18next.t("class is required"),
    },
    commodities: {
      name: "commodities",
      label: i18next.t("commodities"),
      requiredErrorMsg: i18next.t("commodities are required"),
    },
  },
};
