import React, { Fragment, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import styled from "styled-components";
import { autoEffect, view } from "@risingstack/react-easy-state";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { InputLabel } from "@material-ui/core";

import { auth, favorites, invite } from "../../../stores/BaseStore";
import Loader from "../../shared/Loader";
import LoaderAbsolute from "components/shared/LoaderAbsolute";
import MainModal from "components/shared/MainModal";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledMain = styled(Box)`
  width: 100%;
  z-index: 2;
  position: relative;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
  }
`;

const StyledMainWrapper = styled(Container)`
  width: 100%;
  position: relative;

  position: relative;
  z-index: 2;
  margin-bottom: 60px;

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 24px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 36px;

    span {
      color: #808285;

      &.separator {
        color: #cfcfcf;
      }
    }
  }
`;

const StyledTableContainer = styled(TableContainer)`
  margin-bottom: 40px;

  .MuiTable-root {
    border-collapse: separate;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTableHead-root {
    background-color: #000000;

    .MuiTableCell-head {
      font-family: "RobotoRegular", "Arial", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #ffffff;
    }
  }

  .MuiTableCell-body {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    a {
      text-decoration-line: underline;
      color: #00c9e9;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const StyledSpecialCell = styled.span`
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;
  color: #00bfe9;
`;

const StyledField = styled(Field)`
  background: #ffffff;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  font-family: "RobotoRegular", "Arial", sans-serif;
  transform: none !important;
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 20px;
`;

const StyledButtonPrimary = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const MySuppliers = view(() => {
  const [favoritesRows, setFavoritesRows] = useState([]);
  const { t } = useTranslation();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  useEffect(() => {
    favorites.userFavorites = null;
    favorites.getUserFavorites();
  }, []);

  const createFavoritesData = (id, company_id, company_name, date) => {
    return { id, company_id, company_name, date };
  };

  autoEffect(() => {
    if (favorites.userFavorites) {
      setFavoritesRows(
        favorites.userFavorites.map((favorite) => {
          return createFavoritesData(
            favorite.id ? favorite.id : null,
            favorite.Company && favorite.Company.id
              ? favorite.Company.id
              : null,
            favorite.Company && favorite.Company.name
              ? favorite.Company.name
              : t("no data"),
            favorite.created_at
              ? moment(favorite.created_at).format("DD.MM.YYYY.")
              : t("no data")
          );
        })
      );
    }
  });

  const handleInviteModalOpen = () => {
    invite.sent = false;
    setInviteModalOpen(true);
  };

  const handleInviteModalClose = () => {
    setInviteModalOpen(false);
    setTimeout(() => {
      invite.sent = false;
    }, 500);
  };

  const validateInvite = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = t("email is required");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = t("invalid email address");
    }

    return errors;
  };

  return (
    <Fragment>
      {!auth.user ||
      !favorites.userFavorites ||
      favorites.loading ||
      !favoritesRows ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          {/* <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={6}>
                  <h2>{auth.user.attributes.name}</h2>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro> */}
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <MainModal
                    modalOpen={inviteModalOpen}
                    handleModalClose={handleInviteModalClose}
                  >
                    {invite.loading ? <LoaderAbsolute /> : null}
                    {!invite.loading && invite.sent ? (
                      <p style={{ padding: "4rem 3rem" }}>
                        {t("invitation sent successfully.")}
                      </p>
                    ) : !invite.loading && invite.errors ? (
                      <p style={{ padding: "4rem 3rem" }}>
                        {t("there's been an error. please try again.")}
                      </p>
                    ) : (
                      <Formik
                        initialValues={{
                          email: "",
                        }}
                        validate={validateInvite}
                        onSubmit={(values, { setSubmitting }) => {
                          setSubmitting(false);
                          invite.sendInvite(values);
                        }}
                      >
                        {({ submitForm, isSubmitting }) => (
                          <Form>
                            <StyledInputLabel
                              htmlFor="email"
                              shrink={true}
                              variant="outlined"
                              required
                            >
                              {t("supplier email")}
                            </StyledInputLabel>
                            <StyledField
                              component={TextField}
                              name="email"
                              autoComplete="off"
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="email"
                              multiline
                            />

                            {isSubmitting}
                            <br />
                            <StyledButtonPrimary
                              type="button"
                              disabled={isSubmitting}
                              onClick={submitForm}
                            >
                              {t("send an invitation")}
                            </StyledButtonPrimary>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </MainModal>
                  <h3>
                    {t("suppliers added to my suppliers")}{" "}
                    <span className={"separator"}>|</span>{" "}
                    <span>{favoritesRows.length}</span>
                  </h3>
                  <StyledTableContainer>
                    <Table aria-label="favorites table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("company name")}</TableCell>
                          <TableCell align="right">{t("date")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {favoritesRows.map((row, index) => (
                          <TableRow
                            key={row.id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#F3F9FA" : "#FFFFFF",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Link to={`/companies/${row.company_id}`}>
                                <StyledSpecialCell>
                                  {row.company_name}
                                </StyledSpecialCell>
                              </Link>
                            </TableCell>
                            <TableCell align="right">{row.date}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <StyledButtonPrimary onClick={handleInviteModalOpen}>
                    {t("invite a new supplier")}
                  </StyledButtonPrimary>
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
        </Fragment>
      )}
    </Fragment>
  );
});

export default MySuppliers;
