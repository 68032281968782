import React, { Fragment } from "react";
import { Grid, Box, InputLabel } from "@material-ui/core";
import { InputField, SelectField } from "../../FormFields";
import styled from "styled-components";
import { DropzoneArea } from "material-ui-dropzone";
import { useTranslation } from "react-i18next";

import fileUploadIcon from "assets/img/fileUpload.svg";

import Loader from "components/shared/Loader";
import { view } from "@risingstack/react-easy-state";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledSectionOuter = styled(Box)`
  border-left: 2px solid #01e7aa;
  padding-left: 11px;
  margin-bottom: 60px;
`;

const StyledSectionInner = styled(Box)`
  background: #f3f9fa;
  padding: 40px;

  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    line-height: 93.1%;
    color: #000000;
    margin-bottom: 9px;
  }

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }

  .MuiDropzoneArea-root {
    height: 133px;
    border-radius: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: flex;
    justify-content: center;
  }

  .MuiSnackbar-root {
    right: 24px;
    left: auto;
  }

  .MuiDropzoneArea-text {
    display: none;
  }

  .MuiDropzonePreviewList-image {
    height: 50px;
  }
`;

const UploadFakeLabel = styled.span`
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 9px;
`;

const MainForm = view((props) => {
  const {
    formField: {
      name,
      email,
      profile_photo,
      mobile_phone,
      country,
      zip,
      job_function,
      job_level,
      discipline,
      industry,
      company_name,
      address,
      city,
    },
    initialValues,
    values,
    setFieldValue,
    countries,
  } = props;
  const { t } = useTranslation();

  const preparedCountries = Array.from(countries).sort((a, b) => {
    return a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  }).map((country) => {
    return {
      value: country.name,
      label: country.name.charAt(0).toUpperCase() + country.name.slice(1),
      iso: country.iso,
    };
  });

  const industries = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "Advertising",
      label: t("advertising"),
    },
    {
      value: "Agriculture",
      label: t("agriculture"),
    },
    {
      value: "Banking & Financial Services",
      label: t("banking & financial services"),
    },
    {
      value: "Civil Construction",
      label: t("civil construction"),
    },
    {
      value: "Consulting",
      label: t("consulting"),
    },
    {
      value: "Ecommerce",
      label: t("ecommerce"),
    },
    {
      value: "Education",
      label: t("education"),
    },
    {
      value: "Electronics / Hardware",
      label: t("electronics / hardware"),
    },
    {
      value: "Energy",
      label: t("energy"),
    },
    {
      value: "Fast Moving Consumer Goods",
      label: t("fast moving consumer goods"),
    },
    {
      value: "Healthcare",
      label: t("healthcare"),
    },
    {
      value: "Human Resources Service Provider",
      label: t("human resources service provider"),
    },
    {
      value: "Industrial Goods",
      label: t("industrial goods"),
    },
    {
      value: "IT Products",
      label: t("IT products"),
    },
    {
      value: "IT Services",
      label: t("IT services"),
    },
    {
      value: "Media",
      label: t("media"),
    },
    {
      value: "Real Estate",
      label: t("real estate"),
    },
    {
      value: "Retail",
      label: t("retail"),
    },
    {
      value: "Telecommunication",
      label: t("telecommunication"),
    },
    {
      value: "Tourism / Hotel / Restaurant",
      label: t("tourism / hotel / restaurant"),
    },
    {
      value: "Transport - Logistic",
      label: t("transport - logistic"),
    },
    {
      value: "Other",
      label: t("other"),
    },
  ];

  const job_functions = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "Accounting and Control",
      label: t("accounting and control"),
    },
    {
      value: "Consulting",
      label: t("consulting"),
    },
    {
      value: "Customer Support / Service",
      label: t("customer support / service"),
    },
    {
      value: "Engineering",
      label: t("engineering"),
    },
    {
      value: "Finance",
      label: t("finance"),
    },
    {
      value: "General Management",
      label: t("general management"),
    },
    {
      value: "Human Resources Management",
      label: t("human resources management"),
    },
    {
      value: "Information Technology",
      label: t("information technology"),
    },
    {
      value: "Learning and Development",
      label: t("learning and development"),
    },
    {
      value: "Legal",
      label: t("legal"),
    },
    {
      value: "Logistics / Purchasing",
      label: t("logistics / purchasing"),
    },
    {
      value: "Manufacturing and Operations",
      label: t("manufacturing and operations"),
    },
    {
      value: "Marketing / Communications / PR",
      label: t("marketing / communications / PR"),
    },
    {
      value: "Medical",
      label: t("medical"),
    },
    {
      value: "Project Management",
      label: t("project management"),
    },
    {
      value: "Research and Development",
      label: t("research and development"),
    },
    {
      value: "Sales and Business Development",
      label: t("sales and business development"),
    },
    {
      value: "Strategy / Corporate Planning",
      label: t("strategy / corporate planning"),
    },
    {
      value: "Other",
      label: t("other"),
    },
  ];

  const job_levels = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "Assistant / Entry-Level",
      label: t("assistant / entry-Level"),
    },
    {
      value: "Chairman",
      label: t("chairman"),
    },
    {
      value: "Director",
      label: t("director"),
    },
    {
      value: "Manager",
      label: t("manager"),
    },
    {
      value: "Specialist",
      label: t("specialist"),
    },
    {
      value: "Team Lead",
      label: t("team lead"),
    },
    {
      value: "Other",
      label: t("other"),
    },
  ];

  const disciplines = [
    {
      value: undefined,
      label: t("other"),
    },
    {
      value: "Engineering",
      label: t("engineering"),
    },
    {
      value: "Finance",
      label: t("finance"),
    },
    {
      value: "IT",
      label: t("IT"),
    },
    {
      value: "Management",
      label: t("management"),
    },
    {
      value: "Material Management",
      label: t("material management"),
    },
    {
      value: "Production",
      label: t("production"),
    },
    {
      value: "Quality Management",
      label: t("quality management"),
    },
    {
      value: "R&D",
      label: t("R&D"),
    },
    {
      value: "Sales",
      label: t("sales"),
    },
    {
      value: "Other",
      label: t("other"),
    },
  ];

  return !initialValues ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <h2>{t("user profile")}</h2>
        </Grid>
      </Grid>
      <StyledSectionOuter>
        <StyledSectionInner>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              <InputLabel
                htmlFor={name.name}
                shrink={true}
                variant="outlined"
                required
              >
                {name.label}
              </InputLabel>
              <InputField
                id={name.name}
                name={name.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel htmlFor={email.name} shrink={true} variant="outlined">
                {email.label}
              </InputLabel>
              <InputField
                id={email.name}
                name={email.name}
                fullWidth
                variant="outlined"
                size="small"
                disabled
              />
              <UploadFakeLabel>{profile_photo.label}</UploadFakeLabel>
              <DropzoneArea
                id={profile_photo.name}
                name={profile_photo.name}
                filesLimit={1}
                dropzoneText=""
                showPreviewsInDropzone
                previewGridProps={{ container: { spacing: 0 } }}
                acceptedFiles={[".png", ".jpg", ".jpeg"]}
                initialFiles={
                  initialValues["custom:profile_photo"]
                    ? [initialValues["custom:profile_photo"]]
                    : []
                }
                Icon={() => {
                  return (
                    <Fragment>
                      <img
                        src={fileUploadIcon}
                        alt="file upload icon"
                        style={{ marginBottom: "15px" }}
                      />
                      {!values["custom:profile_photo"] ||
                      values["custom:profile_photo"].length === 0 ? (
                        <p>{t("drag and drop your file here")}</p>
                      ) : null}
                    </Fragment>
                  );
                }}
                onDelete={() => {
                  setFieldValue(profile_photo.name, "");
                }}
                onDrop={(acceptedFiles) => {
                  // do nothing if no files
                  if (acceptedFiles.length === 0) {
                    return;
                  }

                  const file = acceptedFiles[0];
                  const reader = new FileReader();
                  setFieldValue(profile_photo.name, file.name);
                  reader.onload = function (item) {
                    setFieldValue(profile_photo.name, item.target.result);
                  };

                  reader.readAsDataURL(file);
                }}
              />
              <InputLabel
                htmlFor={mobile_phone.name}
                shrink={true}
                variant="outlined"
              >
                {mobile_phone.label}
              </InputLabel>
              <InputField
                id={mobile_phone.name}
                name={mobile_phone.name}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="+1 23 456 7890"
              />
              <InputLabel
                htmlFor={address.name}
                shrink={true}
                variant="outlined"
              >
                {address.label}
              </InputLabel>
              <InputField
                id={address.name}
                name={address.name}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel htmlFor={zip.name} shrink={true} variant="outlined">
                {zip.label}
              </InputLabel>
              <InputField
                id={zip.name}
                name={zip.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel htmlFor={city.name} shrink={true} variant="outlined">
                {city.label}
              </InputLabel>
              <InputField
                id={city.name}
                name={city.name}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={country.name}
                shrink={true}
                variant="outlined"
              >
                {country.label}
              </InputLabel>
              <SelectField
                id={country.name}
                name={country.name}
                data={preparedCountries}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={job_function.name}
                shrink={true}
                variant="outlined"
              >
                {job_function.label}
              </InputLabel>
              <SelectField
                id={job_function.name}
                name={job_function.name}
                data={job_functions}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={job_level.name}
                shrink={true}
                variant="outlined"
              >
                {job_level.label}
              </InputLabel>
              <SelectField
                id={job_level.name}
                name={job_level.name}
                data={job_levels}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputLabel
                htmlFor={company_name.name}
                shrink={true}
                variant="outlined"
              >
                {company_name.label}
              </InputLabel>
              <InputField
                id={company_name.name}
                name={company_name.name}
                fullWidth
                variant="outlined"
                size="small"
              />

              <InputLabel
                htmlFor={discipline.name}
                shrink={true}
                variant="outlined"
              >
                {discipline.label}
              </InputLabel>
              <SelectField
                id={discipline.name}
                name={discipline.name}
                data={disciplines}
                fullWidth
                variant="outlined"
                size="small"
              />
              <InputLabel
                htmlFor={industry.name}
                shrink={true}
                variant="outlined"
              >
                {industry.label}
              </InputLabel>
              <SelectField
                id={industry.name}
                name={industry.name}
                data={industries}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
        </StyledSectionInner>
      </StyledSectionOuter>
    </Fragment>
  );
});

export default MainForm;
