import React, { Fragment, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { InputLabel } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import AWS from "aws-sdk";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import saSearchIcon from "assets/img/saSearchIcon.svg";
import favoriteIcon from "assets/img/favoriteIcon.svg";
import favoriteIconFilled from "assets/img/favoriteIconFilled.svg";
// import listIcon from "assets/img/listIcon.svg";
import mailIcon from "assets/img/mailIcon.svg";
import messageIcon from "assets/img/messageIcon.svg";
import basicSearchIcon from "assets/img/basicSearchIcon.svg";
import bestCostCountryIcon from "assets/img/bestCostCountry.png";

import { autoEffect, view } from "@risingstack/react-easy-state";
import { favorites, quickscan, contact, rfi } from "stores/BaseStore";

import LoaderAbsolute from "components/shared/LoaderAbsolute";
import MainModal from "components/shared/MainModal";

const StyledRating = withStyles({
  root: {
    marginBottom: "11px",
  },
  icon: {
    color: "#C4C4C4",
  },
  iconFilled: {
    color: "#06D7F9",
  },
})(Rating);

const StyledResultWrapper = styled(Box)`
  position: relative;
  margin-bottom: 56px;
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  transition: all 0.3s ease-in-out;
`;

const StyledLogoWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 26px;

  img {
    height: 104px;
    width: 104px;
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 10px;
  }

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 0 !important;
  }

  h6 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-transform: capitalize;
    color: #b0b0b0;
    margin: 0;
  }
`;

const StyledImageNotFound = styled(BrokenImageIcon)`
  height: 104px;
  width: 104px;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 10px;
  fill: #b0b0b0;
`;

const StyledLogoLinkWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;

  img {
    width: 22.5px;
    height: 18.6px;
    margin-right: 6px;
  }

  a {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #000000;
  }

  span {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: ${(props) => (props.ordered ? "none" : "underline")};
    text-transform: capitalize;
    color: ${(props) => (props.ordered ? "#b0b0b0" : "#000000")};
    cursor: ${(props) => (props.ordered ? "default" : "pointer")};
  }
`;

const StyledNameWrapper = styled(Box)`
  flex-wrap: wrap;

  h3 {
    font-family: "RobotoMedium", "Arial", sans-serif !important;
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 16px !important;
  }

  a {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #000000;
    margin: 0;
  }
`;

const StyledDescWrapper = styled(Box)`
  min-height: 68px;
  margin-bottom: 14px;

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
  }
`;

const StyledLinkWrapper = styled(Box)`
  a {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #00c9e9;
    display: flex;
    margin-bottom: 26px;
  }
`;

const StyledProductsWrapper = styled(Box)`
  h4 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 16px;
  }

  ul {
    list-style-position: inside;
    padding-left: 0;
    margin: 0;
    min-height: 68px;
    margin-bottom: 14px;

    li {
      font-family: "RobotoRegular", "Arial", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #ff490f;

      span {
        color: #000000;
      }
    }
  }

  a {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #00c9e9;
  }
`;

const StyledLinksWrapper = styled(Box)`
  img {
    width: 22px;
    height: 18px;
    margin-right: 6px;
  }

  a,
  span {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #000000;
    margin-right: 18px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const StyledNoRatingWrapper = styled(Box)`
  min-height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    width: 80%;
    text-align: center;
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-transform: capitalize;
    margin: 0;
  }
`;

const StyledField = styled(Field)`
  background: #ffffff;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

const StyledDatePicker = styled(KeyboardDatePicker)`
  background: #ffffff;
  margin: 0;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  font-family: "RobotoRegular", "Arial", sans-serif;
  transform: none !important;
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 20px;
`;

const StyledButtonPrimary = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledLinkMain = styled(Link)`
  text-decoration-line: none !important;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledButtonWithIcon = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  margin-bottom: 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledPlusSign = styled(Box)`
  height: 100%;
  width: 46px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #ffffff;
  }
`;

const StyledRemoveWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 33px;
`;

const StyledMinusSign = styled(Box)`
  height: 40px;
  width: 40px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: #ffffff;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledButtonText = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`;

const StyledBestCostCountryWrapper = styled(Box)`
  height: 62px;
  width: 95px;
  margin-bottom: 10px;

  img {
    height: 100%;
    width: 100%;
    margin-bottom: 0;
  }
`;

const StyledDetailsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin: 0;
    margin-right: 20px;

    span {
      font-weight: 700;
    }
  }
`;

const SearchResult = view((props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.up("lg"));
  // const { result, term } = props;
  const { result } = props;
  const [favorite, setFavorite] = useState(false);
  // const [longlist, setLonglist] = useState(false);
  const [quickscanOrderStatus, setQuickscanOrderStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [rfiModalOpen, setRFIModalOpen] = useState(false);
  const { t } = useTranslation();
  const [s3Logo, setS3Logo] = useState(null);

  const getS3Data = async (type, hook) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    });

    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Prefix: `media/${result.id}/${type}/`,
    };

    s3.listObjects(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        if (data.Contents.length > 0) {
          const content = data.Contents.map((url) => {
            return `${process.env.REACT_APP_S3_ENDPOINT}/${url.Key}`;
          });

          hook(content);
        } else {
          hook([]);
        }
      }
    });
  };

  autoEffect(async () => {
    if (result) {
      await getS3Data("logo", setS3Logo);
    }
  });

  useEffect(() => {
    setFavorite(result.is_favourite);
  }, [result.is_favourite]);

  // useEffect(() => {
  //   setLonglist(result.is_shortlisted);
  // }, [result.is_shortlisted]);

  // useEffect(() => {
  //   if (!result.QuickScanOrder || !result.QuickScanOrder.status) {
  //     setQuickscanOrderStatus(false);
  //   } else {
  //     setQuickscanOrderStatus(true);
  //   }
  // }, [result.QuickScanOrder]);

  const handleFavorite = async (id) => {
    setLoading(true);
    if (favorite) {
      await favorites.removeFavorite(id);
      if (!favorites.error) {
        setFavorite(false);
      }
      setLoading(false);
    } else {
      await favorites.addFavorite(id);
      if (!favorites.error) {
        setFavorite(true);
      }
      setLoading(false);
    }
  };

  // const handleLonglist = async (id) => {
  //   setLoading(true);
  //   if (longlist) {
  //     await longlists.removeFromLonglist(id, term);
  //     if (!longlists.error) {
  //       setLonglist(false);
  //     }
  //     setLoading(false);
  //   } else {
  //     await longlists.addToLonglist(id, term);
  //     if (!longlists.error) {
  //       setLonglist(true);
  //     }
  //     setLoading(false);
  //   }
  // };

  const handleOrderQuickscan = async (id) => {
    setLoading(true);
    if (!quickscanOrderStatus) {
      await quickscan.orderQuickscan(id);
      if (!quickscan.error) {
        setQuickscanOrderStatus(true);
      }
      setLoading(false);
    }
  };

  const handleMessageModalOpen = () => {
    contact.sent = false;
    setMessageModalOpen(true);
  };

  const handleMessageModalClose = () => {
    setMessageModalOpen(false);
    setTimeout(() => {
      contact.sent = false;
    }, 500);
  };

  const handleRFIModalOpen = () => {
    rfi.sent = false;
    setRFIModalOpen(true);
  };

  const handleRFIModalClose = () => {
    setRFIModalOpen(false);
    setTimeout(() => {
      rfi.sent = false;
    }, 500);
  };

  const validateMessage = (values) => {
    const errors = {};

    if (!values.message) {
      errors.message = t("message required");
    }

    return errors;
  };

  const validateRFI = (values) => {
    const errors = {};

    if (!values.product_name) {
      errors.product_name = t("product name required");
    }

    if (
      values.quanitity &&
      (!/^\d+$/.test(values.quanitity) || values.quanitity.length > 16)
    ) {
      errors.quanitity = t("quantity must be a number, max 16 characters");
    }

    if (!values.message) {
      errors.message = t("message required");
    }

    return errors;
  };

  return (
    <Grid item xs={12}>
      <StyledResultWrapper loading={loading}>
        {loading ? <LoaderAbsolute /> : null}
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid item xs={12} lg={2}>
            <MainModal
              modalOpen={messageModalOpen}
              handleModalClose={handleMessageModalClose}
            >
              {contact.loading ? <LoaderAbsolute /> : null}
              {!contact.loading && contact.sent ? (
                <p style={{ padding: "4rem 3rem" }}>
                  {t("message sent successfully.")}
                </p>
              ) : !contact.loading && contact.errors ? (
                <p style={{ padding: "4rem 3rem" }}>
                  {t("there's been an error. please try again.")}
                </p>
              ) : (
                <Formik
                  initialValues={{
                    message: "",
                  }}
                  validate={validateMessage}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    contact.sendMessage(result.id, values);
                  }}
                >
                  {({ submitForm, isSubmitting }) => (
                    <Form>
                      <StyledInputLabel
                        htmlFor="message"
                        shrink={true}
                        variant="outlined"
                        required
                      >
                        {t("message")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="message"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                        multiline
                        rows={4}
                      />

                      {isSubmitting}
                      <br />
                      <StyledButtonPrimary
                        type="button"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        {t("send message")}
                      </StyledButtonPrimary>
                    </Form>
                  )}
                </Formik>
              )}
            </MainModal>
            <MainModal
              modalOpen={rfiModalOpen}
              handleModalClose={handleRFIModalClose}
            >
              {rfi.loading ? <LoaderAbsolute /> : null}
              {!rfi.loading && rfi.sent ? (
                <p style={{ padding: "4rem 3rem" }}>
                  {t("rfq sent successfully")}
                </p>
              ) : !rfi.loading && rfi.errors ? (
                <p style={{ padding: "4rem 3rem" }}>
                  {t("there's been an error. please try again.")}
                </p>
              ) : (
                <Formik
                  initialValues={{
                    product_name: "",
                    manufacturing_process: "",
                    special_requirements: "",
                    location: "",
                    quanitity: "",
                    certificates: "",
                    deadline: null,
                    rfi_deadline: null,
                    supplier_target_countries: "",
                    material: "",
                    application_area: "",
                    keyword: "",
                    type_of_sourcing_item: "",
                    known_supplier_list: [],
                    message: "",
                  }}
                  validate={validateRFI}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    rfi.sendRFI(result.id, values);
                  }}
                >
                  {({ submitForm, isSubmitting, setFieldValue, values }) => (
                    <Form>
                      <StyledInputLabel
                        htmlFor="product_name"
                        shrink={true}
                        variant="outlined"
                        required
                      >
                        {t("product name")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="product_name"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <StyledInputLabel
                        htmlFor="manufacturing_process"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("manufacturing process")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="manufacturing_process"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <StyledInputLabel
                        htmlFor="special_requirements"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("special requirements")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="special_requirements"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <StyledInputLabel
                        htmlFor="location"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("location")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="location"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <StyledInputLabel
                        htmlFor="quanitity"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("quantity")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="quanitity"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <StyledInputLabel
                        htmlFor="certificates"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("certificates")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="certificates"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <StyledInputLabel
                        htmlFor="deadline"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("delivery deadline")}
                      </StyledInputLabel>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <StyledDatePicker
                          variant="inline"
                          inputVariant="outlined"
                          size="small"
                          fullWidth
                          disableToolbar
                          margin="normal"
                          id="deadline"
                          name="deadline"
                          format="dd/MM/yyyy"
                          minDate={new Date()}
                          maxDate={new Date("2050/12/31")}
                          value={values.deadline}
                          onChange={(date) => {
                            if (date) {
                              try {
                                /* const ISODateString = date.toISOString();
                                const ISOWithoutTime = ISODateString.split("T"); */

                                const convertedDate =
                                  moment(date).format("YYYY-MM-DD");

                                setFieldValue("deadline", convertedDate);
                              } catch (error) {
                                setFieldValue("deadline", date);
                              }
                            } else {
                              setFieldValue("deadline", date);
                            }
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <StyledInputLabel
                        htmlFor="rfi_deadline"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("rfq deadline")}
                      </StyledInputLabel>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <StyledDatePicker
                          variant="inline"
                          inputVariant="outlined"
                          size="small"
                          fullWidth
                          disableToolbar
                          margin="normal"
                          id="rfi_deadline"
                          name="rfi_deadline"
                          format="dd/MM/yyyy"
                          minDate={new Date()}
                          maxDate={new Date("2050/12/31")}
                          value={values.rfi_deadline}
                          onChange={(date) => {
                            if (date) {
                              try {
                                /* const ISODateString = date.toISOString();
                                const ISOWithoutTime = ISODateString.split("T"); */

                                const convertedDate =
                                  moment(date).format("YYYY-MM-DD");

                                setFieldValue("rfi_deadline", convertedDate);
                              } catch (error) {
                                setFieldValue("rfi_deadline", date);
                              }
                            } else {
                              setFieldValue("rfi_deadline", date);
                            }
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <StyledInputLabel
                        htmlFor="supplier_target_countries"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("supplier target countries")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="supplier_target_countries"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <StyledInputLabel
                        htmlFor="material"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("material")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="material"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <StyledInputLabel
                        htmlFor="application_area"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("application area")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="application_area"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <StyledInputLabel
                        htmlFor="keyword"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("keyword")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="keyword"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <StyledInputLabel
                        htmlFor="type_of_sourcing_item"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("type of sourcing item")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="type_of_sourcing_item"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <StyledInputLabel
                        htmlFor="known_supplier_list"
                        shrink={true}
                        variant="outlined"
                      >
                        {t("known supplier list")} (
                        {t("this is not sent to the supplier")})
                      </StyledInputLabel>
                      <FieldArray
                        name="known_supplier_list"
                        render={(arrayHelpers) => (
                          <Fragment>
                            {values.known_supplier_list.map(
                              (supplier, index) => (
                                <Fragment key={index}>
                                  <Grid
                                    container
                                    spacing={matches ? 6 : 2}
                                    style={{ margin: 0 }}
                                  >
                                    <Grid
                                      item
                                      xs={10}
                                      lg={10}
                                      style={{ padding: 0 }}
                                    >
                                      {/** both these conventions do the same */}
                                      <StyledInputLabel
                                        htmlFor={`known_supplier_list[${index}].name`}
                                        shrink={true}
                                        variant="outlined"
                                      >
                                        {t("name")}
                                      </StyledInputLabel>
                                      <StyledField
                                        component={TextField}
                                        id={`known_supplier_list[${index}].name`}
                                        name={`known_supplier_list[${index}].name`}
                                        autoComplete="off"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      lg={2}
                                      style={{ padding: 0 }}
                                    >
                                      <StyledRemoveWrapper>
                                        <StyledMinusSign
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <RemoveIcon />
                                        </StyledMinusSign>
                                      </StyledRemoveWrapper>
                                    </Grid>
                                  </Grid>
                                </Fragment>
                              )
                            )}

                            <Grid item xs={12}>
                              <StyledButtonWithIcon
                                onClick={() =>
                                  arrayHelpers.push({
                                    name: "",
                                  })
                                }
                              >
                                <StyledPlusSign>
                                  <AddIcon />
                                </StyledPlusSign>
                                <StyledButtonText>
                                  {t("add supplier")}
                                </StyledButtonText>
                              </StyledButtonWithIcon>
                            </Grid>
                          </Fragment>
                        )}
                      />
                      <StyledInputLabel
                        htmlFor="message"
                        shrink={true}
                        variant="outlined"
                        required
                      >
                        {t("message")}
                      </StyledInputLabel>
                      <StyledField
                        component={TextField}
                        name="message"
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        size="small"
                        multiline
                        rows={4}
                      />

                      {isSubmitting}
                      <br />
                      <StyledButtonPrimary
                        type="button"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        {t("send rfq")}
                      </StyledButtonPrimary>
                    </Form>
                  )}
                </Formik>
              )}
            </MainModal>
            <StyledLogoWrapper>
              {s3Logo.length < 1 ? (
                <StyledImageNotFound />
              ) : (
                <img src={s3Logo[0]} alt="logo" />
              )}
              <h6>{t("overall rating")}</h6>
              {!result.assessment ||
              !result.assessment.overall_score ||
              result.assessment.overall_score.original ? (
                <StyledNoRatingWrapper>
                  <p>{t("not passed self assessment yet")}</p>
                </StyledNoRatingWrapper>
              ) : (
                <Fragment>
                  <h2>{result.assessment.overall_score}</h2>
                  <StyledRating
                    name="read-only"
                    value={parseFloat(result.assessment.overall_score)}
                    precision={0.1}
                    readOnly
                  />
                </Fragment>
              )}
              {result.best_buy ? (
                <StyledBestCostCountryWrapper>
                  <img src={bestCostCountryIcon} alt="best cost country" />
                </StyledBestCostCountryWrapper>
              ) : (
                <StyledBestCostCountryWrapper></StyledBestCostCountryWrapper>
              )}
              {!result.assessment ||
              !result.assessment.overall_score ||
              result.assessment.overall_score.original ? null : (
                <StyledLogoLinkWrapper>
                  <img src={saSearchIcon} alt="self assessment search icon" />
                  <Link to={`/reports/${result.id}`}>
                    {t("self assessment")}
                  </Link>
                </StyledLogoLinkWrapper>
              )}
              <Fragment>
                {!quickscanOrderStatus ? (
                  <StyledLogoLinkWrapper>
                    <img src={basicSearchIcon} alt="favorite" />
                    <span onClick={() => handleOrderQuickscan(result.id)}>
                      {t("order quickscan")}
                    </span>
                  </StyledLogoLinkWrapper>
                ) : (
                  <StyledLogoLinkWrapper ordered>
                    <img src={basicSearchIcon} alt="favorite" />
                    <span>{t("quickscan ordered")}</span>
                  </StyledLogoLinkWrapper>
                )}
              </Fragment>
            </StyledLogoWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            lg={9}
            style={{
              borderLeft: "1px solid #B0B0B0",
              borderRight: "1px solid #B0B0B0",
            }}
          >
            <Grid container style={{ borderBottom: "1px solid #B0B0B0" }}>
              <Grid
                item
                xs={12}
                lg={8}
                style={{
                  paddingTop: "24px",
                  paddingRight: "28px",
                  paddingBottom: "22px",
                  paddingLeft: "28px",
                  borderRight: "1px solid #B0B0B0",
                }}
              >
                <StyledNameWrapper
                  display="flex"
                  justifyContent={matches ? "space-between" : "flex-start"}
                  alignItems={matches ? "center" : "flex-start"}
                  flexDirection={matches ? "row" : "column"}
                >
                  <StyledLinkMain to={`/companies/${result.id}`}>
                    <h3>{result.name}</h3>
                  </StyledLinkMain>
                  {result.website ? (
                    <a
                      href={result.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {result.website}
                    </a>
                  ) : null}
                </StyledNameWrapper>
                <StyledDescWrapper>
                  <p>
                    {!result.short_description
                      ? t("no description")
                      : result.short_description.length > 400
                      ? `${result.short_description.substring(0, 400)}...`
                      : result.short_description}
                  </p>
                </StyledDescWrapper>
                <StyledLinkWrapper>
                  <Link to={`/companies/${result.id}`}>{t("more info")}</Link>
                </StyledLinkWrapper>
                <StyledDetailsWrapper>
                  <p>
                    {t("address")}{" "}
                    <span>
                      {result.address ? result.address : t("no data")}
                    </span>
                  </p>
                  <p>
                    {t("country")}{" "}
                    <span>
                      {result.Country && result.Country.name
                        ? result.Country.name
                        : t("no data")}
                    </span>
                  </p>
                  <p>
                    {t("city")}{" "}
                    <span>
                      {result.City && result.City.name
                        ? result.City.name
                        : t("no data")}
                    </span>
                  </p>
                </StyledDetailsWrapper>
                <StyledDetailsWrapper>
                  <p>
                    {t("employees")}{" "}
                    <span>{result.number_of_employees || 0}</span>
                  </p>
                  <p>
                    {t("turnover")} <span>$ {result.turnover || 0}</span>
                  </p>
                  <p>
                    {t("iso")} 9001:2015{" "}
                    {result.Certificates &&
                    result.Certificates.length > 0 &&
                    result.Certificates.find((certificate) => {
                      return (
                        certificate.name === "iso" &&
                        certificate.number === "9001:2015"
                      );
                    }) ? (
                      <span>{t("yes")}</span>
                    ) : (
                      <span>{t("no")}</span>
                    )}
                  </p>
                </StyledDetailsWrapper>
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                style={{
                  paddingTop: "24px",
                  paddingRight: "28px",
                  paddingBottom: "12px",
                  paddingLeft: "28px",
                }}
              >
                <StyledProductsWrapper>
                  <h4>{t("product")}</h4>
                  <ul>
                    {!result.Categories || result.Categories.length < 1 ? (
                      <li>
                        <span>{t("no products")}</span>
                      </li>
                    ) : (
                      result.Categories.map((category, index) => {
                        if (index < 4) {
                          return (
                            <li key={category.id}>
                              <span>{category.commodity_name}</span>
                            </li>
                          );
                        }
                        return null;
                      })
                    )}
                  </ul>
                  <Link to={`/companies/${result.id}`}>
                    {t("all products")}
                  </Link>
                </StyledProductsWrapper>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  paddingTop: "26px",
                  paddingRight: "28px",
                  paddingBottom: "22px",
                  paddingLeft: "28px",
                }}
              >
                <StyledLinksWrapper
                  display="flex"
                  flexDirection={matchesLg ? "row" : "column"}
                  justifyContent="flex-start"
                  alignItems={matchesLg ? "center" : "flex-start"}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginBottom: "4px" }}
                  >
                    {!favorite ? (
                      <Fragment>
                        <img src={favoriteIcon} alt="favorite" />
                        <span onClick={() => handleFavorite(result.id)}>
                          {t("add to my suppliers")}
                        </span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <img src={favoriteIconFilled} alt="favorite" />
                        <span onClick={() => handleFavorite(result.id)}>
                          {t("remove from my suppliers")}
                        </span>
                      </Fragment>
                    )}
                  </Box>
                  {/* <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginBottom: "4px" }}
                  >
                    {!longlist ? (
                      <Fragment>
                        <img src={listIcon} alt="shortlist" />
                        <span onClick={() => handleLonglist(result.id)}>
                          Shortlist
                        </span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <img src={listIcon} alt="shortlist" />
                        <span onClick={() => handleLonglist(result.id)}>
                          Remove from shortlist
                        </span>
                      </Fragment>
                    )}
                  </Box> */}
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginBottom: "4px" }}
                  >
                    <img src={mailIcon} alt="mail" />
                    <span onClick={handleRFIModalOpen}>{t("send rfq")}</span>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginBottom: "4px" }}
                  >
                    <img src={messageIcon} alt="message" />
                    <span onClick={handleMessageModalOpen}>{t("message")}</span>
                  </Box>
                </StyledLinksWrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledResultWrapper>
    </Grid>
  );
});

export default SearchResult;
