import * as Yup from "yup";
import formModel from "./formModel";
const {
  formField: { commodities },
} = formModel;

export default [
  Yup.object().shape({
    // [segment.name]: Yup.string().required(`${segment.requiredErrorMsg}`),
    // [family.name]: Yup.string().required(`${family.requiredErrorMsg}`),
    // [productClass.name]: Yup.string().required(`${productClass.requiredErrorMsg}`),
    [commodities.name]: Yup.array().min(1, `${commodities.requiredErrorMsg}`),
  }),
];
