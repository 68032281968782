import React, { Fragment, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Rating from "@material-ui/lab/Rating";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { InputLabel } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { FieldArray } from "formik";
import AWS from "aws-sdk";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import bestCostCountryIcon from "assets/img/bestCostCountry.png";
import saSearchIcon from "assets/img/saSearchIcon.svg";
import favoriteIcon from "assets/img/favoriteIcon.svg";
import favoriteIconFilled from "assets/img/favoriteIconFilled.svg";
// import listIcon from "assets/img/listIcon.svg";
import mailIcon from "assets/img/mailIcon.svg";
import messageIcon from "assets/img/messageIcon.svg";
import basicSearchIcon from "assets/img/basicSearchIcon.svg";
import { autoEffect, view } from "@risingstack/react-easy-state";
import { company, favorites, quickscan, contact, rfi } from "stores/BaseStore";
import { useTranslation } from "react-i18next";

import LoaderAbsolute from "components/shared/LoaderAbsolute";
import Loader from "../shared/Loader";
import MainModal from "components/shared/MainModal";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const StyledRating = withStyles({
  root: {
    marginBottom: "11px",
  },
  icon: {
    color: "#C4C4C4",
  },
  iconFilled: {
    color: "#06D7F9",
  },
})(Rating);

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  // h2 {
  //   font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
  //   font-size: 36px;
  //   line-height: 40px;
  //   display: flex;
  //   align-items: center;
  //   text-transform: uppercase;
  //   margin: 0;
  //   margin-bottom: 40px;
  // }
`;

const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
`;

const StyledResultWrapper = styled(Box)`
  position: relative;
  margin-bottom: 56px;
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  transition: all 0.3s ease-in-out;
`;

const StyledLogoWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 26px;

  img {
    height: 104px;
    width: 104px;
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 20px;
  }

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 0 !important;
  }

  h6 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-transform: capitalize;
    color: #b0b0b0;
    margin: 0;
  }
`;

const StyledLogoImageWrapper = styled(Box)`
  width: 154px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 1279px) {
    justify-content: flex-start;
  }
`;

const StyledImageNotFound = styled(BrokenImageIcon)`
  height: 104px;
  width: 104px;
  max-width: 100%;
  object-fit: contain;
  fill: #b0b0b0;
`;

const StyledRatingWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  // margin-bottom: 40px;

  @media (max-width: 1279px) {
    align-items: flex-start;
  }
`;

const StyledLogoLinkWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  img {
    width: 22.5px;
    height: 18.6px;
    margin-right: 6px;
    margin-bottom: 0;
  }

  a {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #000000;
  }

  span {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: ${(props) => (props.ordered ? "none" : "underline")};
    text-transform: capitalize;
    color: ${(props) => (props.ordered ? "#b0b0b0" : "#000000")};
    cursor: ${(props) => (props.ordered ? "default" : "pointer")};
  }
`;

const StyledNameWrapper = styled(Box)`
  h2 {
    font-family: "RobotoBold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-bottom: 12px;
  }

  a {
    display: block;
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #000000;
    margin: 0;
    margin-bottom: 22px;
  }

  span {
    display: block;
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: #000000;
    margin: 0;
    margin-bottom: 22px;
  }
`;

const StyledDescWrapper = styled(Box)`
  margin-bottom: 40px;

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
  }
`;

const StyledProductsWrapper = styled(Box)`
  ul {
    list-style-position: inside;
    padding-left: 0;
    margin: 0;
    min-height: 68px;

    li {
      font-family: "RobotoRegular", "Arial", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #ff490f;

      span {
        color: #000000;
      }
    }
  }
`;

const StyledNoRatingWrapper = styled(Box)`
  min-height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    // width: 80%;
    text-align: center;
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-transform: capitalize;
    margin: 0;
  }

  @media (max-width: 1279px) {
    justify-content: flex-start;

    p {
      text-align: unset;
    }
  }
`;

const StyledCentralWrapperTop = styled(Box)`
  padding-top: 24px;
  padding-right: 55px;
  padding-bottom: 46px;
  padding-left: 55px;
  border-bottom: 1px solid #b0b0b0;

  @media (max-width: 1279px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledButtonDownload = styled(Button)`
  background: #ffffff;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  padding: 10px 0;
  text-transform: none;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00bfe9;
  color: #00bfe9;
  width: 210px;

  &:first-of-type {
    margin-top: 20px;
  }

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledCentralWrapperBottom = styled(Box)`
  padding-top: 46px;
  padding-right: 55px;
  padding-bottom: 46px;
  padding-left: 55px;
  min-height: 300px;

  h3 {
    font-family: "NeueMachinaUltraBold", "Arial", sans-serif;
    font-size: 21px;
    font-weight: normal;
    line-height: 20px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 30px;
  }

  @media (max-width: 1279px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledPhotosWrapper = styled(Box)`
  padding-top: 24px;
  padding-right: 38px;
  padding-bottom: 46px;
  padding-left: 38px;

  h3 {
    font-family: "NeueMachinaUltraBold", "Arial", sans-serif;
    font-size: 21px;
    font-weight: normal;
    line-height: 20px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 30px;

    span {
      font-weight: 700;
    }
  }

  img {
    height: 120px;
    width: 100%;
    max-width: 190px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  @media (max-width: 1279px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledField = styled(Field)`
  background: #ffffff;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  font-family: "RobotoRegular", "Arial", sans-serif;
  transform: none !important;
  display: block;
  font-size: 14px;
  line-height: 93.1%;
  color: #000000;
  margin-bottom: 20px;
`;

const StyledButtonPrimary = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledButtonWithIcon = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  margin-bottom: 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledPlusSign = styled(Box)`
  height: 100%;
  width: 46px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #ffffff;
  }
`;

const StyledRemoveWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding-top: 33px;
`;

const StyledMinusSign = styled(Box)`
  height: 40px;
  width: 40px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: #ffffff;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledButtonText = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`;

const StyledDatePicker = styled(KeyboardDatePicker)`
  background: #ffffff;
  margin: 0;
  margin-bottom: 40px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

const StyledBestCostCountryWrapper = styled(Box)`
  height: 62px;
  width: 95px;
  margin-bottom: 10px;

  img {
    height: 100%;
    width: 100%;
    margin-bottom: 0;
  }
`;

const StyledDetailsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin: 0;
    margin-right: 20px;

    span {
      font-weight: 700;
    }
  }
`;

const srlOptions = {
  caption: {
    showCaption: false,
  },
  buttons: {
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
  },
};

const srlCallbacks = {
  onLightboxOpened: () => {
    document.getElementById("helpBorder").style.opacity = 0;
  },
  onLightboxClosed: () => {
    document.getElementById("helpBorder").style.opacity = 1;
  },
};

const Company = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.up("lg"));
  let params = useParams();
  const [favorite, setFavorite] = useState(false);
  const [quickscanOrderStatus, setQuickscanOrderStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [rfiModalOpen, setRFIModalOpen] = useState(false);
  const { t } = useTranslation();
  const [s3Urls, setS3Urls] = useState(null);
  const [s3Logo, setS3Logo] = useState(null);
  const [s3Presentation, setS3Presentation] = useState(null);
  const [s3Reference, setS3Reference] = useState(null);

  const getS3Data = async (type, hook) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    });

    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Prefix: `media/${company.specificCompany.id}/${type}/`,
    };

    s3.listObjects(params, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        if (data.Contents.length > 0) {
          const content = data.Contents.map((url) => {
            return `${process.env.REACT_APP_S3_ENDPOINT}/${url.Key}`;
          });

          hook(content);
        } else {
          hook([]);
        }
      }
    });
  };

  useEffect(() => {
    if (params.id) {
      company.getSpecificCompany(params.id);
    }
  }, [params.id]);

  autoEffect(async () => {
    if (company.specificCompany) {
      setFavorite(company.specificCompany.is_favourite);
      // setLonglist(company.specificCompany.is_shortlisted);

      // if (
      //   !company.specificCompany.QuickScanOrder ||
      //   !company.specificCompany.QuickScanOrder.status
      // ) {
      //   setQuickscanOrderStatus(false);
      // } else {
      //   setQuickscanOrderStatus(true);
      // }

      await getS3Data("image", setS3Urls);
      await getS3Data("logo", setS3Logo);
      await getS3Data("company-presentation", setS3Presentation);
      await getS3Data("reference-list", setS3Reference);
    }
  });

  const openFilePDF = (path) => {
    const url = path;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleFavorite = async (id) => {
    setLoading(true);
    if (favorite) {
      await favorites.removeFavorite(id);
      if (!favorites.error) {
        setFavorite(false);
      }
      setLoading(false);
    } else {
      await favorites.addFavorite(id);
      if (!favorites.error) {
        setFavorite(true);
      }
      setLoading(false);
    }
  };

  // const handleLonglist = async (id) => {
  //   setLoading(true);
  //   if (longlist) {
  //     // await favorites.removeFavorite(id);
  //     if (!longlist.error) {
  //       setLonglist(false);
  //     }
  //     setLoading(false);
  //   } else {
  //     // await favorites.addFavorite(id);
  //     if (!longlist.error) {
  //       setLonglist(true);
  //     }
  //     setLoading(false);
  //   }
  // };

  const handleOrderQuickscan = async (id) => {
    setLoading(true);
    if (!quickscanOrderStatus) {
      await quickscan.orderQuickscan(id);
      if (!quickscan.error) {
        setQuickscanOrderStatus(true);
      }
      setLoading(false);
    }
  };

  const handleMessageModalOpen = () => {
    contact.sent = false;
    setMessageModalOpen(true);
  };

  const handleMessageModalClose = () => {
    setMessageModalOpen(false);
    setTimeout(() => {
      contact.sent = false;
    }, 500);
  };

  const handleRFIModalOpen = () => {
    rfi.sent = false;
    setRFIModalOpen(true);
  };

  const handleRFIModalClose = () => {
    setRFIModalOpen(false);
    setTimeout(() => {
      rfi.sent = false;
    }, 500);
  };

  const validateMessage = (values) => {
    const errors = {};

    if (!values.message) {
      errors.message = t("message required");
    }

    return errors;
  };

  const validateRFI = (values) => {
    const errors = {};

    if (!values.product_name) {
      errors.product_name = t("product name required");
    }

    if (
      values.quanitity &&
      (!/^\d+$/.test(values.quanitity) || values.quanitity.length > 16)
    ) {
      errors.quanitity = t("quantity must be a number, max 16 characters");
    }

    if (!values.message) {
      errors.message = t("message required");
    }

    return errors;
  };

  return (
    <Fragment>
      {!company.specificCompany ||
      !s3Urls ||
      !s3Logo ||
      !s3Presentation ||
      !s3Reference ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <StyledIntro>
          <StyledIntroWrapper fixed>
            <Grid
              container
              justify={matches ? "space-between" : "flex-start"}
              alignItems={"flex-start"}
            >
              <Grid item xs={12}>
                <MainModal
                  modalOpen={messageModalOpen}
                  handleModalClose={handleMessageModalClose}
                >
                  {contact.loading ? <LoaderAbsolute /> : null}
                  {!contact.loading && contact.sent ? (
                    <p style={{ padding: "4rem 3rem" }}>
                      {t("message sent successfully.")}
                    </p>
                  ) : !contact.loading && contact.errors ? (
                    <p style={{ padding: "4rem 3rem" }}>
                      {t("here's been an error. please try again.")}
                    </p>
                  ) : (
                    <Formik
                      initialValues={{
                        message: "",
                      }}
                      validate={validateMessage}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        contact.sendMessage(company.specificCompany.id, values);
                      }}
                    >
                      {({ submitForm, isSubmitting }) => (
                        <Form>
                          <StyledInputLabel
                            htmlFor="message"
                            shrink={true}
                            variant="outlined"
                            required
                          >
                            {t("message")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="message"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                          />

                          {isSubmitting}
                          <br />
                          <StyledButtonPrimary
                            type="button"
                            disabled={isSubmitting}
                            onClick={submitForm}
                          >
                            {t("send message")}
                          </StyledButtonPrimary>
                        </Form>
                      )}
                    </Formik>
                  )}
                </MainModal>
                <MainModal
                  modalOpen={rfiModalOpen}
                  handleModalClose={handleRFIModalClose}
                >
                  {rfi.loading ? <LoaderAbsolute /> : null}
                  {!rfi.loading && rfi.sent ? (
                    <p style={{ padding: "4rem 3rem" }}>
                      {t("rfq sent successfully")}
                    </p>
                  ) : !rfi.loading && rfi.errors ? (
                    <p style={{ padding: "4rem 3rem" }}>
                      {t("there's been an error. please try again.")}
                    </p>
                  ) : (
                    <Formik
                      initialValues={{
                        product_name: "",
                        manufacturing_process: "",
                        special_requirements: "",
                        location: "",
                        quanitity: "",
                        certificates: "",
                        deadline: null,
                        rfi_deadline: null,
                        supplier_target_countries: "",
                        material: "",
                        application_area: "",
                        keyword: "",
                        type_of_sourcing_item: "",
                        known_supplier_list: [],
                        message: "",
                      }}
                      validate={validateRFI}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        rfi.sendRFI(company.specificCompany.id, values);
                      }}
                    >
                      {({
                        submitForm,
                        isSubmitting,
                        setFieldValue,
                        values,
                      }) => (
                        <Form>
                          <StyledInputLabel
                            htmlFor="product_name"
                            shrink={true}
                            variant="outlined"
                            required
                          >
                            {t("product name")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="product_name"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <StyledInputLabel
                            htmlFor="manufacturing_process"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("manufacturing process")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="manufacturing_process"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <StyledInputLabel
                            htmlFor="special_requirements"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("special requirements")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="special_requirements"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <StyledInputLabel
                            htmlFor="location"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("location")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="location"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <StyledInputLabel
                            htmlFor="quanitity"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("quantity")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="quanitity"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <StyledInputLabel
                            htmlFor="certificates"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("certificates")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="certificates"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <StyledInputLabel
                            htmlFor="deadline"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("delivery deadline")}
                          </StyledInputLabel>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <StyledDatePicker
                              variant="inline"
                              inputVariant="outlined"
                              size="small"
                              fullWidth
                              disableToolbar
                              margin="normal"
                              id="deadline"
                              name="deadline"
                              format="dd/MM/yyyy"
                              minDate={new Date()}
                              maxDate={new Date("2050/12/31")}
                              value={values.deadline}
                              onChange={(date) => {
                                if (date) {
                                  try {
                                    /* const ISODateString = date.toISOString();
                                    const ISOWithoutTime =
                                      ISODateString.split("T"); */

                                    const convertedDate =
                                      moment(date).format("YYYY-MM-DD");

                                    setFieldValue("deadline", convertedDate);
                                  } catch (error) {
                                    setFieldValue("deadline", date);
                                  }
                                } else {
                                  setFieldValue("deadline", date);
                                }
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <StyledInputLabel
                            htmlFor="rfi_deadline"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("rfq deadline")}
                          </StyledInputLabel>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <StyledDatePicker
                              variant="inline"
                              inputVariant="outlined"
                              size="small"
                              fullWidth
                              disableToolbar
                              margin="normal"
                              id="rfi_deadline"
                              name="rfi_deadline"
                              format="dd/MM/yyyy"
                              minDate={new Date()}
                              maxDate={new Date("2050/12/31")}
                              value={values.rfi_deadline}
                              onChange={(date) => {
                                if (date) {
                                  try {
                                    /* const ISODateString = date.toISOString();
                                    const ISOWithoutTime =
                                      ISODateString.split("T"); */

                                    const convertedDate =
                                      moment(date).format("YYYY-MM-DD");

                                    setFieldValue(
                                      "rfi_deadline",
                                      convertedDate
                                    );
                                  } catch (error) {
                                    setFieldValue("rfi_deadline", date);
                                  }
                                } else {
                                  setFieldValue("rfi_deadline", date);
                                }
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <StyledInputLabel
                            htmlFor="supplier_target_countries"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("supplier target countries")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="supplier_target_countries"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <StyledInputLabel
                            htmlFor="material"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("material")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="material"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <StyledInputLabel
                            htmlFor="application_area"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("application area")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="application_area"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <StyledInputLabel
                            htmlFor="keyword"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("keyword")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="keyword"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <StyledInputLabel
                            htmlFor="type_of_sourcing_item"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("type of sourcing item")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="type_of_sourcing_item"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <StyledInputLabel
                            htmlFor="known_supplier_list"
                            shrink={true}
                            variant="outlined"
                          >
                            {t("known supplier list")}{" "}
                            {t("this is not sent to the supplier")})
                          </StyledInputLabel>
                          <FieldArray
                            name="known_supplier_list"
                            render={(arrayHelpers) => (
                              <Fragment>
                                {values.known_supplier_list.map(
                                  (supplier, index) => (
                                    <Fragment key={index}>
                                      <Grid
                                        container
                                        spacing={matches ? 6 : 2}
                                        style={{ margin: 0 }}
                                      >
                                        <Grid
                                          item
                                          xs={10}
                                          lg={10}
                                          style={{ padding: 0 }}
                                        >
                                          {/** both these conventions do the same */}
                                          <StyledInputLabel
                                            htmlFor={`known_supplier_list[${index}].name`}
                                            shrink={true}
                                            variant="outlined"
                                          >
                                            {t("name")}
                                          </StyledInputLabel>
                                          <StyledField
                                            component={TextField}
                                            id={`known_supplier_list[${index}].name`}
                                            name={`known_supplier_list[${index}].name`}
                                            autoComplete="off"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          lg={2}
                                          style={{ padding: 0 }}
                                        >
                                          <StyledRemoveWrapper>
                                            <StyledMinusSign
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <RemoveIcon />
                                            </StyledMinusSign>
                                          </StyledRemoveWrapper>
                                        </Grid>
                                      </Grid>
                                    </Fragment>
                                  )
                                )}

                                <Grid item xs={12}>
                                  <StyledButtonWithIcon
                                    onClick={() =>
                                      arrayHelpers.push({
                                        name: "",
                                      })
                                    }
                                  >
                                    <StyledPlusSign>
                                      <AddIcon />
                                    </StyledPlusSign>
                                    <StyledButtonText>
                                      {t("add supplier")}
                                    </StyledButtonText>
                                  </StyledButtonWithIcon>
                                </Grid>
                              </Fragment>
                            )}
                          />
                          <StyledInputLabel
                            htmlFor="message"
                            shrink={true}
                            variant="outlined"
                            required
                          >
                            {t("message")}
                          </StyledInputLabel>
                          <StyledField
                            component={TextField}
                            name="message"
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                          />

                          {isSubmitting}
                          <br />
                          <StyledButtonPrimary
                            type="button"
                            disabled={isSubmitting}
                            onClick={submitForm}
                          >
                            {t("send rfq")}
                          </StyledButtonPrimary>
                        </Form>
                      )}
                    </Formik>
                  )}
                </MainModal>
                <StyledResultWrapper loading={loading}>
                  {loading ? <LoaderAbsolute /> : null}
                  <Grid container justify="flex-start" alignItems="flex-start">
                    <Grid item xs={12} lg={3}>
                      <StyledLogoWrapper>
                        <StyledLogoImageWrapper>
                          {s3Logo.length < 1 ? (
                            <StyledImageNotFound />
                          ) : (
                            <img src={s3Logo} alt="logo" />
                          )}
                        </StyledLogoImageWrapper>
                        <StyledRatingWrapper>
                          <h6>{t("overall rating")}</h6>
                          {!company.specificCompany.assessment ||
                          !company.specificCompany.assessment.overall_score ||
                          company.specificCompany.assessment.overall_score
                            .original ? (
                            <StyledNoRatingWrapper>
                              <p>{t("not passed self assessment yet")}</p>
                            </StyledNoRatingWrapper>
                          ) : (
                            <Fragment>
                              <h2>
                                {
                                  company.specificCompany.assessment
                                    .overall_score
                                }
                              </h2>
                              <StyledRating
                                name="read-only"
                                value={parseFloat(
                                  company.specificCompany.assessment
                                    .overall_score
                                )}
                                precision={0.1}
                                readOnly
                              />
                            </Fragment>
                          )}
                        </StyledRatingWrapper>
                        {company.specificCompany.Country &&
                        company.specificCompany.Country.best_buy ? (
                          <StyledBestCostCountryWrapper>
                            <img
                              src={bestCostCountryIcon}
                              alt="best cost country"
                            />
                          </StyledBestCostCountryWrapper>
                        ) : (
                          <StyledBestCostCountryWrapper></StyledBestCostCountryWrapper>
                        )}
                        <StyledLogoLinkWrapper>
                          {!favorite ? (
                            <Fragment>
                              <img src={favoriteIcon} alt="favorite" />
                              <span
                                onClick={() =>
                                  handleFavorite(company.specificCompany.id)
                                }
                              >
                                {t("add to my suppliers")}
                              </span>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <img src={favoriteIconFilled} alt="favorite" />
                              <span
                                onClick={() =>
                                  handleFavorite(company.specificCompany.id)
                                }
                              >
                                {t("remove from my suppliers")}
                              </span>
                            </Fragment>
                          )}
                        </StyledLogoLinkWrapper>
                        {/* <StyledLogoLinkWrapper>
                          {!longlist ? (
                            <Fragment>
                              <img src={listIcon} alt="shortlist" />
                              <span
                                onClick={() =>
                                  handleLonglist(company.specificCompany.id)
                                }
                              >
                                Shortlist
                              </span>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <img src={listIcon} alt="shortlist" />
                              <span
                                onClick={() =>
                                  handleLonglist(company.specificCompany.id)
                                }
                              >
                                Remove from shortlist
                              </span>
                            </Fragment>
                          )}
                        </StyledLogoLinkWrapper> */}
                        <StyledLogoLinkWrapper>
                          <img src={mailIcon} alt="mail" />
                          <span onClick={handleRFIModalOpen}>
                            {t("send rfq")}
                          </span>
                        </StyledLogoLinkWrapper>
                        <StyledLogoLinkWrapper>
                          <img src={messageIcon} alt="message" />
                          <span onClick={handleMessageModalOpen}>
                            {t("message")}
                          </span>
                        </StyledLogoLinkWrapper>
                        {!company.specificCompany.assessment ||
                        !company.specificCompany.assessment.overall_score ||
                        company.specificCompany.assessment.overall_score
                          .original ? null : (
                          <StyledLogoLinkWrapper>
                            <img
                              src={saSearchIcon}
                              alt="self assessment search icon"
                            />
                            <Link to={`/reports/${company.specificCompany.id}`}>
                              {t("self assessment")}
                            </Link>
                          </StyledLogoLinkWrapper>
                        )}
                        <Fragment>
                          {!quickscanOrderStatus ? (
                            <StyledLogoLinkWrapper>
                              <img src={basicSearchIcon} alt="favorite" />
                              <span
                                onClick={() =>
                                  handleOrderQuickscan(
                                    company.specificCompany.id
                                  )
                                }
                              >
                                {t("order quickscan")}
                              </span>
                            </StyledLogoLinkWrapper>
                          ) : (
                            <StyledLogoLinkWrapper ordered>
                              <img src={basicSearchIcon} alt="favorite" />
                              <span ordered>{t("quickscan ordered")}</span>
                            </StyledLogoLinkWrapper>
                          )}
                        </Fragment>
                        {s3Reference.length > 0 && (
                          <StyledButtonDownload
                            onClick={() => openFilePDF(s3Reference)}
                          >
                            {t("reference list") + " pdf"}
                          </StyledButtonDownload>
                        )}
                        {s3Presentation.length > 0 && (
                          <StyledButtonDownload
                            onClick={() => openFilePDF(s3Presentation)}
                          >
                            {t("company presentation") + " pdf"}
                          </StyledButtonDownload>
                        )}
                      </StyledLogoWrapper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      style={{
                        borderLeft: matchesLg ? "1px solid #B0B0B0" : "none",
                        borderRight: matchesLg ? "1px solid #B0B0B0" : "none",
                      }}
                    >
                      <StyledCentralWrapperTop>
                        <StyledNameWrapper>
                          <h2>{company.specificCompany.name}</h2>
                          {company.specificCompany.website ? (
                            <a
                              href={company.specificCompany.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {company.specificCompany.website}
                            </a>
                          ) : (
                            <span>{t("no website provided")}</span>
                          )}
                        </StyledNameWrapper>
                        <StyledDescWrapper>
                          <p>
                            {!company.specificCompany.short_description
                              ? t("no description")
                              : company.specificCompany.short_description
                                  .length > 400
                              ? `${company.specificCompany.short_description.substring(
                                  0,
                                  400
                                )}...`
                              : company.specificCompany.short_description}
                          </p>
                        </StyledDescWrapper>
                        <StyledDetailsWrapper>
                          <p>
                            {t("address")}{" "}
                            <span>
                              {company.specificCompany.address
                                ? company.specificCompany.address
                                : t("no data")}
                            </span>
                          </p>
                          <p>
                            {t("country")}{" "}
                            <span>
                              {company.specificCompany.Country &&
                              company.specificCompany.Country.name
                                ? company.specificCompany.Country.name
                                : t("no data")}
                            </span>
                          </p>
                          <p>
                            {t("city")}{" "}
                            <span>
                              {company.specificCompany.City &&
                              company.specificCompany.City.name
                                ? company.specificCompany.City.name
                                : t("no data")}
                            </span>
                          </p>
                        </StyledDetailsWrapper>
                        <StyledDetailsWrapper>
                          <p>
                            {t("employees")}{" "}
                            <span>
                              {company.specificCompany.number_of_employees || 0}
                            </span>
                          </p>
                          <p>
                            {t("turnover")}{" "}
                            <span>
                              $ {company.specificCompany.turnover || 0}
                            </span>
                          </p>
                          <p>
                            {t("iso")} 9001:2015{" "}
                            {company.specificCompany.Certificates &&
                            company.specificCompany.Certificates.length > 0 &&
                            company.specificCompany.Certificates.find(
                              (certificate) => {
                                return (
                                  certificate.name === "iso" &&
                                  certificate.number === "9001:2015"
                                );
                              }
                            ) ? (
                              <span>{t("yes")}</span>
                            ) : (
                              <span>{t("no")}</span>
                            )}
                          </p>
                        </StyledDetailsWrapper>
                      </StyledCentralWrapperTop>
                      <StyledCentralWrapperBottom>
                        <h3>{t("products")}</h3>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <StyledProductsWrapper>
                              <ul>
                                {!company.specificCompany.Categories ||
                                company.specificCompany.Categories.length <
                                  1 ? (
                                  <li>
                                    <span>{t("no products")}</span>
                                  </li>
                                ) : (
                                  company.specificCompany.Categories.map(
                                    (category, index) => {
                                      if (
                                        index <
                                        company.specificCompany.Categories
                                          .length /
                                          2
                                      ) {
                                        return (
                                          <li key={category.id}>
                                            <span>
                                              {category.commodity_name}
                                            </span>
                                          </li>
                                        );
                                      }
                                      return null;
                                    }
                                  )
                                )}
                              </ul>
                            </StyledProductsWrapper>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <StyledProductsWrapper>
                              <ul>
                                {!company.specificCompany.Categories ||
                                company.specificCompany.Categories.length < 1
                                  ? null
                                  : company.specificCompany.Categories.map(
                                      (category, index) => {
                                        if (
                                          index >=
                                          company.specificCompany.Categories
                                            .length /
                                            2
                                        ) {
                                          return (
                                            <li key={category.id}>
                                              <span>
                                                {category.commodity_name}
                                              </span>
                                            </li>
                                          );
                                        }
                                        return null;
                                      }
                                    )}
                              </ul>
                            </StyledProductsWrapper>
                          </Grid>
                        </Grid>
                      </StyledCentralWrapperBottom>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <StyledPhotosWrapper>
                        <h3>{t("company photos")}</h3>
                        {s3Urls && s3Urls.length > 3 ? (
                          <p>{t("open gallery to see all photos")}</p>
                        ) : (
                          <p></p>
                        )}
                        <SimpleReactLightbox>
                          <SRLWrapper
                            options={srlOptions}
                            callbacks={srlCallbacks}
                          >
                            {!s3Urls || s3Urls.length < 1
                              ? null
                              : s3Urls.map((image, index) => {
                                  if (index < 3) {
                                    return (
                                      <Box
                                        key={image}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <img src={image} alt="company" />
                                      </Box>
                                    );
                                  } else {
                                    return (
                                      <Box
                                        key={image}
                                        style={{ display: "none" }}
                                      >
                                        <img src={image} alt="company" />
                                      </Box>
                                    );
                                  }
                                })}
                          </SRLWrapper>
                        </SimpleReactLightbox>
                        {s3Urls && s3Urls.length > 3 ? (
                          <p>
                            + <span>{s3Urls.length - 3}</span>{" "}
                            {s3Urls.length - 3 === 1
                              ? t("more photo")
                              : t("more photos")}
                          </p>
                        ) : null}
                      </StyledPhotosWrapper>
                    </Grid>
                  </Grid>
                </StyledResultWrapper>
              </Grid>
            </Grid>
          </StyledIntroWrapper>
        </StyledIntro>
      )}
    </Fragment>
  );
});

export default Company;
