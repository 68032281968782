import formModel from "./formModel";
const {
  formField: {
    customer_rating,
    facility_clean,
    visual_labeling,
    everything_stored,
    operational_goals,
    materials_stored,
    work_instructions,
    updated_charts,
    current_state,
    production_lines,
    material_moved,
    continuous_flow,
    trained_teams,
    commited_employes,
    timetable,
    project_managment,
    supplier_certification,
    product_characteristics,
  },
} = formModel;

export default {
  [customer_rating.name]: "no answer",
  [facility_clean.name]: "no answer",
  [visual_labeling.name]: "no answer",
  [everything_stored.name]: "no answer",
  [operational_goals.name]: "no answer",
  [materials_stored.name]: "no answer",
  [work_instructions.name]: "no answer",
  [updated_charts.name]: "no answer",
  [current_state.name]: "no answer",
  [production_lines.name]: "no answer",
  [material_moved.name]: "no answer",
  [continuous_flow.name]: "no answer",
  [trained_teams.name]: "no answer",
  [commited_employes.name]: "no answer",
  [timetable.name]: "no answer",
  [project_managment.name]: "no answer",
  [supplier_certification.name]: "no answer",
  [product_characteristics.name]: "no answer",
};
