import React, { Fragment, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import { Box, Container } from "@material-ui/core";
import styled from "styled-components";
import { autoEffect, view } from "@risingstack/react-easy-state";
import { auth, countries } from "stores/BaseStore";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import Loader from "components/shared/Loader";

import MainForm from "./userProfile/FormPage/Forms/MainForm";
import validationSchema from "./userProfile/FormPage/FormModel/validationSchema";
import formModel from "./userProfile/FormPage/FormModel/formModel";
import formInitialValues from "./userProfile/FormPage/FormModel/formInitialValues";

import PasswordForm from "./password/FormPage/Forms/PasswordForm";
import passwordValidationSchema from "./password/FormPage/FormModel/validationSchema";
import passwordFormModel from "./password/FormPage/FormModel/formModel";
import passwordFormInitialValues from "./password/FormPage/FormModel/formInitialValues";

import useStyles from "./userProfile/FormPage/styles";

const { formId, formField } = formModel;
const { passwordFormId, passwordFormField } = passwordFormModel;

const StyledContainer = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 21px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 20px;
  }
`;

const StyledFormWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 80px;
`;

const StyledButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const UserProfile = view((props) => {
  const classes = useStyles();
  const currentValidationSchema = validationSchema[0];
  const currentPasswordValidationSchema = passwordValidationSchema[0];
  const [initialValues, setInitialValues] = useState(null);
  const { t } = useTranslation();

  autoEffect(async () => {
    // if (!company.company) {
    //   // await company.getCompany();
    //   // await cities.getCities();
    // }

    if (!countries.countries) {
      await countries.getCountries();
    }

    if (!auth.user) {
      await auth.getUser();
    }

    if (auth.user) {
      setInitialValues({
        name: auth.user.attributes.name || "",
        email: auth.user.attributes.email || "",
        "custom:profile_photo":
          auth.user.attributes["custom:profile_photo"] || "",
        "custom:mobile_phone":
          auth.user.attributes["custom:mobile_phone"] || "",
        "custom:country": auth.user.attributes["custom:country"] || "",
        "custom:zip": auth.user.attributes["custom:zip"] || "",
        "custom:job_function":
          auth.user.attributes["custom:job_function"] || "",
        "custom:job_level": auth.user.attributes["custom:job_level"] || "",
        "custom:discipline": auth.user.attributes["custom:discipline"] || "",
        "custom:industry": auth.user.attributes["custom:industry"] || "",
        "custom:company_name":
          auth.user.attributes["custom:company_name"] || "",
        address: auth.user.attributes["address"] || "",
        "custom:city": auth.user.attributes["custom:city"] || "",
      });
    } else {
      setInitialValues(formInitialValues);
    }
  });

  async function _submitForm(values, actions) {
    auth.updateUser(values);
    actions.setSubmitting(false);
  }

  async function _submitPasswordForm(values, actions) {
    auth.changePassword(values);
    actions.setSubmitting(false);
  }

  async function _handleSubmit(values, actions) {
    _submitForm(values, actions);
  }

  async function _handlePasswordSubmit(values, actions) {
    _submitPasswordForm(values, actions);
  }

  autoEffect(() => {
    if (auth.errors || auth.passwordErrors) {
      props.enqueueSnackbar(t("something went wrong"), {
        variant: "error",
      });
    }

    if (auth.updateSuccess || auth.passwordUpdateSuccess) {
      props.enqueueSnackbar(t("update successful"), {
        variant: "success",
      });
    }
  });

  return !initialValues ||
    !countries.countries ||
    !auth.user ||
    auth.loading ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <Fragment>
      <StyledContainer>
        <StyledFormWrapper fixed>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form id={formId}>
                <MainForm
                  formField={formField}
                  values={values}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  countries={countries.countries}
                />
                <div>
                  <StyledButton disabled={isSubmitting} type="submit">
                    {t("save changes")}
                  </StyledButton>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </StyledFormWrapper>
        <StyledFormWrapper fixed>
          <Formik
            initialValues={passwordFormInitialValues}
            validationSchema={currentPasswordValidationSchema}
            onSubmit={_handlePasswordSubmit}
          >
            {({ isSubmitting, values, validateField, resetForm }) => (
              <Form id={passwordFormId}>
                <PasswordForm
                  formField={passwordFormField}
                  values={values}
                  initialValues={initialValues}
                  validateField={validateField}
                  resetForm={resetForm}
                />
                <div>
                  <StyledButton disabled={isSubmitting} type="submit">
                    {t("save changes")}
                  </StyledButton>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </StyledFormWrapper>
      </StyledContainer>
    </Fragment>
  );
});

export default withSnackbar(UserProfile);
