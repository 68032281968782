import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const StyledWrapper = styled.div`
  position: absolute;
  // min-height: 100vh;
  // min-width: 100vw;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  // background-color: rgba(0, 0, 0, 0.5);
  // opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledProgress = styled(CircularProgress)`
  color: #06d7f9;
`;

const LoaderAbsolute = (props) => {
  return (
    <StyledWrapper
      style={props ? { backgroundColor: props.bg, zIndex: 9999 } : null}
    >
      <StyledProgress />
    </StyledWrapper>
  );
};

export default LoaderAbsolute;
