import React from "react";
import PropTypes from "prop-types";
import { at } from "lodash";
import { useField } from "formik";
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const StyledSelect = styled(FormControl)`
  margin-bottom: 30px;

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
  }

  .MuiInputBase-input {
    background: #ffffff;
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -24px;
  }
`;

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      // borderColor: "green",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      // borderColor: "red",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#06D7F9",
    },
    "& .MuiOutlinedInput-input": {
      // color: "green",
    },
    "&:hover .MuiOutlinedInput-input": {
      // color: "red",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#06D7F9",
    },
    "& .MuiInputLabel-outlined": {
      // color: "green",
    },
    "&:hover .MuiInputLabel-outlined": {
      // color: "red",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#06D7F9",
    },
  },
});

function SelectField(props) {
  const classes = useStyles();
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <StyledSelect {...rest} error={isError} className={classes.root}>
      <InputLabel>{label}</InputLabel>
      <Select {...field} value={selectedValue ? selectedValue : ""}>
        {data.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </StyledSelect>
  );
}

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;
