import React, { Fragment, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { autoEffect, view } from "@risingstack/react-easy-state";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Chart from "react-apexcharts";
import moment from "moment";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { company, quickscan, report } from "stores/BaseStore";
import formModel from "components/supplier/quickscan/FormPage/FormModel/formModel";
import Loader from "components/shared/Loader";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 10px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
  }

  h4 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 22px;
    margin: 0;
    margin-bottom: 10px;
  }

  h5 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
  }

  h6 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 40px;
  }
`;

const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledScoreWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 24px;
    line-height: 40px;
    text-align: right;
    text-transform: uppercase;
    // color: #06d7f9;
    color: ${(props) =>
      props.value === "0" ||
      props.value === 0 ||
      props.value === null ||
      props.value === ""
        ? "#000000"
        : props.value <= 1.5
        ? "#FF0000"
        : props.value >= 1.5 && props.value <= 2.5
        ? "#FF7B51"
        : props.value >= 2.5 && props.value <= 3.5
        ? "#FFC527"
        : props.value >= 3.5 && props.value <= 4.5
        ? "#06D7F9"
        : props.value >= 4.5 && props.value <= 5
        ? "#01E7AA"
        : "#000000"};
    margin: 0;
    margin-bottom: 8px;
  }

  h5 {
    font-family: "RobotoMedium", "Arial", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    text-align: right;
    color: #000000;
    margin: 0;
    margin-bottom: 8px;
  }

  @media (max-width: 1279px) {
    align-items: flex-start;

    h3,
    h5 {
      text-align: unset;
    }
  }
`;

const StyledMainWrapper = styled(Box)`
  border-top: 1px solid #dddddd;
  padding-bottom: 40px;
`;

const StyledGraphWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  border-right: 1px solid #dddddd;
  padding-top: 60px;
`;

const StyledGroupScoresWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  padding: 60px 34px;
`;

const StyledGroupScoreInner = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 3px solid ${(props) => props.background};
  padding-bottom: ${(props) => (props.last ? 0 : "36px")};
`;

const StyledGroupScoreBar = styled(Box)`
  padding: 3px 15px;
  width: ${(props) => props.width};
  background: ${(props) => props.background};
  margin-right: 33px;

  span {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 39px;
    color: #ffffff;
    margin: 0;
  }
`;

const StyledGroupScoreInfo = styled(Box)`
  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin: 0;
    margin-bottom: 3px;
  }
`;

const StyledValuesWrapper = styled(Box)`
  height: 100%;
  width: 100%;
`;

const StyledValueWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;
  // height: 112px;
  height: 150px;
  border-right: 3px solid
    ${(props) =>
      props.value === "0" ||
      props.value === 0 ||
      props.value === null ||
      props.value === ""
        ? "#000000"
        : props.value === "1"
        ? "#FF0000"
        : props.value === "2"
        ? "#FF7B51"
        : props.value === "3"
        ? "#FFC527"
        : props.value === "4"
        ? "#06D7F9"
        : props.value === "5"
        ? "#01E7AA"
        : "#000000"};

  @media (max-width: 559px) {
    flex-wrap: wrap;
    min-height: 112px;
    height: auto;
  }
`;

const StyledValueLabelWrapper = styled(Box)`
  background-color: #f9f9f9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 14px;
  width: 100%;
  height: 100%;

  span {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }
`;

const StyledValueLabelInner = styled(Box)`
  position: relative;
`;

const StyledValueAnswerWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  width: 53px;
  color: ${(props) =>
    props.value === "0" ||
    props.value === 0 ||
    props.value === null ||
    props.value === ""
      ? "#000000"
      : props.value === "1"
      ? "#FF0000"
      : props.value === "2"
      ? "#FF7B51"
      : props.value === "3"
      ? "#FFC527"
      : props.value === "4"
      ? "#06D7F9"
      : props.value === "5"
      ? "#01E7AA"
      : "#000000"};

  span {
    font-family: "RobotoMedium", "Arial", sans-serif;
    font-size: 14px;
    line-height: 39px;
    // color: #000000;
  }
`;

const StyledButton = styled(Button)`
  background: #ffffff;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  padding: 10px 0;
  text-transform: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00bfe9;
  color: #00bfe9;
  width: 210px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledScoreScale = styled(Box)`
  margin-bottom: 30px;

  h3 {
    margin-bottom: 20px;
  }

  p {
    margin: 0;
    margin-bottom: 5px;
  }
`;

const StyledValuesHeadline = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 16px 10px;
`;

const SpecificReport = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [supplierPotentialData, setSupplierPotentialData] = useState(null);
  const [supplierPotentialNames, setSupplierPotentialNames] = useState(null);
  const [productionAbilityData, setProductionAbilityData] = useState(null);
  const [productionAbilityNames, setProductionAbilityNames] = useState(null);
  const [values, setValues] = useState(null);
  let params = useParams();
  const { t } = useTranslation();

  autoEffect(async () => {
    if (params.id) {
      const { id } = params;
      await company.getSpecificCompany(id);
      await report.getSpecificReport(id);
      await quickscan.getSpecificQuickscan(id);
    }

    if (!quickscan.specificQuickscan) {
      return;
    }

    const filteredSupplierPotentialObj = {
      organisation: parseInt(quickscan.specificQuickscan.organisation) || 0,
      financial: parseInt(quickscan.specificQuickscan.financial) || 0,
      system_audit: parseInt(quickscan.specificQuickscan.system_audit) || 0,
      process: parseInt(quickscan.specificQuickscan.process) || 0,
      location: parseInt(quickscan.specificQuickscan.location) || 0,
      line_stop_concept:
        parseInt(quickscan.specificQuickscan.line_stop_concept) || 0,
      capacity_planning:
        parseInt(quickscan.specificQuickscan.capacity_planning) || 0,
      economical: 0,
      product_competence:
        parseInt(quickscan.specificQuickscan.product_competence) || 0,
      development_competence:
        parseInt(quickscan.specificQuickscan.development_competence) || 0,
      productivity: parseInt(quickscan.specificQuickscan.productivity) || 0,
      control: parseInt(quickscan.specificQuickscan.control) || 0,
    };

    const filteredSupplierPotentialArray = Object.keys(
      filteredSupplierPotentialObj
    ).map((val) => filteredSupplierPotentialObj[val]);

    setSupplierPotentialData(filteredSupplierPotentialArray);

    const spZeroValues = Object.fromEntries(
      Object.entries(filteredSupplierPotentialObj).filter(([key, value]) => {
        return value === 0 || value === "0" || value === "";
      })
    );

    const spOneValues = Object.fromEntries(
      Object.entries(filteredSupplierPotentialObj).filter(([key, value]) => {
        return value === 1 || value === "1";
      })
    );
    const spTwoValues = Object.fromEntries(
      Object.entries(filteredSupplierPotentialObj).filter(([key, value]) => {
        return value === 2 || value === "2";
      })
    );
    const spThreeValues = Object.fromEntries(
      Object.entries(filteredSupplierPotentialObj).filter(([key, value]) => {
        return value === 3 || value === "3";
      })
    );
    const spFourValues = Object.fromEntries(
      Object.entries(filteredSupplierPotentialObj).filter(([key, value]) => {
        return value === 4 || value === "4";
      })
    );
    const spFiveValues = Object.fromEntries(
      Object.entries(filteredSupplierPotentialObj).filter(([key, value]) => {
        return value === 5 || value === "5";
      })
    );

    const allPotentialSupplierValues = {
      0: Object.keys(spZeroValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
      1: Object.keys(spOneValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
      2: Object.keys(spTwoValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
      3: Object.keys(spThreeValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
      4: Object.keys(spFourValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
      5: Object.keys(spFiveValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
    };

    setSupplierPotentialNames(allPotentialSupplierValues);

    ////

    const filteredProductionAbilityObj = {
      cycle: parseInt(quickscan.specificQuickscan.cycle) || 0,
      deviations: parseInt(quickscan.specificQuickscan.deviations) || 0,
      factory_design: parseInt(quickscan.specificQuickscan.factory_design) || 0,
      flow: parseInt(quickscan.specificQuickscan.flow) || 0,
      inventory: parseInt(quickscan.specificQuickscan.inventory) || 0,
      line_design: parseInt(quickscan.specificQuickscan.line_design) || 0,
      machine_design: parseInt(quickscan.specificQuickscan.machine_design) || 0,
      problem_solving:
        parseInt(quickscan.specificQuickscan.problem_solving) || 0,
      pull: parseInt(quickscan.specificQuickscan.pull) || 0,
      s5: parseInt(quickscan.specificQuickscan.s5) || 0,
      schedule: parseInt(quickscan.specificQuickscan.schedule) || 0,
      standardized_work:
        parseInt(quickscan.specificQuickscan.standardized_work) || 0,
      tpm: parseInt(quickscan.specificQuickscan.tpm) || 0,
      visual_management:
        parseInt(quickscan.specificQuickscan.visual_management) || 0,
    };

    const filteredProductionAbilityArray = Object.keys(
      filteredProductionAbilityObj
    ).map((val) => filteredProductionAbilityObj[val]);

    setProductionAbilityData(filteredProductionAbilityArray);

    const paZeroValues = Object.fromEntries(
      Object.entries(filteredProductionAbilityObj).filter(([key, value]) => {
        return value === 0 || value === "0" || value === "";
      })
    );

    const paOneValues = Object.fromEntries(
      Object.entries(filteredProductionAbilityObj).filter(([key, value]) => {
        return value === 1 || value === "1";
      })
    );
    const paTwoValues = Object.fromEntries(
      Object.entries(filteredProductionAbilityObj).filter(([key, value]) => {
        return value === 2 || value === "2";
      })
    );
    const paThreeValues = Object.fromEntries(
      Object.entries(filteredProductionAbilityObj).filter(([key, value]) => {
        return value === 3 || value === "3";
      })
    );
    const paFourValues = Object.fromEntries(
      Object.entries(filteredProductionAbilityObj).filter(([key, value]) => {
        return value === 4 || value === "4";
      })
    );
    const paFiveValues = Object.fromEntries(
      Object.entries(filteredProductionAbilityObj).filter(([key, value]) => {
        return value === 5 || value === "5";
      })
    );

    const allProductionAbilityValues = {
      0: Object.keys(paZeroValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
      1: Object.keys(paOneValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
      2: Object.keys(paTwoValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
      3: Object.keys(paThreeValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
      4: Object.keys(paFourValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
      5: Object.keys(paFiveValues).map((value) => {
        return formModel.formField[value]
          ? formModel.formField[value].label
          : null;
      }),
    };

    setProductionAbilityNames(allProductionAbilityValues);

    let questions = [];

    Object.entries(quickscan.specificQuickscan).map(([key, value]) => {
      if (formModel.formField[key]) {
        questions.push({
          name: key,
          value: value === null || value === "" ? 0 : value,
          label: formModel.formField[key].label,
          desc:
            value === null || value === ""
              ? t("no answer")
              : formModel.formField[key].values[value],
        });
      }
      return value;
    });

    setValues(questions);
  });

  const supplierPotentialChart = {
    series: [
      {
        name: t("supplier potential"),
        data: supplierPotentialData ? supplierPotentialData : [],
      },
      {
        name: t("supplier potential minimum values"),
        data: [3, 3, 3, 2, 3, 0, 3, 0, 2, 2, 2, 2],
      },
    ],
    options: {
      chart: {
        height: 450,
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [
          t("organisation-strategy-management-staff-members"),
          t("financial assessment"),
          t("system audit"),
          t("process product quality"),
          t("location-environment"),
          t("line stop concept"),
          t("medium-long term capacity planning"),
          t("economical characteristics"),
          t("pre material and product competence"),
          t("process development competence"),
          t("cost structure location costs"),
          t("cost recording-calculation control"),
        ],
        labels: {
          show: true,
          style: {
            fontSize: "12px",
            fontFamily: '"RobotoRegular", "Arial", sans-serif',
            colors: [
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
            ],
          },
          formatter: (value) => {
            var len = value.length;
            return len > 15 ? value.substring(0, 12) + "..." : value;
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: '"RobotoRegular", "Arial", sans-serif',
            colors: ["#000000"],
          },
        },
      },
      tooltip: {
        enabled: true,
        formatter: (value) => {
          return value;
        },
        x: {
          formatter: (value) => {
            return value;
          },
        },
        marker: {
          show: false,
        },
      },
      fill: {
        opacity: 0,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["#000000", "transparent"],
        dashArray: 0,
      },
      markers: {
        colors: ["#06d7f9", "#dddddd"],
        size: 5.5,
        hover: {
          size: 10,
        },
      },
      legend: {
        markers: {
          fillColors: ["#06d7f9", "#dddddd"],
        },
      },
    },
  };

  const productionAbilityChart = {
    series: [
      {
        name: t("production ability"),
        data: productionAbilityData ? productionAbilityData : [],
      },
      {
        name: t("production ability minimum values"),
        data: [1, 3, 1, 2, 1, 1, 1, 2, 1, 3, 2, 2, 1, 3],
      },
    ],
    options: {
      chart: {
        height: 450,
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [
          t("cycle time"),
          t("recognition variances/deviations/poka yoke"),
          t("factory design"),
          t("continuous flow"),
          t("inventory i.e stock-management"),
          t("line design"),
          t("machine design"),
          t("problem solving"),
          t("pull-system"),
          t("5s-sort-set in order-shine-standardize-sustain"),
          t("level schedule/synchronized processes"),
          t("standardized work"),
          t("tpm-total productive maintenance"),
          t("visual management"),
        ],
        labels: {
          show: true,
          style: {
            fontSize: "12px",
            fontFamily: '"RobotoRegular", "Arial", sans-serif',
            colors: [
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
              "#000000",
            ],
          },
          formatter: (value) => {
            var len = value.length;
            return len > 15 ? value.substring(0, 12) + "..." : value;
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: '"RobotoRegular", "Arial", sans-serif',
            colors: ["#000000"],
          },
        },
      },
      tooltip: {
        enabled: true,
        formatter: (value) => {
          return value;
        },
        x: {
          formatter: (value) => {
            return value;
          },
        },
        marker: {
          show: false,
        },
      },
      fill: {
        opacity: 0,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["#000000", "transparent"],
        dashArray: 0,
      },
      markers: {
        colors: ["#06d7f9", "#dddddd"],
        size: 5.5,
        hover: {
          size: 10,
        },
      },
      legend: {
        markers: {
          fillColors: ["#06d7f9", "#dddddd"],
        },
      },
    },
  };

  return (
    <Fragment>
      {!quickscan.specificQuickscan && !quickscan.loading ? (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
                style={{ marginBottom: "40px" }}
              >
                <Grid item xs={12}>
                  <h3>
                    {t(
                      "not enough data, please answer at least 80% of quickscan questions"
                    )}
                  </h3>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
        </Fragment>
      ) : !company.specificCompany ||
        company.loading ||
        !report.specificReport ||
        report.loading ||
        !quickscan.specificQuickscan ||
        quickscan.loading ||
        !supplierPotentialData ||
        !supplierPotentialNames ||
        !productionAbilityData ||
        !productionAbilityNames ||
        !values ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : report.specificReport.physical_score.original ? (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"center"}
                style={{ marginBottom: "40px" }}
              >
                <Grid item xs={12}>
                  <h3>
                    {t(
                      "not enough data, please answer at least 80% of quickscan questions"
                    )}
                  </h3>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro id="singlePage">
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
                style={{ marginBottom: "40px" }}
              >
                <Grid item xs={12} lg={5}>
                  <h6>{t("quality, reliability and transparency")}</h6>
                  <h2>{t("view quickscan")}</h2>
                  <h4>{company.specificCompany.name}</h4>
                  <h5>{moment().format("MMMM Do YYYY")}</h5>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <StyledScoreWrapper
                    value={
                      report.specificReport &&
                      report.specificReport.overall_score
                        ? report.specificReport.overall_score
                        : 0
                    }
                  >
                    <h5>{t("self quickscan")}</h5>
                    <h3>
                      {t("score")}:{" "}
                      {report.specificReport.overall_score
                        ? report.specificReport.overall_score
                        : 0}
                      /5.0
                    </h3>
                    <StyledButton
                      id="downloadButton"
                      onClick={() => {
                        const input = document.getElementById("singlePage");

                        html2canvas(input, {
                          width: 1855,
                          windowWidth: 1920,
                          scale: 1,
                          ignoreElements: (element) => {
                            return (
                              element.id === "downloadButton" ||
                              element.nodeName === "IFRAME"
                            );
                          },
                          /* onclone: (doc) => {
                            // onclone logic to resize div
                            doc.getElementById("singlePage").style.width =
                              "1855";
                            doc.getElementById("singlePage").style.height =
                              "2220";

                            return new Promise((resolve, reject) => {
                              setTimeout(() => {
                                resolve();
                              }, 400);
                            });
                          }, */
                        }).then((canvas) => {
                          const imgData = canvas.toDataURL("image/png");
                          const imgWidth = 1855;

                          const imgHeight =
                            (canvas.height * imgWidth) / canvas.width;

                          /* const imgHeight = 3000; */

                          const pdf = new jsPDF({
                            orientation: "p",
                            unit: "px",
                            format: [imgWidth, imgHeight],
                            hotfixes: ["px_scaling"],
                          });
                          pdf.addImage(
                            imgData,
                            "PNG",
                            matches ? 0 : 0,
                            0,
                            imgWidth,
                            imgHeight,
                            undefined,
                            'FAST'
                          );
                          pdf.save("report.pdf");
                        });
                      }}
                    >
                      {t("download pdf")}
                    </StyledButton>
                  </StyledScoreWrapper>
                </Grid>
              </Grid>
              <StyledMainWrapper>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <StyledGraphWrapper>
                      <Chart
                        options={supplierPotentialChart.options}
                        series={supplierPotentialChart.series}
                        type="radar"
                        height={450}
                      />
                    </StyledGraphWrapper>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <StyledGroupScoresWrapper>
                      <StyledGroupScoreInner background="#01E7AA">
                        <StyledGroupScoreBar background="#01E7AA" width="160px">
                          <span>5</span>
                        </StyledGroupScoreBar>
                        <StyledGroupScoreInfo>
                          {supplierPotentialNames[5].map((value) => {
                            return <p key={value}>{value}</p>;
                          })}
                        </StyledGroupScoreInfo>
                      </StyledGroupScoreInner>
                      <StyledGroupScoreInner background="#06D7F9">
                        <StyledGroupScoreBar background="#06D7F9" width="130px">
                          <span>4</span>
                        </StyledGroupScoreBar>
                        <StyledGroupScoreInfo>
                          {supplierPotentialNames[4].map((value) => {
                            return <p key={value}>{value}</p>;
                          })}
                        </StyledGroupScoreInfo>
                      </StyledGroupScoreInner>
                      <StyledGroupScoreInner background="#FFC527">
                        <StyledGroupScoreBar background="#FFC527" width="100px">
                          <span>3</span>
                        </StyledGroupScoreBar>
                        <StyledGroupScoreInfo>
                          {supplierPotentialNames[3].map((value) => {
                            return <p key={value}>{value}</p>;
                          })}
                        </StyledGroupScoreInfo>
                      </StyledGroupScoreInner>
                      <StyledGroupScoreInner background="#FF7B51">
                        <StyledGroupScoreBar background="#FF7B51" width="70px">
                          <span>2</span>
                        </StyledGroupScoreBar>
                        <StyledGroupScoreInfo>
                          {supplierPotentialNames[2].map((value) => {
                            return <p key={value}>{value}</p>;
                          })}
                        </StyledGroupScoreInfo>
                      </StyledGroupScoreInner>
                      <StyledGroupScoreInner background="none" last="true">
                        <StyledGroupScoreBar background="#FF0000" width="43px">
                          <span>1</span>
                        </StyledGroupScoreBar>
                        <StyledGroupScoreInfo>
                          {supplierPotentialNames[1].map((value) => {
                            return <p key={value}>{value}</p>;
                          })}
                        </StyledGroupScoreInfo>
                      </StyledGroupScoreInner>
                    </StyledGroupScoresWrapper>
                  </Grid>
                </Grid>
              </StyledMainWrapper>
              <StyledMainWrapper>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <StyledGraphWrapper>
                      <Chart
                        options={productionAbilityChart.options}
                        series={productionAbilityChart.series}
                        type="radar"
                        height={450}
                      />
                    </StyledGraphWrapper>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <StyledGroupScoresWrapper>
                      <StyledGroupScoreInner background="#01E7AA">
                        <StyledGroupScoreBar background="#01E7AA" width="160px">
                          <span>5</span>
                        </StyledGroupScoreBar>
                        <StyledGroupScoreInfo>
                          {productionAbilityNames[5].map((value) => {
                            return <p key={value}>{value}</p>;
                          })}
                        </StyledGroupScoreInfo>
                      </StyledGroupScoreInner>
                      <StyledGroupScoreInner background="#06D7F9">
                        <StyledGroupScoreBar background="#06D7F9" width="130px">
                          <span>4</span>
                        </StyledGroupScoreBar>
                        <StyledGroupScoreInfo>
                          {productionAbilityNames[4].map((value) => {
                            return <p key={value}>{value}</p>;
                          })}
                        </StyledGroupScoreInfo>
                      </StyledGroupScoreInner>
                      <StyledGroupScoreInner background="#FFC527">
                        <StyledGroupScoreBar background="#FFC527" width="100px">
                          <span>3</span>
                        </StyledGroupScoreBar>
                        <StyledGroupScoreInfo>
                          {productionAbilityNames[3].map((value) => {
                            return <p key={value}>{value}</p>;
                          })}
                        </StyledGroupScoreInfo>
                      </StyledGroupScoreInner>
                      <StyledGroupScoreInner background="#FF7B51">
                        <StyledGroupScoreBar background="#FF7B51" width="70px">
                          <span>2</span>
                        </StyledGroupScoreBar>
                        <StyledGroupScoreInfo>
                          {productionAbilityNames[2].map((value) => {
                            return <p key={value}>{value}</p>;
                          })}
                        </StyledGroupScoreInfo>
                      </StyledGroupScoreInner>
                      <StyledGroupScoreInner background="none" last="true">
                        <StyledGroupScoreBar background="#FF0000" width="43px">
                          <span>1</span>
                        </StyledGroupScoreBar>
                        <StyledGroupScoreInfo>
                          {productionAbilityNames[1].map((value) => {
                            return <p key={value}>{value}</p>;
                          })}
                        </StyledGroupScoreInfo>
                      </StyledGroupScoreInner>
                    </StyledGroupScoresWrapper>
                  </Grid>
                </Grid>
              </StyledMainWrapper>
              <StyledMainWrapper style={{ paddingTop: "50px" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <StyledScoreScale>
                      <h3>{t("scale of scoring")}</h3>
                      <p>
                        <span style={{ color: "#FF0000" }}>1 - Poor</span>
                      </p>
                      <p>
                        <span style={{ color: "#FF7B51" }}>2 - Fair</span>
                      </p>
                      <p>
                        <span style={{ color: "#FFC527" }}>3 - Good</span>
                      </p>
                      <p>
                        <span style={{ color: "#06D7F9" }}>4 - Very good</span>
                      </p>
                      <p>
                        <span style={{ color: "#01E7AA" }}>5 - Excellent</span>
                      </p>
                    </StyledScoreScale>
                  </Grid>
                </Grid>
                <Grid container spacing={10}>
                  <Grid item xs={12} lg={6}>
                    <StyledValuesWrapper>
                      <StyledValuesHeadline>{t("score")}</StyledValuesHeadline>
                      {values.map((value, index) => {
                        if (index < 13) {
                          return (
                            <StyledValueWrapper
                              key={value.name}
                              value={value.value}
                            >
                              <StyledValueLabelWrapper>
                                <StyledValueLabelInner>
                                  <p
                                    style={{
                                      marginBottom: "10px",
                                      color: "#06D7F9",
                                    }}
                                  >
                                    {index + 1}. {value.label}
                                  </p>
                                  <p style={{ marginBottom: 0 }}>
                                    {value.desc}
                                  </p>
                                </StyledValueLabelInner>
                              </StyledValueLabelWrapper>
                              <StyledValueAnswerWrapper value={value.value}>
                                {value.value}
                              </StyledValueAnswerWrapper>
                            </StyledValueWrapper>
                          );
                        }
                        return null;
                      })}
                    </StyledValuesWrapper>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <StyledValuesWrapper>
                      <StyledValuesHeadline>{t("score")}</StyledValuesHeadline>
                      {values.map((value, index) => {
                        if (index >= 13) {
                          return (
                            <StyledValueWrapper
                              key={value.name}
                              value={value.value}
                            >
                              <StyledValueLabelWrapper>
                                <StyledValueLabelInner>
                                  <p
                                    style={{
                                      marginBottom: "10px",
                                      color: "#06D7F9",
                                    }}
                                  >
                                    {index + 1}. {value.label}
                                  </p>
                                  <p style={{ marginBottom: 0 }}>
                                    {value.desc}
                                  </p>
                                </StyledValueLabelInner>
                              </StyledValueLabelWrapper>
                              <StyledValueAnswerWrapper value={value.value}>
                                {value.value}
                              </StyledValueAnswerWrapper>
                            </StyledValueWrapper>
                          );
                        }
                        return null;
                      })}
                    </StyledValuesWrapper>
                  </Grid>
                </Grid>
              </StyledMainWrapper>
            </StyledIntroWrapper>
          </StyledIntro>
        </Fragment>
      )}
    </Fragment>
  );
});

export default SpecificReport;
