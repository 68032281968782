import React, { Fragment, useState } from "react";
import {
  Container,
  Grid,
  Box,
  InputLabel,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form, FieldArray } from "formik";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as Yup from "yup";
import { InputField } from "../../supplier/companyDetails/FormFields";
import { SelectField } from "../../supplier/companyDetails/FormFields";
import { customSearch } from "../../../stores/BaseStore";
import { useParams } from "react-router";
import { useTranslation, Trans } from "react-i18next";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";

import { auth } from "../../../stores/BaseStore";
import Loader from "../../shared/Loader";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledButtonWithIcon = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledPlusSign = styled(Box)`
  height: 100%;
  width: 46px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #ffffff;
  }
`;

const StyledRemoveWrapper = styled(Box)`
  height: 100%;
  width: 100%;
`;

const StyledMinusSign = styled(Box)`
  height: 40px;
  width: 40px;
  background: #00bfe9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: #ffffff;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledButtonText = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`;

const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;
  padding-left: 24px;
  padding-right: 24px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 24px;
  }

  h4 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: 500;
    font-size: 21px;
    line-height: 27px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
  }
`;

const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  // margin-bottom: 60px;
`;

const StyledMain = styled(Box)`
  width: 100%;
  position: relative;
  z-index: 2;
  padding-left: 24px;
  padding-right: 24px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
  }

  h4 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: 500;
    font-size: 21px;
    line-height: 27px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 20px;
  }
`;

const StyledMainWrapper = styled(Container)`
  width: 100%;
  position: relative;
  z-index: 2;
  padding-bottom: 60px;
  padding-top: 60px;

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 24px;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 36px;

    span {
      color: #808285;

      &.separator {
        color: #cfcfcf;
      }
    }
  }
`;

const StyledFormSection = styled(Box)`
  background: #f3f9fa;
  padding: 40px;
  margin-bottom: 44px;

  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    line-height: 93.1%;
    color: #000000;
    margin-bottom: 9px;
  }

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }

  .MuiDropzoneArea-root {
    height: 133px;
    border-radius: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: flex;
    justify-content: center;
  }

  .MuiSnackbar-root {
    right: 24px;
    left: auto;
  }

  .MuiDropzoneArea-text {
    display: none;
  }

  .MuiDropzonePreviewList-image {
    height: 50px;
  }

  h4 {
    margin-bottom: 10px;
  }

  p {
    color: #00c9e9;
    margin: 0;
    margin-bottom: 10px;
  }
`;

const customSearchInitialValues = {
  description: "",
  industry: undefined,
  synonims: "",
  certificates: [{ name: "" }],
  tech_parameters: [{ parameter: "" }],
  known_suppliers_list: [{ name: "" }],
  dont_inquire: "",
  area: "",
};

const customSearchValidationSchema = Yup.object().shape({
  description: Yup.string().required().label("Description"),
  industry: Yup.string().required().label("Industry"),
  synonims: Yup.string().required().label("Synonims"),
  // product_name: Yup.string().required().label("Product name"),
  certificates: Yup.array(
    Yup.object().shape({
      name: Yup.string(),
      number: Yup.string(),
    })
  ).label("Certificates"),
  tech_parameters: Yup.array(
    Yup.object().shape({
      parameter: Yup.string(),
    })
  ).label("Tech parameters"),
  known_suppliers_list: Yup.array(
    Yup.object().shape({
      name: Yup.string(),
    })
  ).label("Suppliers"),
  dont_inquire: Yup.string().label("Don't inquire"),
  area: Yup.string().label("Area"),
});

const CustomSearch = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [showSuccess, setShowSuccess] = useState(false);
  const params = useParams();
  const { input } = params;
  const { t } = useTranslation();

  const handleSubmit = (values, formikHelpers) => {
    values.product_name = params.input;
    const filteredKnownSuppliers = values.known_suppliers_list.filter(
      (supplier) => {
        return supplier.name;
      }
    );

    const filteredCertificates = values.certificates.filter((certificate) => {
      return certificate.name;
    });

    const filteredTechParameters = values.tech_parameters.filter(
      (parameter) => {
        return parameter.parameter;
      }
    );

    values = {
      ...values,
      known_suppliers_list: filteredKnownSuppliers,
      certificates: filteredCertificates,
      tech_parameters: filteredTechParameters,
    };

    customSearch.postCustomSearch(values);
    formikHelpers.setSubmitting(false);
    setShowSuccess(true);
  };

  const industries = [
    {
      value: undefined,
      label: t("none"),
    },
    {
      value: "Advertising",
      label: t("advertising"),
    },
    {
      value: "Agriculture",
      label: t("agriculture"),
    },
    {
      value: "Banking & Financial Services",
      label: t("banking & financial services"),
    },
    {
      value: "Civil Construction",
      label: t("civil construction"),
    },
    {
      value: "Consulting",
      label: t("consulting"),
    },
    {
      value: "Ecommerce",
      label: t("ecommerce"),
    },
    {
      value: "Education",
      label: t("education"),
    },
    {
      value: "Electronics / Hardware",
      label: t("electronics / hardware"),
    },
    {
      value: "Energy",
      label: t("energy"),
    },
    {
      value: "Fast Moving Consumer Goods",
      label: t("fast moving consumer goods"),
    },
    {
      value: "Healthcare",
      label: t("healthcare"),
    },
    {
      value: "Human Resources Service Provider",
      label: t("human resources service provider"),
    },
    {
      value: "Industrial Goods",
      label: t("industrial goods"),
    },
    {
      value: "IT Products",
      label: t("IT products"),
    },
    {
      value: "IT Services",
      label: t("IT services"),
    },
    {
      value: "Media",
      label: t("media"),
    },
    {
      value: "Real Estate",
      label: t("real estate"),
    },
    {
      value: "Retail",
      label: t("retail"),
    },
    {
      value: "Telecommunication",
      label: t("telecommunication"),
    },
    {
      value: "Tourism / Hotel / Restaurant",
      label: t("tourism / hotel / restaurant"),
    },
    {
      value: "Transport - Logistic",
      label: t("transport - logistic"),
    },
    {
      value: "Other",
      label: t("other"),
    },
  ];

  return (
    <Fragment>
      {!auth.user ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : !showSuccess ? (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={6}>
                  <h2>
                    {/* <Trans i18nKey="custom-search-results-message">
                      {{ input }}
                    </Trans> */}
                    {t("yvoo individualizing procurement")}
                  </h2>
                  <h4>
                    {/* {t(
                      "no suppliers registered yet, please provide your inputs for individual search"
                    )} */}
                    <Trans i18nKey="custom-search-no-results">
                      {{ input }}
                    </Trans>
                  </h4>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
          <StyledMain>
            <StyledMainWrapper>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Formik
                    initialValues={customSearchInitialValues}
                    validationSchema={customSearchValidationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting, values }) => (
                      <Form>
                        <StyledFormSection>
                          <InputLabel
                            htmlFor={"industry"}
                            shrink={true}
                            variant="outlined"
                            required
                          >
                            {t("industry")}
                          </InputLabel>
                          <SelectField
                            id={"industry"}
                            name={"industry"}
                            data={industries}
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <InputLabel
                            htmlFor={"description"}
                            shrink={true}
                            variant="outlined"
                            required
                          >
                            {t("product description")}
                          </InputLabel>
                          <InputField
                            id={"description"}
                            name={"description"}
                            fullWidth
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                            placeholder={t("product description placeholder")}
                          />
                          <InputLabel
                            htmlFor={"synonims"}
                            shrink={true}
                            variant="outlined"
                            required
                          >
                            {t("describe the products in other words")}
                          </InputLabel>
                          <InputField
                            id={"synonims"}
                            name={"synonims"}
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder={t("product synonyms placeholder")}
                          />
                        </StyledFormSection>
                        <StyledFormSection>
                          <h4>{t("special technical requirements")}</h4>
                          <p>
                            {t(
                              "e.g. length from 4 to 5 meters or minimum temperature -35*C"
                            )}
                          </p>
                          <FieldArray
                            name="tech_parameters"
                            render={(arrayHelpers) => (
                              <Fragment>
                                {values.tech_parameters.map(
                                  (parameter, index) => (
                                    <Fragment key={index}>
                                      <Grid container>
                                        <Grid item xs={9} lg={9}>
                                          <InputField
                                            id={`tech_parameters[${index}].parameter`}
                                            name={`tech_parameters[${index}].parameter`}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            placeholder={t("tech parameter")}
                                          />
                                        </Grid>
                                        <Grid item xs={3} lg={3}>
                                          <StyledRemoveWrapper>
                                            <StyledMinusSign
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <RemoveIcon />
                                            </StyledMinusSign>
                                          </StyledRemoveWrapper>
                                        </Grid>
                                      </Grid>
                                    </Fragment>
                                  )
                                )}
                                <StyledButtonWithIcon
                                  onClick={() =>
                                    arrayHelpers.push({
                                      parameter: "",
                                    })
                                  }
                                >
                                  <StyledPlusSign>
                                    <AddIcon />
                                  </StyledPlusSign>
                                  <StyledButtonText>
                                    {t("add tech parameter")}
                                  </StyledButtonText>
                                </StyledButtonWithIcon>
                              </Fragment>
                            )}
                          ></FieldArray>
                        </StyledFormSection>

                        <StyledFormSection>
                          <h4>{t("add certificates")}</h4>
                          <p>{t("e.g. iso, iatf, din, asme, gost")}</p>
                          <FieldArray
                            name="certificates"
                            render={(arrayHelpers) => (
                              <Fragment>
                                {values.certificates.map(
                                  (certificate, index) => (
                                    <Fragment key={index}>
                                      <Grid container>
                                        <Grid item xs={9} lg={9}>
                                          <InputField
                                            id={`certificates[${index}].name`}
                                            name={`certificates[${index}].name`}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            placeholder={t("certificate")}
                                          />
                                        </Grid>
                                        <Grid item xs={3} lg={3}>
                                          <StyledRemoveWrapper>
                                            <StyledMinusSign
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <RemoveIcon />
                                            </StyledMinusSign>
                                          </StyledRemoveWrapper>
                                        </Grid>
                                      </Grid>
                                    </Fragment>
                                  )
                                )}
                                <StyledButtonWithIcon
                                  onClick={() =>
                                    arrayHelpers.push({
                                      name: "",
                                      number: "",
                                    })
                                  }
                                >
                                  <StyledPlusSign>
                                    <AddIcon />
                                  </StyledPlusSign>
                                  <StyledButtonText>
                                    {t("add certificate")}
                                  </StyledButtonText>
                                </StyledButtonWithIcon>
                              </Fragment>
                            )}
                          ></FieldArray>
                        </StyledFormSection>

                        <StyledFormSection>
                          <h4>{t("Add known suppliers")}</h4>
                          <FieldArray
                            name="known_suppliers_list"
                            render={(arrayHelpers) => (
                              <Fragment>
                                {values.known_suppliers_list.map(
                                  (known_supplier, index) => (
                                    <Fragment key={index}>
                                      <Grid container>
                                        <Grid item xs={9} lg={9}>
                                          <InputField
                                            id={`known_suppliers_list[${index}].name`}
                                            name={`known_suppliers_list[${index}].name`}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            placeholder={t("supplier")}
                                          />
                                        </Grid>
                                        <Grid item xs={3} lg={3}>
                                          <StyledRemoveWrapper>
                                            <StyledMinusSign
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <RemoveIcon />
                                            </StyledMinusSign>
                                          </StyledRemoveWrapper>
                                        </Grid>
                                      </Grid>
                                    </Fragment>
                                  )
                                )}
                                <StyledButtonWithIcon
                                  onClick={() =>
                                    arrayHelpers.push({
                                      name: "",
                                    })
                                  }
                                >
                                  <StyledPlusSign>
                                    <AddIcon />
                                  </StyledPlusSign>
                                  <StyledButtonText>
                                    {t("add supplier")}
                                  </StyledButtonText>
                                </StyledButtonWithIcon>
                              </Fragment>
                            )}
                          ></FieldArray>
                        </StyledFormSection>
                        <StyledFormSection>
                          <InputLabel
                            htmlFor={"dont_inquire"}
                            shrink={true}
                            variant="outlined"
                          >
                            {t("suppliers that shouldn't be inquired")}
                          </InputLabel>
                          <InputField
                            id={"dont_inquire"}
                            name={"dont_inquire"}
                            fullWidth
                            variant="outlined"
                            size="small"
                          />
                          <InputLabel
                            htmlFor={"area"}
                            shrink={true}
                            variant="outlined"
                          >
                            {t("area")}
                          </InputLabel>
                          <InputField
                            id={"area"}
                            name={"area"}
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder={t(
                              "please enter country, city or region"
                            )}
                          />
                        </StyledFormSection>

                        <div>
                          <StyledButton disabled={isSubmitting} type="submit">
                            {t("submit")}
                          </StyledButton>
                          {isSubmitting && <CircularProgress size={24} />}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </StyledMainWrapper>
          </StyledMain>
          {/* <StyledContainer>
            <h2>
              No Results for {params.input}, try the custom search feature here
            </h2>
            <h4>
              No suppliers registered yet, please provide your inputs for
              individual search
            </h4>

          </StyledContainer> */}
        </Fragment>
      ) : (
        <StyledIntro>
          <StyledIntroWrapper fixed>
            <Grid
              container
              justify={matches ? "space-between" : "flex-start"}
              alignItems={"flex-start"}
            >
              <Grid item xs={12} lg={6}>
                <h4>
                  <Trans i18nKey="custom-search-submitted">
                    <Link to="/"></Link>
                  </Trans>
                </h4>
              </Grid>
            </Grid>
          </StyledIntroWrapper>
        </StyledIntro>
      )}
    </Fragment>
  );
});

export default CustomSearch;
