import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const StyledWrapper = styled.div`
  position: fixed;
  // min-height: 100vh;
  // min-width: 100vw;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const StyledProgress = styled(CircularProgress)`
  color: #06d7f9;
`;

const Loader = () => {
  return (
    <StyledWrapper>
      <StyledProgress />
    </StyledWrapper>
  );
};

export default Loader;
