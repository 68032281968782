import React, { Fragment, useEffect, useState } from "react";
import GdprFooter from "../shared/GdprFooter";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { autoEffect, view } from "@risingstack/react-easy-state";
import parse from "html-react-parser";

import { gdpr } from "../../stores/BaseStore";
import Loader from "../shared/Loader";

const StyledGridContainer = styled(Grid)`
  min-height: calc(100vh);
  position: relative;
  z-index: 2;
  padding-top: 230px;
  background-color: #f3f8f9;
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StyledWrapperOuter = styled(Box)`
  height: 60vh;
  width: 100%;
  max-width: 1000px;
  padding: 56px 112px;
  display: flex;
  flex-direction: column;
  // background-color: #273032;

  // h2 {
  //   font-family: unset;
  //   font-size: 3.75rem;
  //   font-weight: 300;
  //   line-height: 1.2;
  //   letter-spacing: -0.00833em;
  // }

  h2 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: normal;
    margin: 0;
    margin-bottom: 56px;

    span {
      color: #0091d4;
    }
  }

  h3 {
    font-family: unset;
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: 0em;
  }

  h4 {
    font-family: unset;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
  }

  h5 {
    font-family: unset;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
  }

  h6 {
    font-family: unset;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }

  @media (max-width: 959px) {
    padding: 24px;

    h2 {
      font-size: 30px;
      line-height: unset;
      margin-bottom: 12px;
    }
  }
`;

const StyledWrapperInner = styled(Box)`
  overflow-y: scroll;
  padding: 24px 24px 24px 0;
  // margin-bottom: 82px;

  @media (max-width: 959px) {
    margin-bottom: 24px;
  }
`;

const Privacy = view(() => {
  const [term, setTerm] = useState(null);
  useEffect(() => {
    if (!gdpr.publicTerms) {
      gdpr.getPublicTerms();
    }
  }, []);

  autoEffect(() => {
    if (gdpr.publicTerms) {
      const term = gdpr.publicTerms.find((term) => {
        return term.name.toLowerCase() === "privacy policy";
      });

      setTerm(term);
    }
  });

  return (
    <Fragment>
      {!term ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <StyledGridContainer container>
          <Grid item xs={12}>
            <StyledWrapper>
              <StyledWrapperOuter>
                <h2>{term.name}</h2>
                <StyledWrapperInner>{parse(term.terms)}</StyledWrapperInner>
              </StyledWrapperOuter>
              <GdprFooter />
            </StyledWrapper>
          </Grid>
        </StyledGridContainer>
      )}
    </Fragment>
  );
});

export default Privacy;
