import React, { Fragment, useEffect } from "react";
// import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import styled from "styled-components";
import { view } from "@risingstack/react-easy-state";
import { useParams } from "react-router";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

import { auth, customSearch } from "../../../stores/BaseStore";

import Loader from "../../shared/Loader";
import CustomSearchResult from "../search/CustomSearchResult";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;

    span {
      font-weight: 700;
    }
  }
`;

const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  margin-bottom: 60px;
`;

const StyledResultsWrapper = styled(Container)`
  position: relative;
  z-index: 2;
`;

const CustomSearchResults = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  let params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (params.id) {
      customSearch.getCustomSearchResults(params.id);
    }
  }, [params.id]);

  return (
    <Fragment>
      {!auth.user || !customSearch.result || customSearch.loading ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : !auth.user ||
        !customSearch.result ||
        !customSearch.result[0] ||
        !customSearch.result[0].CustomSearch ||
        (customSearch.result[0] &&
          customSearch.result[0].CustomSearch.user_uuid !==
            auth.user.attributes.sub) ? (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={6}>
                  <h3>{t("not available")}</h3>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
          </StyledIntro>
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={6}>
                  <h2>{t("custom search results")}</h2>
                </Grid>
              </Grid>
            </StyledIntroWrapper>
            {!customSearch.result || customSearch.result.length < 1 ? null : (
              <StyledResultsWrapper>
                <Grid container justify="flex-start" alignItems={"flex-start"}>
                  {customSearch.result.map((result) => {
                    return (
                      <CustomSearchResult
                        result={result.Company}
                        term={params.input}
                        key={result.Company.id}
                      />
                    );
                  })}
                </Grid>
              </StyledResultsWrapper>
            )}
          </StyledIntro>
        </Fragment>
      )}
    </Fragment>
  );
});

export default CustomSearchResults;
