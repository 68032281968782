import formModel from "./formModel";
const {
  passwordFormField: { current_password, new_password, repeat_password },
} = formModel;

export default {
  [current_password.name]: "",
  [new_password.name]: "",
  [repeat_password.name]: "",
};
