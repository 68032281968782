import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import styled from "styled-components";

const StyledFormControlLabel = styled(FormControlLabel)`
  font-size: 18px;
  line-height: 17px;
  color: #000000;

  .MuiCheckbox-root {
    color: #c4c4c4;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #06D7F9;
  }
`;

export default function CheckboxField(props) {
  const { label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);
  }

  return (
    <FormControl {...rest}>
      <StyledFormControlLabel
        value={field.value}
        checked={field.value}
        control={<Checkbox {...field} onChange={_onChange} />}
        label={label}
      />
      {_renderHelperText()}
    </FormControl>
  );
}
