import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import footerGraphic from "../../assets/img/footerGraphic.svg";

const StyledFooterContainer = styled(Grid)`
  // position: fixed;
  bottom: 0;
  position: relative;
  z-index: 1200;
  padding: 60px 0 60px 0;
  background-color: #000000;
  border-top: 1px solid #383838;
`;

const StyledFooterWrapper = styled(Box)`
  height: 100%;
  border-left: 1px solid #474747;
  padding: 6px 18px 20px 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledLink = styled(Link)`
  font-family: "RobotoMedium", "Arial", sans-serif;
  font-size: 14px;
  line-height: 17px;
  // text-decoration-line: underline;
  color: #42eae5;
  margin-bottom: 9px;
`;

const StyledGraphic = styled.img`
  position: absolute;
  right: 30px;
  bottom: 0;
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <StyledFooterContainer container>
      <Container fixed>
        <Grid container>
          <Grid item xs={12} md={3}>
            <StyledFooterWrapper>
              <StyledLink to="/terms-and-conditions">
                {t("terms and conditions")}
              </StyledLink>
              <StyledLink to="/privacy">
                {t("privacy policy")}
              </StyledLink>
              <StyledLink to="/impressum">{t("impressum")}</StyledLink>
            </StyledFooterWrapper>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledFooterWrapper>
              <StyledLink to="/">{t("dashboard")}</StyledLink>
              <StyledLink to="/profile">{t("user profile")}</StyledLink>
            </StyledFooterWrapper>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledFooterWrapper>
              <StyledLink to="/faq">{t("faq")}</StyledLink>
              <StyledLink to="/help">{t("help")}</StyledLink>
            </StyledFooterWrapper>
          </Grid>
        </Grid>
      </Container>
      <StyledGraphic src={footerGraphic} alt="" />
    </StyledFooterContainer>
  );
};

export default Footer;
