import i18next from "i18next";

export default {
  formId: "mainForm",
  formField: {
    organisation: {
      name: "organisation",
      label: i18next.t("quickscan-question-1"),
      values: {
        1: i18next.t("quickscan-question-1-answer-1"),
        2: i18next.t("quickscan-question-1-answer-2"),
        3: i18next.t("quickscan-question-1-answer-3"),
        4: i18next.t("quickscan-question-1-answer-4"),
        5: i18next.t("quickscan-question-1-answer-5"),
      },
    },
    financial: {
      name: "financial",
      label: i18next.t("quickscan-question-2"),
      values: {
        1: i18next.t("quickscan-question-2-answer-1"),
        2: i18next.t("quickscan-question-2-answer-2"),
        3: i18next.t("quickscan-question-2-answer-3"),
        4: i18next.t("quickscan-question-2-answer-4"),
        5: i18next.t("quickscan-question-2-answer-5"),
      },
    },
    system_audit: {
      name: "system_audit",
      label: i18next.t("quickscan-question-3"),
      values: {
        1: i18next.t("quickscan-question-3-answer-1"),
        2: i18next.t("quickscan-question-3-answer-2"),
        3: i18next.t("quickscan-question-3-answer-3"),
        4: i18next.t("quickscan-question-3-answer-4"),
        5: i18next.t("quickscan-question-3-answer-5"),
      },
    },
    process: {
      name: "process",
      label: i18next.t("quickscan-question-4"),
      values: {
        1: i18next.t("quickscan-question-4-answer-1"),
        2: i18next.t("quickscan-question-4-answer-2"),
        3: i18next.t("quickscan-question-4-answer-3"),
        4: i18next.t("quickscan-question-4-answer-4"),
        5: i18next.t("quickscan-question-4-answer-5"),
      },
    },
    location: {
      name: "location",
      label: i18next.t("quickscan-question-5"),
      values: {
        1: i18next.t("quickscan-question-5-answer-1"),
        2: i18next.t("quickscan-question-5-answer-2"),
        3: i18next.t("quickscan-question-5-answer-3"),
        4: i18next.t("quickscan-question-5-answer-4"),
        5: i18next.t("quickscan-question-5-answer-5"),
      },
    },
    capacity_planning: {
      name: "capacity_planning",
      label: i18next.t("quickscan-question-6"),
      values: {
        1: i18next.t("quickscan-question-6-answer-1"),
        2: i18next.t("quickscan-question-6-answer-2"),
        3: i18next.t("quickscan-question-6-answer-3"),
        4: i18next.t("quickscan-question-6-answer-4"),
        5: i18next.t("quickscan-question-6-answer-5"),
      },
    },
    product_competence: {
      name: "product_competence",
      label: i18next.t("quickscan-question-7"),
      values: {
        1: i18next.t("quickscan-question-7-answer-1"),
        2: i18next.t("quickscan-question-7-answer-2"),
        3: i18next.t("quickscan-question-7-answer-3"),
        4: i18next.t("quickscan-question-7-answer-4"),
        5: i18next.t("quickscan-question-7-answer-5"),
      },
    },
    development_competence: {
      name: "development_competence",
      label: i18next.t("quickscan-question-8"),
      values: {
        1: i18next.t("quickscan-question-8-answer-1"),
        2: i18next.t("quickscan-question-8-answer-2"),
        3: i18next.t("quickscan-question-8-answer-3"),
        4: i18next.t("quickscan-question-8-answer-4"),
        5: i18next.t("quickscan-question-8-answer-5"),
      },
    },
    productivity: {
      name: "productivity",
      label: i18next.t("quickscan-question-9"),
      values: {
        1: i18next.t("quickscan-question-9-answer-1"),
        2: i18next.t("quickscan-question-9-answer-2"),
        3: i18next.t("quickscan-question-9-answer-3"),
        4: i18next.t("quickscan-question-9-answer-4"),
        5: i18next.t("quickscan-question-9-answer-5"),
      },
    },
    control: {
      name: "control",
      label: i18next.t("quickscan-question-10"),
      values: {
        1: i18next.t("quickscan-question-10-answer-1"),
        2: i18next.t("quickscan-question-10-answer-2"),
        3: i18next.t("quickscan-question-10-answer-3"),
        4: i18next.t("quickscan-question-10-answer-4"),
        5: i18next.t("quickscan-question-10-answer-5"),
      },
    },
    s5: {
      name: "s5",
      label: i18next.t("quickscan-question-11"),
      values: {
        1: i18next.t("quickscan-question-11-answer-1"),
        2: i18next.t("quickscan-question-11-answer-2"),
        3: i18next.t("quickscan-question-11-answer-3"),
        4: i18next.t("quickscan-question-11-answer-4"),
        5: i18next.t("quickscan-question-11-answer-5"),
      },
    },
    visual_management: {
      name: "visual_management",
      label: i18next.t("quickscan-question-12"),
      values: {
        1: i18next.t("quickscan-question-12-answer-1"),
        2: i18next.t("quickscan-question-12-answer-2"),
        3: i18next.t("quickscan-question-12-answer-3"),
        4: i18next.t("quickscan-question-12-answer-4"),
        5: i18next.t("quickscan-question-12-answer-5"),
      },
    },
    standardized_work: {
      name: "standardized_work",
      label: i18next.t("quickscan-question-13"),
      values: {
        1: i18next.t("quickscan-question-13-answer-1"),
        2: i18next.t("quickscan-question-13-answer-2"),
        3: i18next.t("quickscan-question-13-answer-3"),
        4: i18next.t("quickscan-question-13-answer-4"),
        5: i18next.t("quickscan-question-13-answer-5"),
      },
    },
    inventory: {
      name: "inventory",
      label: i18next.t("quickscan-question-14"),
      values: {
        1: i18next.t("quickscan-question-14-answer-1"),
        2: i18next.t("quickscan-question-14-answer-2"),
        3: i18next.t("quickscan-question-14-answer-3"),
        4: i18next.t("quickscan-question-14-answer-4"),
        5: i18next.t("quickscan-question-14-answer-5"),
      },
    },
    tpm: {
      name: "tpm",
      label: i18next.t("quickscan-question-15"),
      values: {
        1: i18next.t("quickscan-question-15-answer-1"),
        2: i18next.t("quickscan-question-15-answer-2"),
        3: i18next.t("quickscan-question-15-answer-3"),
        4: i18next.t("quickscan-question-15-answer-4"),
        5: i18next.t("quickscan-question-15-answer-5"),
      },
    },
    schedule: {
      name: "schedule",
      label: i18next.t("quickscan-question-16"),
      values: {
        1: i18next.t("quickscan-question-16-answer-1"),
        2: i18next.t("quickscan-question-16-answer-2"),
        3: i18next.t("quickscan-question-16-answer-3"),
        4: i18next.t("quickscan-question-16-answer-4"),
        5: i18next.t("quickscan-question-16-answer-5"),
      },
    },
    flow: {
      name: "flow",
      label: i18next.t("quickscan-question-17"),
      values: {
        1: i18next.t("quickscan-question-17-answer-1"),
        2: i18next.t("quickscan-question-17-answer-2"),
        3: i18next.t("quickscan-question-17-answer-3"),
        4: i18next.t("quickscan-question-17-answer-4"),
        5: i18next.t("quickscan-question-17-answer-5"),
      },
    },
    cycle: {
      name: "cycle",
      label: i18next.t("quickscan-question-18"),
      values: {
        1: i18next.t("quickscan-question-18-answer-1"),
        2: i18next.t("quickscan-question-18-answer-2"),
        3: i18next.t("quickscan-question-18-answer-3"),
        4: i18next.t("quickscan-question-18-answer-4"),
        5: i18next.t("quickscan-question-18-answer-5"),
      },
    },
    pull: {
      name: "pull",
      label: i18next.t("quickscan-question-19"),
      values: {
        1: i18next.t("quickscan-question-19-answer-1"),
        2: i18next.t("quickscan-question-19-answer-2"),
        3: i18next.t("quickscan-question-19-answer-3"),
        4: i18next.t("quickscan-question-19-answer-4"),
        5: i18next.t("quickscan-question-19-answer-5"),
      },
    },
    deviations: {
      name: "deviations",
      label: i18next.t("quickscan-question-20"),
      values: {
        1: i18next.t("quickscan-question-20-answer-1"),
        2: i18next.t("quickscan-question-20-answer-2"),
        3: i18next.t("quickscan-question-20-answer-3"),
        4: i18next.t("quickscan-question-20-answer-4"),
        5: i18next.t("quickscan-question-20-answer-5"),
      },
    },
    line_stop_concept: {
      name: "line_stop_concept",
      label: i18next.t("quickscan-question-21"),
      values: {
        1: i18next.t("quickscan-question-21-answer-1"),
        2: i18next.t("quickscan-question-21-answer-2"),
        3: i18next.t("quickscan-question-21-answer-3"),
        4: i18next.t("quickscan-question-21-answer-4"),
        5: i18next.t("quickscan-question-21-answer-5"),
      },
    },
    problem_solving: {
      name: "problem_solving",
      label: i18next.t("quickscan-question-22"),
      values: {
        1: i18next.t("quickscan-question-22-answer-1"),
        2: i18next.t("quickscan-question-22-answer-2"),
        3: i18next.t("quickscan-question-22-answer-3"),
        4: i18next.t("quickscan-question-22-answer-4"),
        5: i18next.t("quickscan-question-22-answer-5"),
      },
    },
    machine_design: {
      name: "machine_design",
      label: i18next.t("quickscan-question-23"),
      values: {
        1: i18next.t("quickscan-question-23-answer-1"),
        2: i18next.t("quickscan-question-23-answer-2"),
        3: i18next.t("quickscan-question-23-answer-3"),
        4: i18next.t("quickscan-question-23-answer-4"),
        5: i18next.t("quickscan-question-23-answer-5"),
      },
    },
    line_design: {
      name: "line_design",
      label: i18next.t("quickscan-question-24"),
      values: {
        1: i18next.t("quickscan-question-24-answer-1"),
        2: i18next.t("quickscan-question-24-answer-2"),
        3: i18next.t("quickscan-question-24-answer-3"),
        4: i18next.t("quickscan-question-24-answer-4"),
        5: i18next.t("quickscan-question-24-answer-5"),
      },
    },
    factory_design: {
      name: "factory_design",
      label: i18next.t("quickscan-question-25"),
      values: {
        1: i18next.t("quickscan-question-25-answer-1"),
        2: i18next.t("quickscan-question-25-answer-2"),
        3: i18next.t("quickscan-question-25-answer-3"),
        4: i18next.t("quickscan-question-25-answer-4"),
        5: i18next.t("quickscan-question-25-answer-5"),
      },
    },
  },
};
