import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormHelperText,
  Box,
} from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  iconGreen: {
    borderRadius: "50%",
    width: 18,
    height: 18,
    backgroundColor: "#01E7AA",
    opacity: 0,
    "input:hover ~ &": {
      opacity: 1,
    },
    transition: "all .2s ease-in-out",
  },
  checkedIconGreen: {
    borderRadius: "50%",
    width: 18,
    height: 18,
    backgroundColor: "#01E7AA",
    opacity: 1,
    "input:hover ~ &": {
      opacity: 1,
    },
  },
  iconRed: {
    borderRadius: "50%",
    width: 18,
    height: 18,
    backgroundColor: "#FF490F",
    opacity: 0,
    "input:hover ~ &": {
      opacity: 1,
    },
    transition: "all .2s ease-in-out",
  },
  checkedIconRed: {
    borderRadius: "50%",
    width: 18,
    height: 18,
    backgroundColor: "#FF490F",
    opacity: 1,
    "input:hover ~ &": {
      opacity: 1,
    },
  },
  iconNeutral: {
    borderRadius: "50%",
    width: 18,
    height: 18,
    backgroundColor: "#ffffff",
    border: "1px solid #000000",
    opacity: 0,
    "input:hover ~ &": {
      opacity: 1,
    },
    transition: "all .2s ease-in-out",
  },
  checkedIconNeutral: {
    borderRadius: "50%",
    width: 18,
    height: 18,
    backgroundColor: "#ffffff",
    border: "1px solid #000000",
    opacity: 1,
    "input:hover ~ &": {
      opacity: 1,
    },
  },
});

const StyledRadioGroup = styled(RadioGroup)`
  .yesLabel {
    font-family: "NeueMachinaUltrabold";
    font-size: 16px;
    line-height: 17px;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    color: ${(props) => (props.value === 1 ? "#000000" : "#c4c4c4")};
  }

  .maybeLabel {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: #c4c4c4;
    transition: all 0.2s ease-in-out;
    vertical-align: middle;
    color: ${(props) => (props.value === "no answer" ? "#000000" : "#c4c4c4")};
  }

  .noLabel {
    font-family: "NeueMachinaUltrabold";
    font-size: 16px;
    line-height: 17px;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    color: ${(props) => (props.value === 0 ? "#000000" : "#c4c4c4")};
  }

  .MuiIconButton-root {
    margin-right: 10px;
  }
`;

const StyledCheckboxWrapper = styled(Box)`
  border-left: ${(props) =>
    props.value === 1
      ? "1px solid #01e7aa"
      : props.value === "no answer"
      ? "1px solid #DDDDDD"
      : props.value === 0
      ? "1px solid #FF490F"
      : null};
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 30px;
  padding: 0;
  transition: border-left 0.2s ease-in-out;
`;

export default function RadioField(props) {
  const { label, name, initialvalue, ...rest } = props;
  const [field, meta] = useField(props);
  const [value, setValue] = React.useState(initialvalue);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (event) => {
    setValue(
      event.target.value === "no answer"
        ? "no answer"
        : parseInt(event.target.value)
    );
  };

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <StyledCheckboxWrapper value={value}>
      <FormControl {...rest} component="fieldset" onChange={handleChange}>
        <StyledRadioGroup {...field} name={name} value={value}>
          <FormControlLabel
            value={1}
            control={
              <Radio
                checkedIcon={
                  <span
                    className={clsx(
                      classes.iconGreen,
                      classes.checkedIconGreen
                    )}
                  />
                }
                icon={<span className={classes.iconGreen} />}
              />
            }
            label={<span className="yesLabel">{t("yes")}</span>}
          />
          <FormControlLabel
            value="no answer"
            control={
              <Radio
                checkedIcon={
                  <span
                    className={clsx(
                      classes.iconNeutral,
                      classes.checkedIconNeutral
                    )}
                  />
                }
                icon={<span className={classes.iconNeutral} />}
              />
            }
            label={<span className="maybeLabel">{t("no answer")}</span>}
          />
          <FormControlLabel
            value={0}
            control={
              <Radio
                checkedIcon={
                  <span
                    className={clsx(classes.iconRed, classes.checkedIconRed)}
                  />
                }
                icon={<span className={classes.iconRed} />}
              />
            }
            label={<span className="noLabel">{t("no")}</span>}
          />
        </StyledRadioGroup>
        {_renderHelperText()}
      </FormControl>
    </StyledCheckboxWrapper>
  );
}
