import formModel from "./formModel";
const {
  formField: {
    name,
    email,
    profile_photo,
    mobile_phone,
    country,
    zip,
    job_function,
    job_level,
    discipline,
    industry,
    company_name,
    address,
    city
  },
} = formModel;

export default {
  [name.name]: "",
  [email.email]: "",
  [profile_photo.name]: "",
  [mobile_phone.name]: "",
  [country.name]: "",
  [zip.name]: "",
  [job_function.name]: "",
  [job_level.name]: "",
  [discipline.name]: "",
  [industry.name]: "",
  [company_name.name]: "",
  [address.name]: "",
  [city.name]: "",

};
