import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useHistory } from "react-router-dom";

import { auth } from "../../stores/BaseStore";

import Auth from "../Auth";
import GdprFooter from "../shared/GdprFooter";

const StyledGridContainer = styled(Grid)`
  // min-height: calc(100vh - 75px);
  min-height: calc(100vh);
  position: relative;
  background-color: #f3f8f9;
`;

const StyledWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Login = view(() => {
  let history = useHistory();

  autoEffect(() => {
    if (auth.isLoggedIn) {
      if (
        auth.user &&
        auth.user.signInUserSession.idToken.payload["cognito:groups"].find(
          (group) => group === "yvoo-buyer"
        )
      ) {
        history.push("/search");
      } else {
        history.push("/");
      }
      // if (location.prevLocation) {
      //   history.push(location.prevLocation);
      // } else {
      //   history.push("/");
      // }
    }
  });

  return (
    <StyledGridContainer container>
      <Grid item xs={12}>
        <StyledWrapper>
          <Auth />
          <GdprFooter />
        </StyledWrapper>
      </Grid>
    </StyledGridContainer>
  );
});

export default Login;
