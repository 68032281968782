import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import styled from "styled-components";
import { autoEffect, view } from "@risingstack/react-easy-state";
import { useParams } from "react-router";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

import { auth, search, searchFilters } from "../../../stores/BaseStore";

import Loader from "../../shared/Loader";
import SearchResult from "../search/SearchResult";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;

    a {
      text-decoration: none;
      color: #00c9e9;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  margin-bottom: 60px;
`;

const StyledResultsWrapper = styled(Container)`
  position: relative;
  z-index: 2;
`;

const StyledPaginationWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  margin-bottom: 80px;
`;

const SearchResults = view(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  let params = useParams();
  let history = useHistory();
  const [filteredResults, setFilteredResults] = useState([]);
  const [page, setPage] = useState(1);

  autoEffect(async () => {
    if (params.input && params.region && params.page) {
      await search.getSearchResults(params.input, params.region, params.page);

      if (
        auth.user &&
        (!auth.user.attributes["custom:paid"] ||
          auth.user.attributes["custom:paid"] !== "true" ||
          !search.results ||
          search.results.length < 1)
      ) {
        history.push(`/custom-search/${params.input}`);
      }
    }
  }, [params.input, params.region, params.page]);

  autoEffect(() => {
    if (search.results && search.results.length > 0 && searchFilters.filters) {
      filterResults();
    } else {
      setFilteredResults(search.results);
    }
  }, [search.results, searchFilters.filters]);

  const handlePageChange = (event, value) => {
    if (page) {
      history.push(
        `/search-results/${params.input}/region/${params.region}/page/${value}`
      );
    }
  };

  useEffect(() => {
    if (params.page) {
      setPage(parseInt(params.page) || 1);
    }
  }, [params.page]);

  const filterResults = () => {
    let results = search.results.filter((result) => {
      if (searchFilters.filters.quickscan) {
        return (
          result.assessment &&
          result.assessment.overall_score &&
          !result.assessment.overall_score.original
        );
      } else {
        return result;
      }
    });

    results = results.filter((result) => {
      if (searchFilters.filters.quickscan_rating) {
        return (
          result.assessment &&
          result.assessment.overall_score &&
          result.assessment.overall_score > 3
        );
      } else {
        return result;
      }
    });

    results = results.filter((result) => {
      if (searchFilters.filters.turnover5) {
        return result.turnover < 5000000;
      } else if (searchFilters.filters.turnover20) {
        return result.turnover < 20000000;
      } else if (searchFilters.filters.turnover100) {
        return result.turnover < 100000000;
      } else if (searchFilters.filters.turnover500) {
        return result.turnover < 50000000;
      } else if (searchFilters.filters.turnover1B) {
        return result.turnover < 1000000000;
      } else if (searchFilters.filters.turnover10B) {
        return result.turnover < 10000000000;
      } else {
        return result;
      }
    });

    results = results.filter((result) => {
      const iso9001_2015 =
        result.Certificates &&
        result.Certificates.length > 0 &&
        result.Certificates.find((certificate) => {
          return (
            certificate.name === "iso" && certificate.number === "9001:2015"
          );
        });

      if (searchFilters.filters.iso9001_2015) {
        return iso9001_2015;
      } else {
        return result;
      }
    });

    results = results.filter((result) => {
      const iso14001_2015 =
        result.Certificates &&
        result.Certificates.length > 0 &&
        result.Certificates.find((certificate) => {
          return (
            certificate.name === "iso" && certificate.number === "14001:2015"
          );
        });

      if (searchFilters.filters.iso14001_2015) {
        return iso14001_2015;
      } else {
        return result;
      }
    });

    results = results.filter((result) => {
      const ohsas18001 =
        result.Certificates &&
        result.Certificates.length > 0 &&
        result.Certificates.find((certificate) => {
          return certificate.name === "ohsas" && certificate.number === "18001";
        });

      if (searchFilters.filters.ohsas18001) {
        return ohsas18001;
      } else {
        return result;
      }
    });

    setFilteredResults(results);
  };

  return (
    <Fragment>
      {!auth.user || !search.results || !search.pagination || search.loading ? (
        <Fragment>
          <StyledSpacer />
          <Loader />
        </Fragment>
      ) : (
        <Fragment>
          <StyledIntro>
            <StyledIntroWrapper fixed>
              <Grid
                container
                justify={matches ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
              >
                <Grid item xs={12} lg={6}>
                  {!search.results || search.results.length < 1 ? (
                    <>
                      <h2>No results</h2>
                      <h3>
                        Try the custom search feature{" "}
                        <Link to={"/custom-search/" + params.input}>here</Link>.
                      </h3>
                    </>
                  ) : (
                    <h2>{params.input} suppliers</h2>
                  )}
                </Grid>
              </Grid>
            </StyledIntroWrapper>
            {!filteredResults || filteredResults.length < 1 ? null : (
              <StyledResultsWrapper>
                <Grid container justify="flex-start" alignItems={"flex-start"}>
                  {filteredResults.map((result) => {
                    return (
                      <SearchResult
                        result={result}
                        term={params.input}
                        key={result.id}
                      />
                    );
                  })}
                </Grid>
              </StyledResultsWrapper>
            )}
            {!filteredResults ||
            filteredResults.length < 1 ||
            !search.pagination ? null : (
              <StyledPaginationWrapper>
                <Grid container>
                  <Grid item xs={11}>
                    <Box display="flex" justifyContent="flex-end">
                      <Pagination
                        count={search.pagination.last_page}
                        color="primary"
                        shape="rounded"
                        page={page}
                        onChange={handlePageChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </StyledPaginationWrapper>
            )}
          </StyledIntro>
        </Fragment>
      )}
    </Fragment>
  );
});

export default SearchResults;
