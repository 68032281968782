import formModel from "./formModel";
const {
  formField: { segment, family, productClass, commodities },
} = formModel;

export default {
  [segment.name]: "",
  [family.name]: "",
  [productClass.name]: "",
  [commodities.name]: [],
};
