import React, { Fragment } from "react";
import { Grid, Box } from "@material-ui/core";
import styled from "styled-components";
import RadioField from "../../FormFields/RadioField";
import { useTranslation } from "react-i18next";

import Loader from "components/shared/Loader";
import { view } from "@risingstack/react-easy-state";

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledSectionOuter = styled(Box)`
  padding: 25px 0;
  border-bottom: 1px solid #dddddd;
  margin-bottom: ${(props) => (props.last ? "60px" : 0)};
  border-top: ${(props) => (props.first ? "1px solid #dddddd" : "none")};
`;

const StyledSectionInner = styled(Box)`
  .MuiInputLabel-root {
    font-family: "RobotoRegular", "Arial", sans-serif;
    transform: none;
    display: block;
    font-size: 14px;
    line-height: 93.1%;
    color: #000000;
    margin-bottom: 9px;
  }

  .MuiFormLabel-asterisk {
    color: #ff490f;
  }

  .MuiDropzoneArea-root {
    height: 133px;
    border-radius: 0;
    min-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 120.1%;
      text-align: center;
      color: #b0b0b0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: flex;
    justify-content: center;
  }

  .MuiSnackbar-root {
    right: 24px;
    left: auto;
  }

  .MuiDropzoneArea-text {
    display: none;
  }
`;

const StyledInfoWrapper = styled(Box)`
  position: relative;
  // padding: 30px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  h6 {
    font-family: "RobotoMedium", "Arial", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    color: #000000;
    margin: 0;
  }

  p {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: #7b7b7b;
    margin: 0;
    margin-top: 18px;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #06d7f9;
  }
`;

const MainForm = view((props) => {
  const {
    formField: {
      customer_rating,
      facility_clean,
      visual_labeling,
      everything_stored,
      operational_goals,
      materials_stored,
      work_instructions,
      updated_charts,
      current_state,
      production_lines,
      material_moved,
      continuous_flow,
      trained_teams,
      commited_employes,
      timetable,
      project_managment,
      supplier_certification,
      product_characteristics,
    },
    initialValues,
  } = props;
  const { t } = useTranslation();

  return !initialValues ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <h2>{t("lean manufacturing for customer satisfaction and a healthy bottom line")}</h2>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <StyledSectionOuter first="true">
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>1</span>
                    <h6>{customer_rating.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={customer_rating.name}
                    initialvalue={initialValues.customer_rating}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>2</span>
                    <h6>{facility_clean.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={facility_clean.name}
                    initialvalue={initialValues.facility_clean}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>3</span>
                    <h6>{visual_labeling.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={visual_labeling.name}
                    initialvalue={initialValues.visual_labeling}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>4</span>
                    <h6>{everything_stored.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={everything_stored.name}
                    initialvalue={initialValues.everything_stored}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>5</span>
                    <h6>{operational_goals.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={operational_goals.name}
                    initialvalue={initialValues.operational_goals}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>6</span>
                    <h6>{materials_stored.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={materials_stored.name}
                    initialvalue={initialValues.materials_stored}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>7</span>
                    <h6>{work_instructions.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={work_instructions.name}
                    initialvalue={initialValues.work_instructions}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>8</span>
                    <h6>{updated_charts.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={updated_charts.name}
                    initialvalue={initialValues.updated_charts}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>9</span>
                    <h6>{current_state.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={current_state.name}
                    initialvalue={initialValues.current_state}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>10</span>
                    <h6>{production_lines.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={production_lines.name}
                    initialvalue={initialValues.production_lines}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>11</span>
                    <h6>{material_moved.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={material_moved.name}
                    initialvalue={initialValues.material_moved}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>12</span>
                    <h6>{continuous_flow.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={continuous_flow.name}
                    initialvalue={initialValues.continuous_flow}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>13</span>
                    <h6>{trained_teams.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={trained_teams.name}
                    initialvalue={initialValues.trained_teams}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>14</span>
                    <h6>{commited_employes.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={commited_employes.name}
                    initialvalue={initialValues.commited_employes}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>15</span>
                    <h6>{timetable.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={timetable.name}
                    initialvalue={initialValues.timetable}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>16</span>
                    <h6>{project_managment.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={project_managment.name}
                    initialvalue={initialValues.project_managment}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter>
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>17</span>
                    <h6>{supplier_certification.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={supplier_certification.name}
                    initialvalue={initialValues.supplier_certification}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
        <Grid item xs={12}>
          <StyledSectionOuter last="true">
            <StyledSectionInner>
              <Grid container justify="space-between">
                <Grid item xs={12} md={8}>
                  <StyledInfoWrapper>
                    <span>18</span>
                    <h6>{product_characteristics.label}</h6>
                  </StyledInfoWrapper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <RadioField
                    name={product_characteristics.name}
                    initialvalue={initialValues.product_characteristics}
                  />
                </Grid>
              </Grid>
            </StyledSectionInner>
          </StyledSectionOuter>
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default MainForm;
