import * as Yup from "yup";
import formModel from "./formModel";
const {
  formField: {
    organisation,
    financial,
    system_audit,
    process,
    location,
    capacity_planning,
    product_competence,
    development_competence,
    productivity,
    control,
    s5,
    visual_management,
    standardized_work,
    inventory,
    tpm,
    schedule,
    flow,
    cycle,
    pull,
    deviations,
    line_stop_concept,
    problem_solving,
    machine_design,
    line_design,
    factory_design,
  },
} = formModel;

// const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

export default [
  Yup.object().shape({
    [organisation.name]: Yup.string(),
  }),
  Yup.object().shape({
    [financial.name]: Yup.string(),
  }),
  Yup.object().shape({
    [system_audit.name]: Yup.string(),
  }),
  Yup.object().shape({
    [process.name]: Yup.string(),
  }),
  Yup.object().shape({
    [location.name]: Yup.string(),
  }),
  Yup.object().shape({
    [capacity_planning.name]: Yup.string(),
  }),
  Yup.object().shape({
    [product_competence.name]: Yup.string(),
  }),
  Yup.object().shape({
    [development_competence.name]: Yup.string(),
  }),
  Yup.object().shape({
    [productivity.name]: Yup.string(),
  }),
  Yup.object().shape({
    [control.name]: Yup.string(),
  }),
  Yup.object().shape({
    [s5.name]: Yup.string(),
  }),
  Yup.object().shape({
    [visual_management.name]: Yup.string(),
  }),
  Yup.object().shape({
    [standardized_work.name]: Yup.string(),
  }),
  Yup.object().shape({
    [inventory.name]: Yup.string(),
  }),
  Yup.object().shape({
    [tpm.name]: Yup.string(),
  }),
  Yup.object().shape({
    [schedule.name]: Yup.string(),
  }),
  Yup.object().shape({
    [flow.name]: Yup.string(),
  }),
  Yup.object().shape({
    [cycle.name]: Yup.string(),
  }),
  Yup.object().shape({
    [pull.name]: Yup.string(),
  }),
  Yup.object().shape({
    [deviations.name]: Yup.string(),
  }),
  Yup.object().shape({
    [line_stop_concept.name]: Yup.string(),
  }),
  Yup.object().shape({
    [problem_solving.name]: Yup.string(),
  }),
  Yup.object().shape({
    [machine_design.name]: Yup.string(),
  }),
  Yup.object().shape({
    [line_design.name]: Yup.string(),
  }),
  Yup.object().shape({
    [factory_design.name]: Yup.string(),
  }),
];
