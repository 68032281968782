import React, { useState, Fragment } from "react";
import { Button, Container, Grid, Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Formik, Form } from "formik";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { autoEffect, view } from "@risingstack/react-easy-state";
import { company, quickscan } from "stores/BaseStore";

import Loader from "components/shared/Loader";

import validationSchema from "../quickscan/FormPage/FormModel/validationSchema";
import formModel from "../quickscan/FormPage/FormModel/formModel";
import formInitialValues from "../quickscan/FormPage/FormModel/formInitialValues";

import MainForm from "../quickscan/FormPage/Forms/MainForm";

import arrowLeft from "assets/img/arrowLeft.svg";
import arrowLeftGray from "assets/img/arrowLeftGray.svg";
import arrowRight from "assets/img/arrowRight.svg";
import arrowRightGray from "assets/img/arrowRightGray.svg";

const steps = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
];
const { formId, formField } = formModel;

function _renderStepContent(step, values, initialValues, t) {
  switch (step) {
    case 1:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.organisation}
          initialvalues={initialValues}
        />
      );
    case 2:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.financial}
          initialvalues={initialValues}
        />
      );
    case 3:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.system_audit}
          initialvalues={initialValues}
        />
      );
    case 4:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.process}
          initialvalues={initialValues}
        />
      );
    case 5:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.location}
          initialvalues={initialValues}
        />
      );
    case 6:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.capacity_planning}
          initialvalues={initialValues}
        />
      );
    case 7:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.product_competence}
          initialvalues={initialValues}
        />
      );
    case 8:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.development_competence}
          initialvalues={initialValues}
        />
      );
    case 9:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.productivity}
          initialvalues={initialValues}
        />
      );
    case 10:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.control}
          initialvalues={initialValues}
        />
      );
    case 11:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.s5}
          initialvalues={initialValues}
        />
      );
    case 12:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.visual_management}
          initialvalues={initialValues}
        />
      );
    case 13:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.standardized_work}
          initialvalues={initialValues}
        />
      );
    case 14:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.inventory}
          initialvalues={initialValues}
        />
      );
    case 15:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.tpm}
          initialvalues={initialValues}
        />
      );
    case 16:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.schedule}
          initialvalues={initialValues}
        />
      );
    case 17:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.flow}
          initialvalues={initialValues}
        />
      );
    case 18:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.cycle}
          initialvalues={initialValues}
        />
      );
    case 19:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.pull}
          initialvalues={initialValues}
        />
      );
    case 20:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.deviations}
          initialvalues={initialValues}
        />
      );
    case 21:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.line_stop_concept}
          initialvalues={initialValues}
        />
      );
    case 22:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.problem_solving}
          initialvalues={initialValues}
        />
      );
    case 23:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.machine_design}
          initialvalues={initialValues}
        />
      );
    case 24:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.line_design}
          initialvalues={initialValues}
        />
      );
    case 25:
      return (
        <MainForm
          formfield={formField}
          step={step}
          values={values}
          id={formField.factory_design}
          initialvalues={initialValues}
        />
      );

    default:
      return <div>{t("not found")}</div>;
  }
}

const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 40px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #00c9e9;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const StyledContainer = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 21px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 20px;
  }
`;

const StyledFormWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

const StyledButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 959px) {
    flex-direction: column;
  }
`;

const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 959px) {
    flex-direction: column;
  }
`;

const StyledButtonItem = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;

  @media (max-width: 959px) {
    margin-bottom: 10px;
  }
`;

const StyledButton = styled(Button)`
  background: #ffffff;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  padding: 10px 0;
  text-transform: none;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }

  &.save {
    border: 1px solid #00bfe9;
    color: #00bfe9;
  }

  &.cancel {
    border: 1px solid #ffae95;
    color: #ffae95;
  }

  @media (max-width: 959px) {
    margin-bottom: 10px;
  }
`;

const StyledPagination = styled(Pagination)`
  position: fixed;
  margin-left: 50px;

  .MuiPagination-ul {
    flex-direction: column;

    li {
      margin-bottom: 20px;
    }
  }

  .MuiPaginationItem-page {
    position: relative;
    height: 16px;
    width: 16px;
    min-width: 16px;
    background-color: #f8f8f8;
    border: 1px solid #bcbcbc;
    // transition: none !important;

    span {
      position: absolute;
      right: -40px;
      font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
      font-size: 16px;
      line-height: 17px;
      color: #000000;
    }

    &:hover {
      background-color: #f8f8f8 !important;
      opacity: 0.8;
    }

    &[type="red"] {
      background-color: #ff8a80 !important;

      &:hover {
        background-color: #ff8a80 !important;
      }
    }
    &[type="orange"] {
      background-color: #ffc2af !important;

      &:hover {
        background-color: #ffc2af !important;
      }
    }
    &[type="yellow"] {
      background-color: #ffedbe !important;

      &:hover {
        background-color: #ffedbe !important;
      }
    }
    &[type="blue"] {
      background-color: #06d7f9 !important;

      &:hover {
        background-color: #06d7f9 !important;
      }
    }
    &[type="green"] {
      background-color: #00e8a6 !important;

      &:hover {
        background-color: #00e8a6 !important;
      }
    }
  }

  .Mui-selected {
    background-color: #f8f8f8 !important;

    span {
      font-size: 24px;
      color: #00c9e9;
      right: -48px;
    }

    &:hover {
      background-color: #f8f8f8 !important;
      opacity: 0.8;
    }
  }

  [aria-label="Go to next page"],
  [aria-label="Go to previous page"] {
    background-color: none;
    border: none;
  }

  @media (max-width: 959px) {
    position: relative;
    margin-left: unset;
  }
`;

const Quickscan = view(() => {
  const [activeStep, setActiveStep] = useState(1);
  const currentValidationSchema = validationSchema[activeStep - 1];
  const isLastStep = activeStep === steps.length;
  const [companyId, setCompanyId] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  autoEffect(async () => {
    setInitialValues(null);

    if (!quickscan.quickscan) {
      await quickscan.getQuickscan();
    }
    if (quickscan.quickscan) {
      setCompanyId(quickscan.quickscan.company_id);
      setInitialValues(quickscan.quickscan);
    } else {
      setCompanyId(null);
      setInitialValues(formInitialValues);
    }
  });

  const handleCancel = () => {
    quickscan.quickscan = null;
  };

  async function _submitForm(values, actions) {
    if (companyId) {
      quickscan.editQuickscan(values, companyId);
    } else {
      quickscan.addQuickscan(values);
    }
    actions.setSubmitting(false);
  }

  function _handleSubmit(values, actions) {
    _submitForm(values, actions);
    actions.setSubmitting(false);
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
    quickscan.step = activeStep - 1;
  }

  function _handleNext() {
    setActiveStep(activeStep + 1);
    quickscan.step = activeStep + 1;
  }

  const handleChange = (event, value) => {
    setActiveStep(value);
    quickscan.step = value;
  };

  const PageItem = React.forwardRef((props, ref) => {
    const { item, currentvalue } = props;
    return (
      <div
        role="button"
        {...props}
        ref={ref}
        type={
          currentvalue === "1"
            ? "blue"
            : currentvalue === "2"
            ? "blue"
            : currentvalue === "3"
            ? "blue"
            : currentvalue === "4"
            ? "blue"
            : currentvalue === "5"
            ? "blue"
            : null
        }
      >
        {item["aria-label"] === "Go to next page" ? (
          <img src={arrowRightGray} alt="arrow right gray" />
        ) : item["aria-label"] === "Go to previous page" ? (
          <img src={arrowLeftGray} alt="arrow left gray" />
        ) : (
          <span>{item.page}</span>
        )}
      </div>
    );
  });

  const _renderTitle = (step) => {
    const quickscanTitleOne =
      step <= 2
        ? t('quickscan-level-1-title-1')
        : step <= 4
        ? t('quickscan-level-1-title-2')
        : step <= 6
        ? t('quickscan-level-1-title-3')
        : step <= 8
        ? t('quickscan-level-1-title-4')
        : step <= 10
        ? t('quickscan-level-1-title-5')
        : step <= 15
        ? t('quickscan-level-1-title-6')
        : step <= 19
        ? t('quickscan-level-1-title-7')
        : step <= 22
        ? t('quickscan-level-1-title-8')
        : t('quickscan-level-1-title-9');
  
    const quickscanTitleTwo =
      step <= 10 ? t('quickscan-level-2-title-1') : t('quickscan-level-2-title-2');
  
    return (
      <h2>
        {quickscanTitleOne} - {quickscanTitleTwo}
      </h2>
    );
  };

  const _renderPagination = () => {
    return (
      <StyledPagination
        count={25}
        siblingCount={5}
        page={activeStep}
        onChange={handleChange}
        renderItem={(item) => {
          const model = {
            1: "organisation",
            2: "financial",
            3: "system_audit",
            4: "process",
            5: "location",
            6: "capacity_planning",
            7: "product_competence",
            8: "development_competence",
            9: "productivity",
            10: "control",
            11: "s5",
            12: "visual_management",
            13: "standardized_work",
            14: "inventory",
            15: "tpm",
            16: "schedule",
            17: "flow",
            18: "cycle",
            19: "pull",
            20: "deviations",
            21: "line_stop_concept",
            22: "problem_solving",
            23: "machine_design",
            24: "line_design",
            25: "factory_design",
          };

          let modelItem = null;
          let currentValue = "";

          if (
            item["aria-label"] !== "Go to previous page" &&
            item["aria-label"] !== "Go to next page"
          ) {
            modelItem = model[item.page];
          }

          if (modelItem) {
            if (quickscan.tempValues) {
              currentValue = quickscan.tempValues[modelItem];
            } else {
              currentValue = initialValues[modelItem];
            }
          }

          return (
            <PaginationItem
              {...item}
              component={PageItem}
              item={item}
              currentvalue={currentValue}
            />
          );
        }}
      />
    );
  };

  autoEffect(() => {
    if (quickscan.tempValues) {
      _renderPagination();
    }
  });

  return !initialValues || quickscan.loading || company.loading ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : !company.company ? (
    <Fragment>
      <StyledIntro>
        <StyledIntroWrapper fixed>
          <Grid
            container
            justify={matches ? "space-between" : "flex-start"}
            alignItems={"center"}
            style={{ marginBottom: "40px" }}
          >
            <Grid item xs={12}>
              <h3>
                <Trans i18nKey="complete-step-one">
                  <StyledLink to="/company-details"></StyledLink>
                </Trans>
              </h3>
            </Grid>
          </Grid>
        </StyledIntroWrapper>
      </StyledIntro>
    </Fragment>
  ) : (
    <Fragment>
      <StyledContainer>
        <StyledFormWrapper fixed>
          <Grid container>
            <Grid item xs={12} md={10}>
              <Grid container>
                <Grid item xs={12}>
                  {_renderTitle(activeStep)}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                  >
                    {({ isSubmitting, values, resetForm }) => (
                      <Form id={formId}>
                        {_renderStepContent(
                          activeStep,
                          values,
                          initialValues,
                          t
                        )}

                        <StyledButtonWrapper>
                          <StyledButtonItem>
                            {activeStep !== 1 && (
                              <Button onClick={_handleBack}>
                                <img src={arrowLeft} alt="arrow left" />
                              </Button>
                            )}
                          </StyledButtonItem>

                          <StyledButtonContainer>
                            <StyledButton
                              className="cancel"
                              onClick={() => {
                                resetForm(initialValues);
                                handleCancel();
                              }}
                            >
                              {t("cancel")}
                            </StyledButton>
                            <StyledButton
                              disabled={isSubmitting}
                              type="submit"
                              className="save"
                            >
                              {t("save")}
                            </StyledButton>
                          </StyledButtonContainer>
                          <StyledButtonItem>
                            {!isLastStep && (
                              <Button onClick={_handleNext}>
                                <img src={arrowRight} alt="arrow right" />
                              </Button>
                            )}
                          </StyledButtonItem>
                          {isSubmitting && <Loader />}
                        </StyledButtonWrapper>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              {_renderPagination(activeStep)}
            </Grid>
          </Grid>
        </StyledFormWrapper>
      </StyledContainer>
    </Fragment>
  );
});

export default Quickscan;
