import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import './i18n/config';

import App from "./App";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    identityPoolRegion: process.env.REACT_APP_AWS_PROJECT_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    federationTarget: "COGNITO_USER_POOLS",
  }
  
});

// aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
//   aws_cognito_identity_pool_id:
//     process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
//   aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
//   aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
//   aws_user_pools_web_client_id:
//     process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
//   federationTarget: "COGNITO_USER_POOLS",

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
