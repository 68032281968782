import { store } from "@risingstack/react-easy-state";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import { AwsClient } from "aws4fetch";

import { API_ENDPOINT } from "../consts/globals";
// import { PAYMENT_ENDPOINT } from "../consts/globals";

import axios from "axios";

const abortController = new AbortController();
const signal = abortController.signal;

let aws;

export const auth = store({
  authState: null,
  user: null,
  creds: null,
  isLoggedIn: false,
  isLoading: true,
  errors: null,
  updateSuccess: false,
  passwordErrors: null,
  passwordUpdateSuccess: false,
  async getUser() {
    try {
      auth.isLoading = true;
      let user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      auth.user = user;
      await auth.getCredentials();
      await gdpr.getTerms();
      auth.isLoggedIn = true;
      auth.isLoading = false;
    } catch {
      auth.user = null;
      auth.isLoggedIn = false;
      auth.isLoading = false;
    }
  },
  async getCredentials() {
    const creds = await Auth.currentCredentials();
    auth.creds = creds;
    AWS.config.update({
      region: process.env.REACT_APP_AWS_USER_POOLS_REGION,
      accessKeyId: creds.accessKeyId,
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
    });

    aws = new AwsClient({
      accessKeyId: creds.accessKeyId,
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
    });
  },
  async uploadProfilePhoto(photo) {
    auth.errors = null;
    auth.updateSuccess = false;
    auth.isLoading = true;

    const params = {
      file: photo,
    };

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/medias`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.log(error);
      auth.errors = error;
      auth.updateSuccess = false;
      auth.isLoading = false;
    }
  },
  async updateUser(attributes) {
    auth.errors = null;
    auth.updateSuccess = false;
    auth.isLoading = true;

    let params = {
      AccessToken: auth.user.signInUserSession.accessToken.jwtToken,
      UserAttributes: [],
    };

    if (attributes.name) {
      params.UserAttributes.push({
        Name: "name",
        Value: attributes.name,
      });
    }
    if (attributes["custom:mobile_phone"]) {
      params.UserAttributes.push({
        Name: "custom:mobile_phone",
        Value: attributes["custom:mobile_phone"],
      });
    }
    if (attributes["custom:country"]) {
      params.UserAttributes.push({
        Name: "custom:country",
        Value: attributes["custom:country"],
      });
    }
    if (attributes["custom:zip"]) {
      params.UserAttributes.push({
        Name: "custom:zip",
        Value: attributes["custom:zip"],
      });
    }
    if (attributes["custom:job_function"]) {
      params.UserAttributes.push({
        Name: "custom:job_function",
        Value: attributes["custom:job_function"],
      });
    }
    if (attributes["custom:job_level"]) {
      params.UserAttributes.push({
        Name: "custom:job_level",
        Value: attributes["custom:job_level"],
      });
    }
    if (attributes["custom:discipline"]) {
      params.UserAttributes.push({
        Name: "custom:discipline",
        Value: attributes["custom:discipline"],
      });
    }
    if (attributes["custom:industry"]) {
      params.UserAttributes.push({
        Name: "custom:industry",
        Value: attributes["custom:industry"],
      });
    }
    if (attributes["custom:company_name"]) {
      params.UserAttributes.push({
        Name: "custom:company_name",
        Value: attributes["custom:company_name"],
      });
    }
    if (attributes["address"]) {
      params.UserAttributes.push({
        Name: "address",
        Value: attributes["address"],
      });
    }
    if (attributes["custom:city"]) {
      params.UserAttributes.push({
        Name: "custom:city",
        Value: attributes["custom:city"],
      });
    }

    if (attributes["custom:profile_photo"]) {
      const photoResponse = await auth.uploadProfilePhoto(
        attributes["custom:profile_photo"]
      );

      if (photoResponse.path) {
        params.UserAttributes.push({
          Name: "custom:profile_photo",
          Value: photoResponse.path,
        });
      }
    } else {
      params.UserAttributes.push({
        Name: "custom:profile_photo",
        Value: "",
      });
    }

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.updateUserAttributes(params, function (error, data) {
      if (error) {
        console.log(error);
        auth.errors = error;
        auth.updateSuccess = false;
        auth.isLoading = false;
      } else {
        auth.errors = null;
        auth.updateSuccess = true;
        auth.refreshToken();
        auth.getUser();
        auth.isLoading = false;
        auth.updateSuccess = false;
      }
    });
  },
  async changePassword(values) {
    auth.passwordErrors = null;
    auth.passwordUpdateSuccess = false;
    auth.isLoading = true;

    let params = {
      AccessToken: auth.user.signInUserSession.accessToken.jwtToken,
      PreviousPassword: values.current_password,
      ProposedPassword: values.repeat_password,
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.changePassword(params, function (error, data) {
      if (error) {
        console.log(error);
        auth.passwordErrors = error;
        auth.passwordUpdateSuccess = false;
        auth.isLoading = false;
      } else {
        auth.passwordErrors = null;
        auth.passwordUpdateSuccess = true;
        auth.refreshToken();
        auth.getUser();
        auth.isLoading = false;
        auth.passwordUpdateSuccess = false;
      }
    });
  },
  async refreshToken() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        async (err, session) => {
          // const { idToken, refreshToken, accessToken } = session;
          await auth.getUser();
        }
      );
    } catch (e) {
      console.log("Unable to refresh Token", e);
    }
  },
  async logout() {
    await Auth.signOut();
    auth.user = null;
    auth.isLoggedIn = false;
  },
});

export const location = store({
  prevLocation: null,
});

export const news = store({
  news: null,
  singleNews: null,
  async getNews() {
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/news`);

      if (response.status !== 200) {
        throw Error(response.statusText);
      }

      news.news = response.data.data;
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleNews(id) {
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/news/${id}`);

      if (response.status !== 200) {
        throw Error(response.statusText);
      }

      news.singleNews = response.data;
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
});

export const gdpr = store({
  terms: null,
  publicTerms: null,
  loading: false,
  async getPublicTerms() {
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/legal-terms`);

      gdpr.publicTerms = response.data.data;
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getTerms() {
    gdpr.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/legal-terms/check-user`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      gdpr.terms = responseJson.data;
      gdpr.loading = false;
    } catch (error) {
      gdpr.loading = false;
      console.log(error);
    }
  },
  async acceptTerms(values) {
    gdpr.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    let terms = {
      accepted: [],
      rejected: [],
    };

    Object.keys(values).map((key) => {
      if (values[key]) {
        terms.accepted.push(key);
      } else {
        terms.rejected.push(key);
      }

      return terms;
    });

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/legal-terms/update-accepted`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(terms),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      await gdpr.getTerms();
      // gdpr.loading = false;
    } catch (error) {
      // gdpr.loading = false;
      console.log(error);
    }
  },
});

export const contact = store({
  loading: false,
  errors: null,
  sent: false,
  async sendMessage(receiverId, formValues) {
    contact.sent = false;
    contact.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    const values = {
      reciever_company_id: receiverId,
      sender_mail: auth.user.attributes.email,
      message: formValues.message,
    };

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/company-message`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        contact.error = true;
        contact.loading = false;
        contact.sent = false;
        throw Error(response.statusText);
      }

      await response.json();

      contact.error = false;
      contact.loading = false;
      contact.sent = true;
    } catch (error) {
      contact.error = true;
      contact.loading = false;
      contact.sent = false;
      console.log(error);
    }
  },
});

export const support = store({
  loading: false,
  errors: null,
  sent: false,
  async sendSupport(formValues) {
    support.sent = false;
    support.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    const values = {
      user_email: auth.user.attributes.email,
      topic: formValues.topic,
      message: formValues.message,
    };

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/support-mail`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        support.error = true;
        support.loading = false;
        support.sent = false;
        throw Error(response.statusText);
      }

      await response.json();

      support.error = false;
      support.loading = false;
      support.sent = true;
    } catch (error) {
      support.error = true;
      support.loading = false;
      support.sent = false;
      console.log(error);
    }
  },
});

export const countries = store({
  countries: null,
  loading: false,
  async getCountries() {
    countries.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/countries`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      countries.countries = responseJson.data;
      countries.loading = false;
    } catch (error) {
      countries.loading = false;
      console.log(error);
    }
  },
});

export const cities = store({
  cities: null,
  filteredCities: null,
  loading: false,
  errors: null,
  sent: false,
  async createCity(formValues) {
    cities.sent = false;
    cities.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    const values = {
      country_id: formValues.country_id,
      name: formValues.city,
      postal_code: formValues.postal_code,
    };

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/cities`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        cities.error = true;
        cities.loading = false;
        cities.sent = false;
        throw Error(response.statusText);
      }

      await response.json();

      cities.error = false;
      cities.loading = false;
      cities.sent = true;
    } catch (error) {
      cities.error = true;
      cities.loading = false;
      cities.sent = false;
      console.log(error);
    }
  },
  async getCities() {
    cities.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/cities`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      cities.cities = responseJson.data;
      cities.loading = false;
    } catch (error) {
      cities.loading = false;
      console.log(error);
    }
  },
  async getCitiesByCountry(country) {
    cities.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/countries/${country}?include=cities`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      cities.filteredCities = responseJson;
      cities.loading = false;
    } catch (error) {
      cities.loading = false;
      console.log(error);
    }
  },
});

export const selfAssessment = store({
  selfAssessment: null,
  loading: false,
  async getSelfAssessment() {
    selfAssessment.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/steps-assessment`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      selfAssessment.selfAssessment = responseJson;
      selfAssessment.loading = false;
    } catch (error) {
      selfAssessment.loading = false;
      console.log(error);
    }
  },
});

export const company = store({
  company: null,
  allCompanies: null,
  specificCompany: null,
  loading: false,
  async getCompanies() {
    company.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/companies`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      company.allCompanies = responseJson.data;
      company.loading = false;
    } catch (error) {
      company.loading = false;
      console.log(error);
    }
  },
  async getCompany() {
    company.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/usercompany?include=country,city,certificates,productionLocations,categories`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      // if (!response.ok) {
      //   throw Error(response.statusText);
      // }

      const responseJson = await response.json();

      if (responseJson.errors) {
        // if (!company.allCompanies) {
        //   await company.getCompanies();
        // }
        // if (!countries.countries) {
        //   await countries.getCountries();
        // }
        // if (!cities.cities) {
        //   await cities.getCities();
        // }
        company.loading = false;
        return;
      } else {
        let convertedValues = Object.fromEntries(
          // convert to array, map, and then fromEntries gives back the object
          Object.entries(responseJson).map(([key, value]) => {
            if (
              value === null ||
              (Object.keys(value).length === 0 && value.constructor === Object)
            ) {
              value = "";
            }
            return [key, value];
          })
        );

        if (convertedValues.Country.id) {
          convertedValues.country_id = convertedValues.Country.id;
        }
        if (convertedValues.City.id) {
          convertedValues.city_id = convertedValues.City.id;
        }

        convertedValues.certificates = responseJson.Certificates.filter(
          (certificate) => {
            return (
              certificate.name !== "iso" &&
              certificate.number !== "14001" &&
              certificate.name !== "iso" &&
              certificate.number !== "9001" &&
              certificate.name !== "iso" &&
              certificate.number !== "9001:2015" &&
              certificate.name !== "ohsas" &&
              certificate.number !== "18001"
            );
          }
        );

        convertedValues.production_locations =
          responseJson.Production_locations;

        const filteredISO9001 = convertedValues.Certificates.find(
          (certificate) => {
            return certificate.name === "iso" && certificate.number === "9001";
          }
        );
        if (filteredISO9001) {
          convertedValues.iso9001 = true;
          convertedValues.iso9001ExpiryDate = filteredISO9001.expiry_date;
        } else {
          convertedValues.iso9001 = false;
        }

        const filteredISO9001_2015 = convertedValues.Certificates.find(
          (certificate) => {
            return (
              certificate.name === "iso" && certificate.number === "9001:2015"
            );
          }
        );
        if (filteredISO9001_2015) {
          convertedValues.iso9001_2015 = true;
          convertedValues.iso9001_2015ExpiryDate =
            filteredISO9001_2015.expiry_date;
        } else {
          convertedValues.iso9001_2015 = false;
        }

        const filteredISO14001 = convertedValues.Certificates.find(
          (certificate) => {
            return certificate.name === "iso" && certificate.number === "14001";
          }
        );
        if (filteredISO14001) {
          convertedValues.iso14001 = true;
          convertedValues.iso14001ExpiryDate = filteredISO14001.expiry_date;
        } else {
          convertedValues.iso14001 = false;
        }

        const filteredOHSAS18001 = convertedValues.Certificates.find(
          (certificate) => {
            return (
              certificate.name === "ohsas" && certificate.number === "18001"
            );
          }
        );
        if (filteredOHSAS18001) {
          convertedValues.ohsas18001 = true;
          convertedValues.ohsas18001ExpiryDate = filteredOHSAS18001.expiry_date;
        } else {
          convertedValues.ohsas18001 = false;
        }

        convertedValues.photos = [];

        company.company = convertedValues;
        // if (!company.allCompanies) {
        //   await company.getCompanies();
        // }
        // if (!countries.countries) {
        //   await countries.getCountries();
        // }
        // if (!cities.cities) {
        //   await cities.getCities();
        // }
        company.loading = false;
      }
    } catch (error) {
      company.loading = false;
      console.log(error);
    }
  },
  async addCompany(values) {
    company.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    const convertedValues = {
      name: values.name || null,
      industry: values.industry || null,
      company_type: values.company_type || null,
      parent_company: values.parent_company || null,
      number_of_employees: parseInt(values.number_of_employees) || null,
      turnover: parseInt(values.turnover) || null,
      duns_number: parseInt(values.duns_number) || null,
      tax_jurisdiction_code: parseInt(values.tax_jurisdiction_code) || null,
      supplier_type: values.supplier_type || null,
      communication_language: values.communication_language || null,
      country_id: values.country_id || null,
      city_id: values.city_id || null,
      postcode: values.postcode || null,
      address: values.address || null,
      phone: parseInt(values.phone) || null,
      email: values.email || null,
      website: values.website || null,
      short_description: values.short_description || null,
      certificates: values.certificates,
      production_locations: values.production_locations,
      company_presentation: values.company_presentation || null,
      reference_list: values.reference_list || null,
      logo: values.logo || null,
      vat_id: values.vat_id || null,
      // medias: values.photos,
    };

    if (values.iso9001) {
      const certificate = {
        name: "iso",
        number: "9001",
        expiry_date: values.iso9001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.iso9001_2015) {
      const certificate = {
        name: "iso",
        number: "9001:2015",
        expiry_date: values.iso9001_2015ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.iso14001) {
      const certificate = {
        name: "iso",
        number: "14001",
        expiry_date: values.iso14001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.ohsas18001) {
      const certificate = {
        name: "ohsas",
        number: "18001",
        expiry_date: values.ohsas18001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/stepone`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(convertedValues),
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      await company.getCompany();
    } catch (error) {
      company.company = null;
      console.log(error);
    }
  },
  async editCompany(values, id) {
    company.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    const convertedValues = {
      name: values.name || null,
      industry: values.industry || null,
      company_type: values.company_type || null,
      parent_company: values.parent_company || null,
      number_of_employees: parseInt(values.number_of_employees) || null,
      turnover: parseInt(values.turnover) || null,
      duns_number: parseInt(values.duns_number) || null,
      tax_jurisdiction_code: parseInt(values.tax_jurisdiction_code) || null,
      supplier_type: values.supplier_type || null,
      communication_language: values.communication_language || null,
      country_id: values.country_id || null,
      city_id: values.city_id || null,
      postcode: values.postcode || null,
      address: values.address || null,
      phone: parseInt(values.phone) || null,
      email: values.email || null,
      website: values.website || null,
      short_description: values.short_description || null,
      certificates: values.certificates,
      production_locations: values.production_locations,
      company_presentation: values.company_presentation || null,
      reference_list: values.reference_list || null,
      logo: values.logo || null,
      vat_id: values.vat_id || null,
      // medias: values.photos,
    };

    if (values.iso9001) {
      const certificate = {
        name: "iso",
        number: "9001",
        expiry_date: values.iso9001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.iso9001_2015) {
      const certificate = {
        name: "iso",
        number: "9001:2015",
        expiry_date: values.iso9001_2015ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.iso14001) {
      const certificate = {
        name: "iso",
        number: "14001",
        expiry_date: values.iso14001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    if (values.ohsas18001) {
      const certificate = {
        name: "ohsas",
        number: "18001",
        expiry_date: values.ohsas18001ExpiryDate || null,
      };

      convertedValues.certificates.push(certificate);
    }

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/stepone/${id}`, {
        signal: signal,
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(convertedValues),
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      // await company.getCompany();
    } catch (error) {
      company.company = null;
      console.log(error);
    }
  },
  async getSpecificCompany(id) {
    company.loading = true;
    company.specificCompany = null;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/companies/${id}?include=categories,medias,quickScanOrder,country,certificates,city`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      company.specificCompany = responseJson;
      company.loading = false;
    } catch (error) {
      company.specificCompany = null;
      company.loading = false;
      console.log(error);
    }
  },
});

export const lean = store({
  lean: null,
  loading: false,
  async getLean() {
    lean.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    if (company.company) {
      try {
        const response = await aws.fetch(
          `${API_ENDPOINT}/system/company-questions/${company.company.id}`,
          {
            signal: signal,
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              "X-User-Sub": auth.user.attributes.sub,
            },
          }
        );

        if (!response.ok) {
          throw Error(response.statusText);
        }

        const responseJson = await response.json();

        if (responseJson.errors) {
          lean.loading = false;
          return;
        } else {
          let convertedValues = Object.fromEntries(
            // convert to array, map, and then fromEntries gives back the object
            Object.entries(responseJson).map(([key, value]) => {
              if (value === null) {
                value = "no answer";
              }
              return [key, value];
            })
          );

          selfAssessment.selfAssessment = null;
          await selfAssessment.getSelfAssessment();
          lean.lean = convertedValues;
          lean.loading = false;
        }
      } catch (error) {
        lean.loading = false;
        console.log(error);
      }
    } else {
      lean.loading = false;
    }
  },
  async addLean(values) {
    lean.loading = true;

    let convertedValues = Object.fromEntries(
      // convert to array, map, and then fromEntries gives back the object
      Object.entries(values).map(([key, value]) => {
        if (value === "no answer") {
          value = null;
        } else {
          value = parseInt(value);
        }
        return [key, value];
      })
    );

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    convertedValues.company_id = company.company.id;

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/company-questions`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(convertedValues),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      lean.lean = null;
      await lean.getLean();
    } catch (error) {
      lean.lean = null;
      console.log(error);
    }
  },
  async editLean(values, id) {
    lean.loading = true;

    // let convertedValues = Object.fromEntries(
    //   // convert to array, map, and then fromEntries gives back the object
    //   Object.entries(values).filter(([key, value]) => {
    //     // if (value === "no answer") {
    //     //   value = null;
    //     // }
    //     return value !== "no answer" ? [key, value] : null;
    //   })
    // );

    let convertedValues = Object.fromEntries(
      // convert to array, map, and then fromEntries gives back the object
      Object.entries(values).map(([key, value]) => {
        if (value === "no answer") {
          value = null;
        } else {
          value = parseInt(value);
        }
        return [key, value];
      })
    );

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/company-questions/${id}`,
        {
          signal: signal,
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(convertedValues),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      lean.lean = null;
      await lean.getLean();
    } catch (error) {
      lean.lean = null;
      console.log(error);
    }
  },
});

export const quickscan = store({
  quickscan: null,
  specificQuickscan: null,
  adminQuickscan: null,
  loading: true,
  tempValues: null,
  specificTempValues: null,
  error: false,
  step: 1,
  userQuickscans: null,
  async getQuickscan() {
    quickscan.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    if (company.company) {
      try {
        const response = await aws.fetch(
          `${API_ENDPOINT}/system/quick-scan/${company.company.id}`,
          {
            signal: signal,
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              "X-User-Sub": auth.user.attributes.sub,
            },
          }
        );

        if (!response.ok) {
          throw Error(response.statusText);
        }

        const responseJson = await response.json();

        if (responseJson.errors) {
          quickscan.loading = false;
          return;
        } else {
          let convertedValues = Object.fromEntries(
            // convert to array, map, and then fromEntries gives back the object
            Object.entries(responseJson).map(([key, value]) => {
              if (value === null) {
                value = "";
              } else {
                value = value.toString();
              }
              return [key, value];
            })
          );

          selfAssessment.selfAssessment = null;
          await selfAssessment.getSelfAssessment();
          quickscan.tempValues = convertedValues;
          quickscan.quickscan = convertedValues;
          quickscan.loading = false;
        }
      } catch (error) {
        quickscan.loading = false;
        console.log(error);
      }
    } else {
      quickscan.loading = false;
    }
  },
  async getSpecificQuickscan(id) {
    quickscan.specificQuickscan = null;
    quickscan.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.specificCompany) {
      await company.getSpecificCompany(id);
    }

    if (company.specificCompany) {
      try {
        const response = await aws.fetch(
          `${API_ENDPOINT}/system/quick-scan/${company.specificCompany.id}`,
          {
            signal: signal,
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              "X-User-Sub": auth.user.attributes.sub,
            },
          }
        );

        if (!response.ok) {
          throw Error(response.statusText);
        }

        const responseJson = await response.json();

        if (responseJson.errors) {
          quickscan.loading = false;
          return;
        } else {
          let convertedValues = Object.fromEntries(
            // convert to array, map, and then fromEntries gives back the object
            Object.entries(responseJson).map(([key, value]) => {
              if (value === null) {
                value = "";
              } else {
                value = value.toString();
              }
              return [key, value];
            })
          );

          quickscan.specificTempValues = convertedValues;
          quickscan.specificQuickscan = convertedValues;
          quickscan.loading = false;
        }
      } catch (error) {
        quickscan.loading = false;
        console.log(error);
      }
    } else {
      quickscan.loading = false;
    }
  },
  async getAdminQuickscan(id) {
    quickscan.adminQuickscan = null;
    quickscan.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    // if (!company.specificCompany) {
    //   await company.getSpecificCompany(id);
    // }

    if (id) {
      try {
        const response = await aws.fetch(
          `${API_ENDPOINT}/system/admin-quick-scan/${id}?include=quickScanOrder`,
          {
            signal: signal,
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              "X-User-Sub": auth.user.attributes.sub,
            },
          }
        );

        if (!response.ok) {
          throw Error(response.statusText);
        }

        const responseJson = await response.json();

        if (responseJson.errors) {
          quickscan.loading = false;
          return;
        } else {
          let convertedValues = Object.fromEntries(
            // convert to array, map, and then fromEntries gives back the object
            Object.entries(responseJson).map(([key, value]) => {
              if (key === "QuickScanOrder") {
              } else {
                if (value === null) {
                  value = "";
                } else {
                  value = value.toString();
                }
              }

              return [key, value];
            })
          );

          // quickscan.specificTempValues = convertedValues;
          quickscan.adminQuickscan = convertedValues;
          quickscan.loading = false;
        }
      } catch (error) {
        quickscan.loading = false;
        console.log(error);
      }
    } else {
      quickscan.loading = false;
    }
  },
  async addQuickscan(values) {
    quickscan.loading = true;

    let convertedValues = Object.fromEntries(
      // convert to array, map, and then fromEntries gives back the object
      Object.entries(values).map(([key, value]) => {
        if (value === "") {
          value = null;
        } else {
          value = parseInt(value);
        }
        return [key, value];
      })
    );

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    convertedValues.company_id = company.company.id;

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/quick-scan`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(convertedValues),
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      quickscan.quickscan = null;
      await quickscan.getQuickscan();
    } catch (error) {
      quickscan.quickscan = null;
      console.log(error);
    }
  },
  async editQuickscan(values, id) {
    quickscan.loading = true;

    // let convertedValues = Object.fromEntries(
    //   // convert to array, map, and then fromEntries gives back the object
    //   Object.entries(values).filter(([key, value]) => {
    //     // if (value === "no answer") {
    //     //   value = null;
    //     // }
    //     return value !== "no answer" ? [key, value] : null;
    //   })
    // );

    let convertedValues = Object.fromEntries(
      // convert to array, map, and then fromEntries gives back the object
      Object.entries(values).map(([key, value]) => {
        if (value === "") {
          value = null;
        } else {
          value = parseInt(value);
        }
        return [key, value];
      })
    );

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/quick-scan/${id}`,
        {
          signal: signal,
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(convertedValues),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      quickscan.quickscan = null;
      await quickscan.getQuickscan();
    } catch (error) {
      quickscan.quickscan = null;
      console.log(error);
    }
  },
  async orderQuickscan(id) {
    quickscan.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    const values = { company_id: id, visible: true };

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/quick-scan-order`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        quickscan.error = true;
        throw Error(response.statusText);
      }

      await response.json();

      quickscan.error = false;
      quickscan.loading = false;
    } catch (error) {
      quickscan.error = true;
      quickscan.loading = false;
      console.log(error);
    }
  },
  async getUserQuickscans() {
    quickscan.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/ordered-quickscans?include=company`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      quickscan.userQuickscans = responseJson.data;
      quickscan.loading = false;
    } catch (error) {
      quickscan.loading = false;
      console.log(error);
    }
  },
});

export const supplierProducts = store({
  segments: null,
  families: null,
  classes: null,
  commodities: null,
  supplierProducts: null,
  loading: false,
  sideLoading: false,
  async getSegments() {
    supplierProducts.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/categories-segment`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      if (responseJson.errors) {
        supplierProducts.loading = false;
        return;
      } else {
        supplierProducts.segments = responseJson.data;
        supplierProducts.loading = false;
      }
    } catch (error) {
      supplierProducts.loading = false;
      console.log(error);
    }
  },
  async getFamilies(segment) {
    supplierProducts.sideLoading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/categories-segment/${segment}?include=family`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      if (responseJson.errors) {
        supplierProducts.sideLoading = false;
        return;
      } else {
        supplierProducts.families = responseJson.CategoryFamily;
        supplierProducts.sideLoading = false;
      }
    } catch (error) {
      supplierProducts.sideLoading = false;
      console.log(error);
    }
  },
  async getClasses(family) {
    supplierProducts.sideLoading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/categories-family/${family}?include=class`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      if (responseJson.errors) {
        supplierProducts.sideLoading = false;
        return;
      } else {
        supplierProducts.classes = responseJson.CategoryClass;
        supplierProducts.sideLoading = false;
      }
    } catch (error) {
      supplierProducts.sideLoading = false;
      console.log(error);
    }
  },
  async getCommodities(pclass) {
    supplierProducts.sideLoading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/categories-class/${pclass}?include=commodity`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      if (responseJson.errors) {
        supplierProducts.sideLoading = false;
        return;
      } else {
        supplierProducts.commodities = responseJson.CategoryCommodity;
        supplierProducts.sideLoading = false;
      }
    } catch (error) {
      supplierProducts.sideLoading = false;
      console.log(error);
    }
  },
  async searchCommodities(input) {
    supplierProducts.sideLoading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/commodity-search?filter-commodity=${input}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      if (responseJson.errors) {
        supplierProducts.sideLoading = false;
        return;
      } else {
        supplierProducts.sideLoading = false;
        return responseJson.data;
      }
    } catch (error) {
      supplierProducts.sideLoading = false;
      console.log(error);
    }
  },
  async getSupplierProducts() {
    supplierProducts.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    if (company.company) {
      try {
        const response = await aws.fetch(
          `${API_ENDPOINT}/system/company-categories/${company.company.id}?include=categories`,
          {
            signal: signal,
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              "X-User-Sub": auth.user.attributes.sub,
            },
          }
        );

        if (!response.ok) {
          throw Error(response.statusText);
        }

        const responseJson = await response.json();

        if (responseJson.errors) {
          supplierProducts.loading = false;
          return;
        } else {
          supplierProducts.supplierProducts = responseJson.data;
          supplierProducts.loading = false;
        }
      } catch (error) {
        supplierProducts.loading = false;
        console.log(error);
      }
    } else {
      supplierProducts.loading = false;
    }
  },
  async addSupplierProducts(values) {
    supplierProducts.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    values.company_id = company.company.id;

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/company-categories`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      setTimeout(() => {
        supplierProducts.supplierProducts = null;
        supplierProducts.getSupplierProducts();
      }, 1000);
    } catch (error) {
      supplierProducts.supplierProducts = null;
      console.log(error);
    }
  },
  async editSupplierProducts(values, id) {
    supplierProducts.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/company-categories/${id}`,
        {
          signal: signal,
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      supplierProducts.supplierProducts = null;
      await supplierProducts.getSupplierProducts();
    } catch (error) {
      supplierProducts.supplierProducts = null;
      console.log(error);
    }
  },
});

export const report = store({
  report: null,
  specificReport: null,
  adminReport: null,
  loading: false,
  async getReport(id) {
    report.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/assessment/${company.company.id}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      report.report = responseJson;
      report.loading = false;
    } catch (error) {
      report.loading = false;
      console.log(error);
    }
  },
  async getSpecificReport(id) {
    report.specificReport = null;
    report.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.specificCompany) {
      await company.getSpecificCompany(id);
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/assessment/${company.specificCompany.id}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      report.specificReport = responseJson;
      report.loading = false;
    } catch (error) {
      report.loading = false;
      console.log(error);
    }
  },
  async getAdminReport(id) {
    report.adminReport = null;
    report.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    // if (!company.specificCompany) {
    //   await company.getSpecificCompany(id);
    // }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/admin-assessment/${id}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      report.adminReport = responseJson;
      report.loading = false;
    } catch (error) {
      report.loading = false;
      console.log(error);
    }
  },
});

export const customSearch = store({
  response: null,
  loading: false,
  result: null,
  async postCustomSearch(data) {
    customSearch.loading = true;
    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/custom-search`, {
        signal: signal,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
      });
      const responseJson = await response.json();

      customSearch.response = responseJson;
      customSearch.loading = false;
    } catch (error) {
      customSearch.loading = false;
      console.log(error);
    }
  },
  async getCustomSearches() {
    customSearch.loading = true;

    // if (!company.company) {
    //   await company.getCompany();
    // }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/user-custom-searches`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );
      const responseJson = await response.json();

      customSearch.list = responseJson;
      customSearch.loading = false;
    } catch (error) {
      customSearch.loading = false;
      console.log(error);
    }
  },
  async getCustomSearchResults(id) {
    customSearch.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/custom-search-result/${id}?include=company,customSearch`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      customSearch.result = responseJson.data;
      customSearch.loading = false;
      return responseJson.data;
    } catch (error) {
      console.log(error);
      customSearch.loading = false;
    }
  },
});

export const search = store({
  results: null,
  pagination: null,
  suggestions: null,
  loading: false,
  async getSuggestions(input) {
    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/commodities?filter-commodity_name=${input}`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      search.suggestions = responseJson;
      return responseJson;
    } catch (error) {
      console.log(error);
    }
  },
  async getSearchResults(input, region, page) {
    search.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        region !== "all"
          ? `${API_ENDPOINT}/system/search?filter-term=${input}&page-size=20&page-number=${page}&filter-country_id=${region}&include=categories,quickScanOrder,certificates,country,city`
          : `${API_ENDPOINT}/system/search?filter-term=${input}&page-size=20&page-number=${page}&include=categories,quickScanOrder,certificates,country,city`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      search.results = responseJson.data;
      search.pagination = responseJson.meta;
      search.loading = false;
      return responseJson.data;
    } catch (error) {
      console.log(error);
      search.loading = false;
    }
  },
});

export const favorites = store({
  favorites: null,
  loading: false,
  error: false,
  userFavorites: null,
  async addFavorite(id) {
    favorites.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    const values = { company_id: id };

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/favorite`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        favorites.error = true;
        throw Error(response.statusText);
      }

      await response.json();

      favorites.error = false;
      favorites.loading = false;
    } catch (error) {
      favorites.error = true;
      favorites.loading = false;
      console.log(error);
    }
  },
  async removeFavorite(id) {
    favorites.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/favorite/${id}`,
        {
          signal: signal,
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        favorites.error = true;
        throw Error(response.statusText);
      }

      await response.json();

      favorites.error = false;
      favorites.loading = false;
    } catch (error) {
      favorites.error = true;
      favorites.loading = false;
      console.log(error);
    }
  },
  async getUserFavorites() {
    favorites.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/favorite?include=company`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      favorites.userFavorites = responseJson.data;
      favorites.loading = false;
    } catch (error) {
      favorites.loading = false;
      console.log(error);
    }
  },
});

export const longlists = store({
  longlists: null,
  loading: false,
  error: false,
  async addToLonglist(id, name) {
    longlists.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    const values = { seller_id: id, term: name };

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/long-lists/add-to-long-list`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        longlists.error = true;
        throw Error(response.statusText);
      }

      await response.json();

      longlists.error = false;
      longlists.loading = false;
    } catch (error) {
      longlists.error = true;
      longlists.loading = false;
      console.log(error);
    }
  },
  async removeFromLonglist(id, name) {
    longlists.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    const values = { seller_id: id, term: name };

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/long-lists/remove-from-long-list`,
        {
          signal: signal,
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        longlists.error = true;
        throw Error(response.statusText);
      }

      await response.json();

      longlists.error = false;
      longlists.loading = false;
    } catch (error) {
      longlists.error = true;
      longlists.loading = false;
      console.log(error);
    }
  },
});

export const rfi = store({
  loading: false,
  errors: null,
  sent: false,
  rfi: null,
  async sendRFI(receiverId, formValues) {
    rfi.errors = false;
    rfi.sent = false;
    rfi.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    const values = {
      ...formValues,
      supplier_id: receiverId,
    };

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/rfi`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        rfi.errors = true;
        rfi.loading = false;
        rfi.sent = false;
        throw Error(response.statusText);
      }

      await response.json();

      rfi.errors = false;
      rfi.loading = false;
      rfi.sent = true;
    } catch (error) {
      rfi.errors = true;
      rfi.loading = false;
      rfi.sent = false;
      console.log(error);
    }
  },
  async getRFI(id) {
    rfi.rfi = null;
    rfi.errors = false;
    rfi.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    // if (!company.company) {
    //   await company.getCompany();
    // }

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/rfi/${id}`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
      });

      const responseJson = await response.json();

      if (!response.ok) {
        rfi.errors = true;
        rfi.loading = false;
        rfi.rfi = null;
        throw Error(response.statusText);
      }

      rfi.errors = false;
      rfi.loading = false;
      rfi.rfi = responseJson;
      return responseJson;
    } catch (error) {
      rfi.errors = true;
      rfi.loading = false;
      rfi.rfi = null;
      console.log(error);
    }
  },
});

export const dashboards = store({
  buyerDashboard: null,
  supplierDashboard: null,
  errors: null,
  loading: false,
  async getBuyerDashboard() {
    dashboards.buyerDashboard = null;
    dashboards.errors = false;
    dashboards.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    // if (!company.company) {
    //   await company.getCompany();
    // }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/buyer-dashboard`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        dashboards.errors = true;
        dashboards.loading = false;
        dashboards.buyerDashboard = null;
        throw Error(response.statusText);
      }

      dashboards.errors = false;
      dashboards.loading = false;
      dashboards.buyerDashboard = responseJson;
      return responseJson;
    } catch (error) {
      dashboards.errors = true;
      dashboards.loading = false;
      dashboards.buyerDashboard = null;
      console.log(error);
    }
  },
  async getSupplierDashboard() {
    dashboards.supplierDashboard = null;
    dashboards.errors = false;
    dashboards.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    if (!company.company) {
      await company.getCompany();
    }

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/supplier-dashboard`,
        {
          signal: signal,
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "X-User-Sub": auth.user.attributes.sub,
          },
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        dashboards.errors = true;
        dashboards.loading = false;
        dashboards.supplierDashboard = null;
        throw Error(response.statusText);
      }

      dashboards.errors = false;
      dashboards.loading = false;
      dashboards.supplierDashboard = responseJson;
      return responseJson;
    } catch (error) {
      dashboards.errors = true;
      dashboards.loading = false;
      dashboards.supplierDashboard = null;
      console.log(error);
    }
  },
});

export const searchFilters = store({
  filters: null,
});

export const language = store({
  languages: ["en", "ru", "de"],
  language: "en",
  async getLanguage() {
    const storedLang = localStorage.getItem("language");
    if (storedLang) {
      language.language = storedLang;
    }
  },
  async setLanguage(lang) {
    localStorage.setItem("language", lang);
    language.language = lang;
  },
});

export const invite = store({
  loading: false,
  errors: null,
  sent: false,
  async sendInvite(formValues) {
    invite.sent = false;
    invite.loading = true;

    if (!aws) {
      await auth.getUser();
    }

    const values = {
      email: formValues.email,
    };

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/invite-user`, {
        signal: signal,
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "X-User-Sub": auth.user.attributes.sub,
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        invite.error = true;
        invite.loading = false;
        invite.sent = false;
        throw Error(response.statusText);
      }

      await response.json();

      invite.error = false;
      invite.loading = false;
      invite.sent = true;
    } catch (error) {
      invite.error = true;
      invite.loading = false;
      invite.sent = false;
      console.log(error);
    }
  },
});
