import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { auth } from "stores/BaseStore";
import { view } from "@risingstack/react-easy-state";
import { useTranslation } from "react-i18next";

const StyledFooterWrapper = styled(Grid)`
  // position: fixed;
  // z-index: 3;
  // bottom: 0;
  // left: 0;
  padding: 56px 0 56px 0;
  background-color: #f3f8f9;

  // @media (max-width: 959px) {
  //   position: relative;
  //   background-color: #161e20;
  // }
`;

const StyledFooterInner = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 959px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledLink = styled(Link)`
  all: unset;
  cursor: pointer;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.117647px;
  margin-right: 40px;

  &:hover {
    opacity: 0.8;
  }

  &.active {
    color: #06d6f8;
  }

  @media (max-width: 959px) {
    margin-right: 20px;
  }
`;

const GdprFooter = view(() => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <StyledFooterWrapper container>
      <Container fixed>
        <Grid item xs={12}>
          <StyledFooterInner>
            <StyledLink
              to="/terms-and-conditions"
              className={
                location.pathname === "/terms-and-conditions" ? "active" : null
              }
            >
              {t("terms and conditions")}
            </StyledLink>
            <StyledLink
              to="/privacy"
              className={location.pathname === "/privacy" ? "active" : null}
            >
              {t("privacy policy")}
            </StyledLink>
            <StyledLink
              to="/impressum"
              className={location.pathname === "/impressum" ? "active" : null}
            >
              {t("impressum")}
            </StyledLink>
            <StyledLink
              to="/login"
              className={location.pathname === "/login" ? "active" : null}
            >
              {auth.user ? t("home") : t("login")}
            </StyledLink>
          </StyledFooterInner>
        </Grid>
      </Container>
    </StyledFooterWrapper>
  );
});

export default GdprFooter;
