import React, { Fragment, useState } from "react";
import { Button } from "@material-ui/core";
import { Formik, Form } from "formik";
import { Box, Container, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { autoEffect, view } from "@risingstack/react-easy-state";
import { company, lean } from "stores/BaseStore";

import Loader from "components/shared/Loader";
import MainForm from "../lean/FormPage/Forms/MainForm";

import validationSchema from "../lean/FormPage/FormModel/validationSchema";
import formModel from "../lean/FormPage/FormModel/formModel";
import formInitialValues from "../lean/FormPage/FormModel/formInitialValues";

const { formId, formField } = formModel;

const StyledIntro = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "RobotoRegular", "Arial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-bottom: 40px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #00c9e9;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledIntroWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

const StyledContainer = styled(Box)`
  position: relative;
  z-index: 2;
  padding-top: 62px;

  h2 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-family: "NeueMachinaUltrabold", "Arial", sans-serif;
    font-size: 21px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #00c9e9;
    margin: 0;
    margin-bottom: 20px;
  }
`;

const StyledFormWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  padding-bottom: 40px;
`;

const StyledButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #00bfe9;
  border-radius: 0;
  font-family: "RobotoRegular", "Arial", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00bfe9;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-transform: none;
  padding: 10px 40px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StyledSpacer = styled(Box)`
  min-height: 100vh;
  width: 100%;
`;

const Lean = view(() => {
  const currentValidationSchema = validationSchema[0];
  const [companyId, setCompanyId] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  autoEffect(async () => {
    setInitialValues(null);

    if (!lean.lean) {
      await lean.getLean();
    }
    if (lean.lean) {
      setCompanyId(lean.lean.company_id);
      setInitialValues(lean.lean);
    } else {
      setCompanyId(null);
      setInitialValues(formInitialValues);
    }
  });

  // function _sleep(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }

  async function _submitForm(values, actions) {
    if (companyId) {
      lean.editLean(values, companyId);
    } else {
      lean.addLean(values);
    }
    // alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);
  }

  function _handleSubmit(values, actions) {
    _submitForm(values, actions);
  }

  return !initialValues || lean.loading || company.loading ? (
    <Fragment>
      <StyledSpacer />
      <Loader />
    </Fragment>
  ) : !company.company ? (
    <Fragment>
      <StyledIntro>
        <StyledIntroWrapper fixed>
          <Grid
            container
            justify={matches ? "space-between" : "flex-start"}
            alignItems={"center"}
            style={{ marginBottom: "40px" }}
          >
            <Grid item xs={12}>
              <h3>
                <Trans i18nKey="complete-step-one">
                  <StyledLink to="/company-details"></StyledLink>
                </Trans>
              </h3>
            </Grid>
          </Grid>
        </StyledIntroWrapper>
      </StyledIntro>
    </Fragment>
  ) : (
    <React.Fragment>
      <React.Fragment>
        <StyledContainer>
          <StyledFormWrapper fixed>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form id={formId}>
                  <MainForm
                    formField={formField}
                    values={values}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                  />
                  <div>
                    <StyledButton disabled={isSubmitting} type="submit">
                      {t("submit")}
                    </StyledButton>
                    {isSubmitting && <Loader />}
                  </div>
                </Form>
              )}
            </Formik>
          </StyledFormWrapper>
        </StyledContainer>
      </React.Fragment>
    </React.Fragment>
  );
});

export default Lean;
